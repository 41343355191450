/* eslint-disable no-param-reassign */
import produce from 'immer'
import types from './types'
import initialState from './initial'

export default function blotterReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_FILTER_TYPE: {
      const nextState = produce(state, (draftState) => {
        draftState.selectedType = payload
        return draftState
      })
      return nextState
    }
    case types.SET_FILTER_ACCOUNT: {
      const nextState = produce(state, (draftState) => {
        draftState.selectedAccount = payload
        return draftState
      })
      return nextState
    }
    case types.SET_FILTER_DATE: {
      const nextState = produce(state, (draftState) => {
        const { dateType, date } = payload
        draftState.selectedDates[dateType] = date
        return draftState
      })
      return nextState
    }
    default:
      return state
  }
}
