import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { setIsMatch, addTradeMatch } from '../ducks/trading/actions'

// assets
import '../styles/notistack.css'

const useTradeAlertSnackbars = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const fireSnackbars = (msgAlert, notistackSnackbarObj, taSnackbarMsg) => {
    // notistack snackbar
    enqueueSnackbar(msgAlert, notistackSnackbarObj)
    // tradair TaSnackbar
    dispatch(addTradeMatch(taSnackbarMsg))
    dispatch(setIsMatch(true))
  }

  return { fireSnackbars }
}

export default useTradeAlertSnackbars
