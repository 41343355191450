const NAMESPACE = 'TRADING'

const tradingTypes = {
  GET_DAILY_AUCTIONS: `${NAMESPACE}/GET_DAILY_AUCTIONS`,
  SET_DAILY_AUCTIONS: `${NAMESPACE}/SET_DAILY_AUCTIONS`,
  DECREMENT_EVENTS_RUN_TIME: `${NAMESPACE}/DECREMENT_EVENTS_RUN_TIME`,
  SET_SELECTED_AUCTION_ID: `${NAMESPACE}/SET_SELECTED_AUCTION_ID`,
  ADD_AUCTION_ORDER: `${NAMESPACE}/ADD_AUCTION_ORDER`,
  CANCEL_AUCTION_ORDER: `${NAMESPACE}/CANCEL_AUCTION_ORDER`,
  UPDATE_ORDER_STATUS: `${NAMESPACE}/UPDATE_ORDER_STATUS`,
  ADD_OR_UPDATE_ORDER: `${NAMESPACE}/ADD_OR_UPDATE_ORDER`,
  UPDATE_AUCTION_STATUS: `${NAMESPACE}/UPDATE_AUCTION_STATUS`,
  AUCTION_INITIATED: `${NAMESPACE}/AUCTION_INITIATED`,
  ADD_AUCTION: `${NAMESPACE}/ADD_AUCTION`,
  CANCEL_AUCTION: `${NAMESPACE}/CANCEL_AUCTION`,
  CLEAR_ACTIVITY: `${NAMESPACE}/CLEAR_ACTIVITY`,
  SELECT_ACCOUNT: `${NAMESPACE}/SELECT_ACCOUNT`,
  GET_ORDER_SUMMARY_COUNTS: `${NAMESPACE}/GET_ORDER_SUMMARY_COUNTS`,
  SET_ORDER_SUMMARY_COUNTS: `${NAMESPACE}/SET_ORDER_SUMMARY_COUNTS`,
  FILTER_ORDERS_BY_STATUS: `${NAMESPACE}/FILTER_ORDERS_BY_STATUS`,
  SET_FILTERED_ORDERS: `${NAMESPACE}/SET_FILTERED_ORDERS`,
  PUBLISH_RATE: `${NAMESPACE}/PUBLISH_RATE`,
  SET_BENCHMARKS_CURRENT_STATUS: `${NAMESPACE}/SET_BENCHMARKS_CURRENT_STATUS`,
  AMEND_ORDER: `${NAMESPACE}/AMEND_ORDER`,
  SET_ORDER_FILTER: `${NAMESPACE}/SET_ORDER_FILTER`,
  SET_ORDER_ERR_MSG: `${NAMESPACE}/SET_ORDER_ERR_MSG`,
  CLEAR_ERR_MSG: `${NAMESPACE}/CLEAR_ERR_MSG`,
  CLEAR_ALL_DATA: `${NAMESPACE}/CLEAR_ALL_DATA`,
  SET_IS_MATCH: `${NAMESPACE}/SET_IS_MATCH`,
  ADD_MATCH: `${NAMESPACE}/ADD_MATCH`,
  CLEAR_MATCHES: `${NAMESPACE}/CLEAR_MATCHES`,
}

export default tradingTypes
