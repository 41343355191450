import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    '&$short': { minWidth: 'auto' },
    borderTop: '2px solid #131416',
    borderLeft: '1px solid var(--theme-blue-color)',
    height: 36,
    margin: '16px 0 0 15px',
    minWidth: 110,
    color: '#fff',
    backgroundColor: '#131416',
    borderRadius: 2,
  },
  innerText: {
    '&$short': {
      '& $text': {
        marginTop: 8,
      },
      height: 26,
    },
    height: 35,
    padding: '2px 5px ',
    borderRadius: 3,
    textAlign: 'center',
  },
  text: {
    display: 'inline-block',
    marginTop: 8,
  },
  short: {},
})

const LabelContainer = (props) => {
  const { innerText, shortType, rootClass } = props
  const classes = useStyles()
  return (
    <div
      className={`${classes.root} ${rootClass || ''} ${
        shortType ? classes.short : ''
      }`}
    >
      <div className={`${classes.innerText} ${shortType ? classes.short : ''}`}>
        <span className={classes.text}>{innerText}</span>
      </div>
    </div>
  )
}

LabelContainer.propTypes = {
  innerText: PropTypes.string.isRequired,
  shortType: PropTypes.bool,
  rootClass: PropTypes.string,
}

LabelContainer.defaultProps = {
  shortType: false,
  rootClass: '',
}

export default memo(LabelContainer)
