const NAMESPACE = 'TRADE_BLOTTER'

const tradeBlotterTypes = {
  GET_TRADES_DATA: `${NAMESPACE}/GET_TRADES`,
  SET_TRADES_DATA: `${NAMESPACE}/SET_TRADES`,
  CLEAR_ALL_TRADES: `${NAMESPACE}/CLEAR_ALL_TRADES`,
  UPDATE_TRADE: `${NAMESPACE}/UPDATE_TRADE`,
}

export default tradeBlotterTypes
