import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { startLoading, stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { setInStore } from '../actions'

export default function* updateGlobalSettings({
  payload: { userId, globalSettingType, settings },
}) {
  yield put(startLoading({ LoaderTypes: LoaderTypes.UPDATE_GLOBAL_SETTINGS }))
  try {
    const globalSettings = yield call(
      ApiService.updateGlobalSettings,
      userId,
      globalSettingType,
      settings
    )
    yield put(setInStore(globalSettings))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.UPDATE_GLOBAL_SETTINGS,
        error: null,
      })
    )
  } catch (error) {
    yield put(
      stopLoading({
        loaderType: LoaderTypes.UPDATE_GLOBAL_SETTINGS,
        error,
      })
    )
  }
}
