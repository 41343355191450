import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, makeStyles } from '@material-ui/core'
import {
  fetchOrderSummaryCounts,
  getOrdersByStatus,
  setFilterState,
} from '../../ducks/trading/actions'
import {
  getOrderSummaryCounts,
  getSelectedAccountId,
  getOrderFilter,
} from '../../ducks/trading/selectors'
import { FilterStates } from '../../consts/TaConsts'

const useStyles = makeStyles({
  container: {
    borderTop: '1px solid rgba(110, 110, 110, 0.5)',
    borderBottom: '1px solid rgba(110, 110, 110, 0.5)',
    width: '100%',
    margin: '0 auto',
    bottom: 0,
  },
  paneBlock: {
    '&$selected': {
      '& $number': {
        fontWeight: 'bold',
        fontSize: '2.5vh',
      },
      fontWeight: 'bold',
      borderRadius: 6,
    },
    '&$live': {
      '&:hover': {
        backgroundColor: '#663710',
        borderRadius: 6,
      },
      '&$selected': {
        backgroundColor: '#663710',
      },
    },
    '&$cancelled': {
      '&:hover': {
        backgroundColor: '#6b2626',
        borderRadius: 6,
      },
      '&$selected': {
        backgroundColor: '#6b2626',
      },
    },
    '&$done': {
      '&:hover': {
        backgroundColor: '#0e464c',
        borderRadius: 6,
      },
      '&$selected': {
        backgroundColor: '#0e464c',
      },
    },
    '&$last': {
      '&:hover': {
        backgroundColor: 'var(--theme-blue-color)',
        borderRadius: 6,
      },
      '&$selected': {
        backgroundColor: 'var(--theme-blue-color)',
      },
    },
    color: '#fff',
    fontSize: 10,
    textAlign: 'center',
    padding: 0,
    width: '44%',
    margin: '0 auto',
  },
  number: {
    '&$live': {
      color: 'var(--theme-font-orange)',
    },
    '&$cancelled': {
      color: '#ff3636',
    },
    '&$done': {
      color: 'var(--theme-blue-color)',
    },
    fontSize: '2vh',
    fontWeight: 600,
    color: '#fff',
    textAlign: 'center',
    cursor: 'pointer',
    lineHeight: '2vh',
    width: '100%',
    padding: 0,
    position: 'static',
  },
  selected: {},
  live: {},
  cancelled: {},
  done: {},
  first: {},
  last: {},
})

const SummaryPane = () => {
  const dispatch = useDispatch()
  const accountId = useSelector(getSelectedAccountId)
  const orderSummaryCounts = useSelector(getOrderSummaryCounts)
  const orderFilter = useSelector(getOrderFilter)
  const classes = useStyles()

  const handleClick = (orderStatus) => {
    dispatch(setFilterState({ filter: orderStatus }))
    dispatch(getOrdersByStatus(accountId, orderStatus))
  }

  useEffect(() => {
    if (accountId) {
      dispatch(fetchOrderSummaryCounts(accountId))
      dispatch(getOrdersByStatus(accountId, orderFilter))
    }
  }, [dispatch, accountId, orderFilter])

  return orderSummaryCounts != null ? (
    <>
      <Grid container className={classes.container}>
        <Grid item md={3}>
          <div
            className={`${classes.paneBlock} ${classes.live} ${classes.first} ${
              orderFilter === FilterStates.LIVE ? classes.selected : ''
            }`}
          >
            <Button
              onClick={() => handleClick(FilterStates.LIVE)}
              className={`${classes.number} ${classes.live}`}
            >
              {orderSummaryCounts.liveCount}
            </Button>
            <div>Live</div>
          </div>
        </Grid>
        <Grid item md={3}>
          <div
            className={`${classes.paneBlock} ${classes.cancelled} ${
              orderFilter === FilterStates.CANCELLED ? classes.selected : ''
            }`}
          >
            <Button
              onClick={() => handleClick(FilterStates.CANCELLED)}
              className={`${classes.number} ${classes.cancelled}`}
            >
              {orderSummaryCounts.canceledCount}
            </Button>
            <div>Cancelled</div>
          </div>
        </Grid>
        <Grid item md={3}>
          <div
            className={`${classes.paneBlock} ${classes.done} ${
              orderFilter === FilterStates.DONE ? classes.selected : ''
            }`}
          >
            <Button
              onClick={() => handleClick(FilterStates.DONE)}
              className={`${classes.number} ${classes.done}`}
            >
              {orderSummaryCounts.doneCount}
            </Button>
            <div>Done</div>
          </div>
        </Grid>
        <Grid item md={3}>
          <div
            className={`${classes.paneBlock} ${classes.total} ${classes.last} ${
              orderFilter === FilterStates.ALL ? classes.selected : ''
            }`}
          >
            <Button
              onClick={() => handleClick(FilterStates.ALL)}
              className={`${classes.number} ${classes.total}`}
            >
              {orderSummaryCounts.totalCount}
            </Button>
            <div>Total</div>
          </div>
        </Grid>
      </Grid>
    </>
  ) : null
}

export default memo(SummaryPane)
