import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { resetCCpairBalance } from '../actions'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'

export default function* resetCCPairLiveOrders({
  payload: { accountId, ccPairId },
}) {
  try {
    yield call(ApiService.resetCCPairLiveOrders, accountId, ccPairId)
    yield put(resetCCpairBalance(ccPairId))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.RESET_CCPAIR_LIVE_ORDERS,
        error: null,
      })
    )
  } catch (error) {
    // eslint-disable-next-line
    console.log('err', error)
    yield put(
      stopLoading({
        loaderType: LoaderTypes.RESET_CCPAIR_LIVE_ORDERS,
        error: `Falied to get parameterHolder ${error}`,
      })
    )
  }
}
