import { call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'

export default function* downloadCSVReport({
  payload: { selectedType, chosenAccountId, selectedDates, dataType, afterEOD },
}) {
  try {
    yield call(
      ApiService.downloadCSVReport,
      selectedType,
      chosenAccountId,
      selectedDates,
      dataType,
      afterEOD
    )
  } catch (error) {
    // eslint-disable-next-line
    console.error('error', error)
  }
}
