const InitialState = {
  selectedType: 'live',
  selectedAccount: '',
  selectedDates: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
}

export default InitialState
