import React, { memo } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getSortedDailyAuctions } from '../../../ducks/trading/selectors'
import OrderForm from '../../../components/orderForm/OrderForm'

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    overflow: 'hidden',
  },
})

const OrderDialog = (props) => {
  const { onClose, fixingEvent, benchmarkId, order } = props
  const classes = useStyles()
  const dailyAuctions = useSelector(getSortedDailyAuctions)
  const exists = dailyAuctions.find(
    (event) => event.auction.id === fixingEvent.id
  )

  return (
    typeof exists !== 'undefined' && (
      <Dialog
        open
        onClose={onClose}
        classes={{ paper: classes.paper }}
        disableBackdropClick
      >
        <OrderForm
          onCancel={onClose}
          fixingEvent={fixingEvent}
          benchmarkId={benchmarkId}
          order={order}
        />
      </Dialog>
    )
  )
}

OrderDialog.propTypes = {
  fixingEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    benchmarkId: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  benchmarkId: PropTypes.number.isRequired,
  order: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    executedAmount: PropTypes.number.isRequired,
    ccPairId: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
  }),
}

OrderDialog.defaultProps = {
  order: null,
}

export default memo(OrderDialog)
