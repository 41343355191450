import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Dialog, makeStyles } from '@material-ui/core'
import ConfirmContent from '../../confirmContent/ConfirmContent'

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    backgroundColor: 'var(--theme-widget-bg-color)',
  },
})

const ConfirmationDialog = (props) => {
  const { onClose } = props
  const classes = useStyles()
  return (
    <Dialog open onClose={onClose} classes={{ paper: classes.paper }}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ConfirmContent {...props} show />
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default memo(ConfirmationDialog)
