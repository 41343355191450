const NAMESPACE = 'ORDER_BLOTTER'

const orderBlotterTypes = {
  GET_ORDERS_DATA: `${NAMESPACE}/GET_ORDERS`,
  SET_ORDERS_DATA: `${NAMESPACE}/SET_ORDERS`,
  CLEAR_ALL_ORDERS: `${NAMESPACE}/CLEAR_ALL_ORDERS`,
  UPDATE_ORDER: `${NAMESPACE}/UPDATE_ORDER`,
}

export default orderBlotterTypes
