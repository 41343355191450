import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { AuctionStatus } from '../../consts/TaConsts'
import Counter from '../../common-components/counter/Counter'
import HorizontalLabel from '../../common-components/horizontalLabel/HorizontalLabel'
import LabelContainer from '../../common-components/labelContainer/LabelContainer'
import TaButton from '../../common-components/taButton/TaButton'
import OrderDialog from '../../common-components/dialog/orderDialog/OrderDialog'
import Utils from '../../utils/Utils'
import auctionHammer from '../../images/auctionHammer.svg'

const useStyles = makeStyles({
  auctionLive: {
    height: 55,
    color: '#6e6e6e',
    position: 'relative',
  },
  roundCounterContainer: {
    padding: '10px 0',
  },
  auction: {
    height: 45,
    color: '#6e6e6e',
    width: '100%',
  },
  statusLabel: {
    '&$live': {
      backgroundColor: 'var(--theme-font-orange)',
      height: 40,
    },
    position: 'relative',
    backgroundColor: '#595959',
    fontSize: 15,
    fontWeight: 600,
    color: '#272727',
    textIndent: 13,
    paddingTop: 14,
    width: '20.5%',
    height: 30,
    minWidth: 130,
  },
  text: {
    '&$live': {
      paddingTop: 25,
    },
    fontSize: 14,
    padding: 16,
  },
  rect: {
    '&$live': {
      backgroundColor: 'var(--theme-font-orange)',
    },
    position: 'absolute',
    right: -4,
    top: '47%',
    width: 8,
    height: 8,
    transform: 'rotate(-315deg)',
    backgroundColor: '#595959',
  },
  benchmark: {
    '&$wide': {
      right: 46,
      textAlign: 'center',
      minWidth: 195,
    },
    '&$live': {
      '&$wide': {
        right: 40,
      },
      paddingTop: 15,
      marginLeft: '3%',
      color: '#fff',
      minWidth: 195,
      right: 0,
    },
    fontWeight: 600,
    fontSize: 18,
    padding: '14px 0',
    textIndent: 15,
  },
  textCont: {
    minWidth: 136,
  },
  hammer: {
    '& img': {
      height: 10,
    },
    position: 'absolute',
    right: 2,
    top: -3,
    height: 12,
  },
  btn: {
    '&$disabled': {
      '&:hover': {
        cursor: 'normal',
      },
      backgroundColor: 'var(--theme-grey-color)',
      color: '#000',
      marginTop: 5,
      fontWeight: 600,
    },
    '&$active': {
      '&:hover': {
        backgroundColor: '#1eb5db',
        outline: '1px solid #fff',
      },
      backgroundColor: 'var(--theme-blue-color)',
    },
    borderRadius: 2,
    color: '#fff',
    height: 37,
    margin: '17px 0 0 8px',
    fontSize: 12,
    width: 130,
    textTransform: 'none',
  },
  smallMargin: {
    marginTop: 4,
  },
  live: {},
  active: {},
  selected: {},
  wide: {},
  disabled: {},
})

const WaitingItem = (props) => {
  const { benchmark, auctionTime } = props
  const classes = useStyles()

  return (
    benchmark != null && (
      <Grid container className={classes.auction}>
        <div className={classes.statusLabel}>
          Starts at {auctionTime}
          <div className={classes.rect} />
        </div>
        <LabelContainer
          innerText={benchmark.name}
          rootClass={classes.smallMargin}
        />
        <TaButton className={`${classes.btn} ${classes.disabled}`} disabled>
          Place Order
        </TaButton>
      </Grid>
    )
  )
}

const LiveItem = (props) => {
  const [showPopup, setShowPopup] = useState(false)
  const { currentFixingEvent, benchmark, benchmarkId } = props
  const classes = useStyles()

  const closePopup = () => {
    setShowPopup(false)
  }

  const handleClick = () => {
    setShowPopup(true)
  }

  return (
    <Grid container className={classes.auctionLive}>
      <div className={`${classes.statusLabel} ${classes.live}`}>
        <div className={`${classes.rect} ${classes.live}`} />
        <HorizontalLabel fontSize={12} text="Live" color="#212121" />
        <Counter auctionId={currentFixingEvent.id} />
        <div className={classes.hammer}>
          <img src={auctionHammer} alt="Auction Hammer" />
        </div>
      </div>
      <LabelContainer innerText={benchmark.name} />
      <TaButton
        className={`${classes.btn} ${classes.active}`}
        onClick={() => handleClick()}
      >
        Place Order
      </TaButton>
      {showPopup && (
        <OrderDialog
          className="orderModal"
          onClose={closePopup}
          fixingEvent={currentFixingEvent}
          benchmarkId={benchmarkId}
        />
      )}
    </Grid>
  )
}

const DoneItem = (props) => {
  const { benchmark } = props
  const classes = useStyles()

  return (
    <Grid container className={classes.auction}>
      <div className={classes.statusLabel}>
        Closed
        <div className={classes.rect} />
      </div>
      <LabelContainer
        innerText={benchmark.name}
        rootClass={classes.smallMargin}
      />
      <TaButton className={`${classes.btn} ${classes.disabled}`} disabled>
        Place Order
      </TaButton>
    </Grid>
  )
}

const BenchmarkItem = (props) => {
  const { benchmarkId, benchmarkCurrentStatus } = props
  let currentFixingEvent = null
  if (benchmarkId in benchmarkCurrentStatus) {
    currentFixingEvent = benchmarkCurrentStatus[benchmarkId]
  } else {
    return null
  }
  const { dailyAuctionTime } = currentFixingEvent
  const auctionTime = Utils.convertHourLocalTime(dailyAuctionTime)
  const augmentedProps = { ...props, auctionTime, currentFixingEvent }
  let result = null
  switch (currentFixingEvent.status) {
    case AuctionStatus.WAITING:
      // eslint-disable-next-line react/jsx-props-no-spreading
      result = <WaitingItem {...augmentedProps} />
      break
    case AuctionStatus.LIVE:
      // eslint-disable-next-line react/jsx-props-no-spreading
      result = <LiveItem {...augmentedProps} />
      break
    case AuctionStatus.DONE:
      // eslint-disable-next-line react/jsx-props-no-spreading
      result = <DoneItem {...augmentedProps} />
      break
    default:
      result = <div>Unkown status</div>
  }
  return (
    <Grid
      container
      className="auctionItem"
      style={{ width: '100%', height: '100%' }}
    >
      {result}
    </Grid>
  )
}

BenchmarkItem.propTypes = {
  benchmarkId: PropTypes.number.isRequired,
  benchmark: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
}

WaitingItem.propTypes = BenchmarkItem.propTypes
LiveItem.propTypes = BenchmarkItem.propTypes
DoneItem.propTypes = BenchmarkItem.propTypes

export default BenchmarkItem
