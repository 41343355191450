/* eslint-disable no-param-reassign */
import produce from 'immer'
import types from './types'
import initialState from './initial'

export default function authenticationReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_AUTH_DATA: {
      const nextState = produce(state, (draftState) => {
        draftState.sessionData = payload
        draftState.isAuthenticated = true
      })
      return nextState
    }
    case types.SET_ERR_MSG: {
      const nextState = produce(state, (draftState) => {
        draftState.errMsg = payload
        draftState.isAuthenticated = false
      })
      return nextState
    }
    case types.CLEAR_ERR_MSG: {
      const nextState = produce(state, (draftState) => {
        draftState.errMsg = ''
        draftState.isAuthenticated = false
      })
      return nextState
    }
    case types.CLEAR_AUTH_DATA: {
      const nextState = produce(state, (draftState) => {
        draftState.sessionData = null
        draftState.isAuthenticated = false
      })
      return nextState
    }
    default:
      return state
  }
}
