import React, { useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  DialogTitle,
  makeStyles,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import LabelContainer from '../../common-components/labelContainer/LabelContainer'
import {
  getSelectedAccountId,
  getSortedDailyAuctions,
} from '../../ducks/trading/selectors'
import { cleanError } from '../../ducks/loaders/actions'
import {
  addAuctionOrder,
  amendOrder as actionAmendOrder,
} from '../../ducks/trading/actions'
import { getBenchmarksMap } from '../../ducks/paramHolder/selectors'
import { getUserId } from '../../ducks/authentication/selectors'
import { LoaderTypes } from '../../ducks/loaders/loaderTypesEnum'
import { TaConsts } from '../../consts/TaConsts'
import TaButton from '../../common-components/taButton/TaButton'
import Counter from '../../common-components/counter/Counter'
import ProgressBar from '../../common-components/progressBar/ProgressBar'
import CurrencyDropDown from '../../common-components/currencyDropDown/CurrencyDropDown'

const useStyles = makeStyles({
  container: {
    width: 345,
    border: '2px solid #0180a0',
    backgroundColor: 'var(--theme-widget-bg-color)',
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  title: {
    '& h2': {
      color: '#000',
      fontFamily: 'var(--theme-font-family)',
      fontSize: 13,
      fontWeight: 600,
      textAlign: 'left',
      textIndent: 5,
      letterSpacing: 0,
      height: 20,
    },
    backgroundColor: 'var(--theme-blue-color)',
    width: '100%',
    padding: 0,
    borderRadius: '2px 2px 0 0',
  },
  ccPair: {
    fontSize: 14,
  },
  white: {
    color: '#fff',
    fontWeight: 600,
  },
  label: {
    fontSize: 13,
    marginRight: 10,
  },
  counter: {
    marginTop: 45,
  },
  orange: {
    color: 'var(--theme-font-orange)',
    fontWeight: 'bold',
    fontSize: 13,
    height: 60,
  },
  adornedEnd: {
    '& p': {
      color: '#fff !important',
      fontWeight: 600,
      fontSize: 13,
    },
  },
  root: {
    '& input': {
      textAlign: 'center',
    },
    width: '100%',
    backgroundColor: '#131416',
  },
  separator: {
    height: 0,
    borderBottom: '0.1em solid #131416',
  },
  btn: {
    '&$buy': {
      '&:hover': {
        border: '1px solid #c1ffb6',
      },
      backgroundColor: 'var(--theme-buy-color)',
    },
    '&$sell': {
      '&:hover': {
        border: '1px solid #ffcae0',
      },
      backgroundColor: 'var(--theme-sell-color)',
    },
    '&$disabled': {
      '&:hover': {
        cursor: 'normal',
      },
      backgroundColor: 'var(--theme-grey-color)',
      color: '#000',
      fontWeight: 600,
    },
    color: '#fff',
    textAlign: 'center',
    height: 25,
    borderRadius: 0,
    fontSize: 13,
    width: '100%',
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    top: 5,
    height: '0.6em',
    fontWeight: 'bold',
  },
  orderEntry: {
    fontSize: 14,
    border: 'none',
    margin: 0,
  },
  smallContainer: {
    maxWidth: 102,
  },
  rightContainer: {
    marginLeft: 8,
  },
  smallLabel: {
    color: 'var(--theme-grey-color)',
    fontSize: 11,
    textAlign: 'left',
    textIndent: 5,
    display: 'inherit',
  },
  dropDownContainer: {
    height: 25,
    backgroundColor: '#131416',
  },
  formControl: {
    width: '100%',
    display: 'block',
    marginTop: 2,
    position: 'static',
  },
  topContainer: {
    borderBottom: '0.15em solid #131416',
  },
  fontFamily: {
    fontFamily: 'var(--theme-font-family)',
  },
  buy: {},
  disabled: {},
  sell: {},
})

const OrderForm = (props) => {
  const { onCancel, fixingEvent, benchmarkId, order } = props
  const liveAmount =
    order !== null ? (order.quantity - order.executedAmount) / 1000000 : null
  const [baseQuantity, setBaseQuantity] = useState(order ? liveAmount : '')
  const [ccyPair, setCCPair] = useState(order ? order.ccPairId : -1)
  const [ccyErrMsg, setCcyErrMsg] = useState('')
  const [sizeErrMsg, setSizeErrMsg] = useState('')
  const [currentFixingEvent, setCurrentFixingEvent] = useState(fixingEvent)
  const classes = useStyles()
  const dispatch = useDispatch()
  const benchmarksMap = useSelector(getBenchmarksMap)
  const userId = useSelector(getUserId)
  const selectedAccountId = useSelector(getSelectedAccountId)
  const dailyAuctions = useSelector(getSortedDailyAuctions)

  const saveOrderAmount = (e) => {
    const { value } = e.target
    dispatch(cleanError({ loaderType: LoaderTypes.ADD_AUCTION_ORDER }))
    setSizeErrMsg('')
    if (
      value.length === 0 ||
      (parseInt(value, 10) < 1000 && parseInt(value, 10) !== 0)
    ) {
      if (TaConsts.DECIMAL_REGEX.test(value)) {
        setBaseQuantity(Number(value))
      }
    }
  }

  const findRelevantFixingEvent = (ccPairId) => {
    return dailyAuctions.find(
      (event) =>
        event.auction.ccPairId === ccPairId &&
        event.auction.benchmarkId === benchmarkId
    )
  }

  const validate = () => {
    let validated = false
    if (ccyPair === -1) {
      setCcyErrMsg('Please choose a Currency pair')
    } else if (baseQuantity.length === 0) {
      setSizeErrMsg('Please input the size in millions')
    } else if (order && baseQuantity > liveAmount) {
      setSizeErrMsg(
        'The amended quantity cannot be higher than the outstanding quantity'
      )
    } else if (order && baseQuantity === liveAmount) {
      // dismiss - noop
      onCancel()
    } else {
      validated = true
    }
    return validated
  }

  const handleCurrencyChange = (ccPairId) => {
    setCcyErrMsg('')
    setCCPair(ccPairId)
    if (ccPairId !== -1) {
      const { auction } = findRelevantFixingEvent(ccPairId)
      setCurrentFixingEvent(auction)
    }
  }

  const addOrder = (direction) => {
    if (validate()) {
      const realQuantity = 1000000 * baseQuantity
      const accountId = selectedAccountId
      const realParentOrderId = currentFixingEvent.parentOrderId
      dispatch(
        addAuctionOrder({
          direction,
          baseQuantity: realQuantity,
          userId,
          accountId,
          parentOrderId: realParentOrderId,
        })
      )
      onCancel()
    }
  }

  const amendOrder = () => {
    if (validate()) {
      const { orderId, executedAmount } = order
      const realQuantity = 1000000 * baseQuantity + executedAmount
      dispatch(actionAmendOrder({ orderId, baseQuantity: realQuantity }))
      onCancel()
    }
  }

  const handleClick = (direction) => {
    if (order != null) {
      amendOrder()
    } else {
      addOrder(direction)
    }
  }

  const benchmark = benchmarksMap.get(currentFixingEvent.benchmarkId)
  const title = order != null ? 'Order Amendment' : 'Order Entry'
  return (
    <Grid container className={classes.container}>
      <DialogTitle className={classes.title}>
        {title}
        <CloseOutlinedIcon className={classes.closeBtn} onClick={onCancel} />
      </DialogTitle>
      <Grid container className={classes.topContainer}>
        <Grid item md={4} className={classes.smallContainer}>
          <span className={classes.smallLabel}>Benchmark</span>
          <LabelContainer
            innerText={benchmark.name}
            shortType
            rootClass={classes.orderEntry}
          />
        </Grid>
        <Grid item md={8} className={classes.rightContainer}>
          <span className={classes.smallLabel}>Session ends in:</span>
          <ProgressBar
            totalSeconds={currentFixingEvent.duration * 60}
            fixingEventId={currentFixingEvent.id}
            className={classes.roundCounterContainer}
            barColor="orange"
            style={{ marginTop: 14 }}
          />
          <Counter
            auctionId={currentFixingEvent.id}
            color="var(--theme-font-orange)"
            parent="orderEntry"
          />
        </Grid>
      </Grid>
      <Grid container>
        <FormControl
          className={classes.formControl}
          error={ccyErrMsg.length > 0 || sizeErrMsg.length > 0}
        >
          <Grid item md={12} className={classes.dropDownContainer}>
            <CurrencyDropDown
              benchmarkId={benchmarkId}
              onChange={handleCurrencyChange}
              value={ccyPair}
              disabled={order != null}
            />
          </Grid>
          {ccyErrMsg.length > 0 && (
            <Grid item md={12} className={classes.center}>
              <FormHelperText className={classes.fontFamily}>
                {ccyErrMsg}
              </FormHelperText>
            </Grid>
          )}
          <Grid item md={12} className={classes.separator} />
          <Grid item md={12}>
            <span className={classes.smallLabel}>Size (M)</span>
          </Grid>
          <Grid item md={12}>
            <TextField
              id="amount"
              variant="standard"
              autoFocus
              onChange={saveOrderAmount}
              value={baseQuantity}
              classes={{ root: classes.root }}
              autoComplete="off"
              InputProps={{
                style: {
                  fontFamily: 'var(--theme-font-family)',
                  color: '#fff',
                  fontSize: 13,
                  height: 35,
                  fontWeight: 600,
                  borderRadius: 0,
                },
                disableUnderline: true,
              }}
            />
          </Grid>
          {sizeErrMsg.length > 0 && (
            <Grid item md={12} className={classes.center}>
              <FormHelperText className={classes.fontFamily}>
                {sizeErrMsg}
              </FormHelperText>
            </Grid>
          )}
        </FormControl>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <TaButton
            className={`${classes.btn} ${classes.buy} ${
              order != null && order.direction === 'SELL'
                ? classes.disabled
                : ''
            }`}
            onClick={() => handleClick('BUY')}
            disabled={order != null && order.direction === 'SELL'}
          >
            Buy
          </TaButton>
        </Grid>
        <Grid item md={6}>
          <TaButton
            className={`${classes.btn} ${classes.sell} ${
              order != null && order.direction === 'BUY' ? classes.disabled : ''
            }`}
            onClick={() => handleClick('SELL')}
            disabled={order != null && order.direction === 'BUY'}
          >
            Sell
          </TaButton>
        </Grid>
      </Grid>
    </Grid>
  )
  // )
}

OrderForm.propTypes = {
  fixingEvent: PropTypes.shape({
    benchmarkId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    runTime: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    ccPairId: PropTypes.number.isRequired,
    createTime: PropTypes.string.isRequired,
  }).isRequired,
  benchmarkId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  order: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    executedAmount: PropTypes.number.isRequired,
    ccPairId: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
  }),
}

OrderForm.defaultProps = {
  order: null,
}

export default memo(OrderForm)
