import moment from 'moment'

const numberFormatter = (params) => {
  return params.value.toLocaleString()
}

const dateFormatter = (params) => {
  const { createDate } = params.data
  const utcTime = moment.utc(createDate)
  utcTime.local()
  return utcTime.format('DD MMM YYYY HH:mm:ss')
}

const columns = [
  {
    field: 'orderId',
    width: 85,
    sortable: true,
    headerName: 'Order Id',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'orderId',
    headerTooltip: 'Order Id',
  },
  {
    field: 'createDate',
    width: 145,
    sortable: true,
    headerName: 'Trade Date',
    menuTabs: ['filterMenuTab'],
    valueFormatter: dateFormatter,
    tooltipField: 'createDate',
    headerTooltip: 'Trade Date',
  },
  {
    field: 'status',
    width: 95,
    sortable: true,
    headerName: 'Status',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'status',
    headerTooltip: 'Status',
  },
  {
    field: 'direction',
    width: 90,
    sortable: true,
    cellRenderer: 'directionRenderer',
    headerName: 'Direction',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'direction',
    headerTooltip: 'Direction',
  },
  {
    field: 'currencyPair',
    width: 94,
    sortable: true,
    headerName: 'CCY Pair',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'currencyPair',
    headerTooltip: 'CCY Pair',
  },
  {
    field: 'baseQuantity',
    width: 125,
    sortable: true,
    headerName: 'Base Quantity',
    valueFormatter: numberFormatter,
    menuTabs: ['filterMenuTab'],
    tooltipField: 'baseQuantity',
    headerTooltip: 'Base Quantity',
  },
  {
    field: 'executedQuantity',
    width: 145,
    sortable: true,
    headerName: 'Executed Quantity',
    valueFormatter: numberFormatter,
    menuTabs: ['filterMenuTab'],
    tooltipField: 'executedQuantity',
    headerTooltip: 'Executed Quantity',
  },
  {
    field: 'benchmark',
    width: 102,
    sortable: true,
    headerName: 'Benchmark',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'benchmark',
    headerTooltip: 'Benchmark',
  },
  {
    field: 'maturityDate',
    width: 90,
    sortable: true,
    headerName: 'Maturity Date',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'maturityDate',
    headerTooltip: 'Maturity Date',
  },
  {
    field: 'account',
    width: 94,
    sortable: true,
    headerName: 'Account',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'account',
    headerTooltip: 'Account',
  },
  {
    field: 'rejectReason',
    width: 94,
    sortable: true,
    headerName: 'Cancel/Reject Reason',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'rejectReason',
    headerTooltip: 'Cancel/Reject Reason',
  },
  {
    field: 'user',
    width: 100,
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerName: 'Trader',
    tooltipField: 'user',
    headerTooltip: 'Trader',
  },
]

export default columns
