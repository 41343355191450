import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PasswordStrengthMeter from '../../passwordStrengthMeter/PasswordStrengthMeter'

const firstLoginDialogStyles = makeStyles({
  listItem: {
    paddingTop: '0',
    '& span': {
      fontSize: '12px',
    },
    '& div': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
})

const FirstLoginDialog = (props) => {
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [passwordErrMsg, setPasswordErrMsg] = useState('')
  const [repeatPasswordErrMsg, setRepeatPasswordErrMsg] = useState('')
  const {
    isOpen,
    handleClose,
    username,
    oldPassword,
    attemptResetPassword,
    errorFromServer,
  } = props
  const classes = firstLoginDialogStyles()

  const validatePass = (value) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)
  }

  const validate = (value, type) => {
    let validated = false
    const setMethod =
      type === 'password' ? setPasswordErrMsg : setRepeatPasswordErrMsg
    if (!validatePass(value)) {
      setMethod(
        'Password must be at least 8 chars long, contain a number, a small letter and a capital letter'
      )
    } else if (type === 'repeatPassword' && password !== repeatPassword) {
      setMethod('Passwords do not match')
    } else {
      validated = true
      setMethod('')
    }

    return validated
  }

  const handleSubmit = () => {
    if (
      validate(password, 'password') &&
      validate(repeatPassword, 'repeatPassword')
    ) {
      attemptResetPassword(username, oldPassword, password)
    }
  }

  const resetErrorLabels = () => {
    setPasswordErrMsg('')
    setRepeatPasswordErrMsg('')
    setPassword('')
  }

  const handleCancel = () => {
    resetErrorLabels()
    handleClose()
  }

  return isOpen ? (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.container}
        disableBackdropClick
        fullWidth
        width="500"
      >
        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorFromServer}</DialogContentText>
          <DialogContentText>Your password :</DialogContentText>
          <List className={classes.list} disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Must be at least 8 chars long" />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Must contain a number, a small letter, a capital letter" />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Must not use previous passwords" />
            </ListItem>
          </List>
          <form>
            <TextField
              autoFocus
              margin="dense"
              type="password"
              name="password"
              onChange={(e) => {
                setPasswordErrMsg('')
                setPassword(e.target.value)
              }}
              fullWidth
              error={passwordErrMsg.length > 0}
              label={passwordErrMsg.length > 0 ? 'Error' : 'New Password'}
              helperText={passwordErrMsg}
              variant="outlined"
              autoComplete="off"
            />
            <PasswordStrengthMeter password={password} />
            <TextField
              margin="dense"
              type="password"
              name="repeatPassword"
              onChange={(e) => {
                setRepeatPasswordErrMsg('')
                setRepeatPassword(e.target.value)
              }}
              fullWidth
              error={repeatPasswordErrMsg.length > 0}
              label={
                repeatPasswordErrMsg.length > 0 ? 'Error' : 'Repeat Password'
              }
              helperText={repeatPasswordErrMsg}
              variant="outlined"
              autoComplete="off"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null
}

FirstLoginDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  attemptResetPassword: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  oldPassword: PropTypes.string.isRequired,
  errorFromServer: PropTypes.string,
}

FirstLoginDialog.defaultProps = {
  errorFromServer: '',
}

export default FirstLoginDialog
