import tradeBlotterTypes from './types'

export const getTrades = (
  selectedType,
  chosenAccountId,
  selectedDates,
  afterEOD
) => ({
  type: tradeBlotterTypes.GET_TRADES_DATA,
  payload: { selectedType, chosenAccountId, selectedDates, afterEOD },
})

export const updateTrade = (trade) => ({
  type: tradeBlotterTypes.UPDATE_TRADE,
  payload: { trade },
})

export const setTrades = (trades) => ({
  type: tradeBlotterTypes.SET_TRADES_DATA,
  payload: { trades },
})

export const clearAllTrades = () => ({
  type: tradeBlotterTypes.CLEAR_ALL_TRADES,
})
