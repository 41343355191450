const NAMESPACE = 'AUTHENTICATION'

const authTypes = {
  AUTHENTICATE: `${NAMESPACE}/AUTHENTICATE`,
  LOGOUT: `${NAMESPACE}/LOGOUT`,
  SET_AUTH_DATA: `${NAMESPACE}/SET_AUTH_DATA`,
  CLEAR_AUTH_DATA: `${NAMESPACE}/CLEAR_AUTH_DATA`,
  RESET_PASSWORD: `${NAMESPACE}/RESET_PASSWORD`,
  SET_ERR_MSG: `${NAMESPACE}/SET_ERR_MSG`,
  CLEAR_ERR_MSG: `${NAMESPACE}/CLEAR_ERR_MSG`,
}

export default authTypes
