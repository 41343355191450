import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, TableCell, TableRow } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import { FilterStates, OrderStatus } from '../../consts/TaConsts'
import Counter from '../../common-components/counter/Counter'
import { getCcPairMap } from '../../ducks/paramHolder/selectors'
import { cancelAuctionOrder } from '../../ducks/trading/actions'
import {
  getAllDailyAuctionMap,
  getOrderFilter,
} from '../../ducks/trading/selectors'
import './orderListItem.css'
import auctionHammer from '../../images/auctionHammer.svg'
import OrderDialog from '../../common-components/dialog/orderDialog/OrderDialog'
import ConfirmationDialog from '../../common-components/dialog/confirmationDialog/ConfirmationDialog'

const useStyles = makeStyles({
  statusLabel: {
    '&$live': {
      '&$small': {
        minWidth: 80,
        maxWidth: 80,
        height: 13,
      },
      backgroundColor: 'var(--theme-font-orange)',
      height: 13,
    },
    roundCounterContainer: {
      padding: '10px 0',
    },
    position: 'relative',
    backgroundColor: 'var(--theme-font-orange)',
    fontSize: 18,
    fontWeight: 600,
    color: '#272727',
    textIndent: 13,
    width: 190,
    height: 30,
    minWidth: 190,
  },
  rect: {
    '&$live': {
      backgroundColor: 'var(--theme-font-orange)',
      '&$small': {
        top: '28%',
      },
    },
    position: 'absolute',
    right: -4,
    top: '47%',
    width: 8,
    height: 4,
    transform: 'rotate(-315deg)',
    backgroundColor: '#595959',
  },
  hammer: {
    '& img': {
      height: 8,
    },
    position: 'absolute',
    right: 0,
    top: -3,
    height: 12,
  },
  orderDetails: {
    '&$small': { fontSize: 11, textIndent: '8%', lineHeight: '20px' },
    fontSize: 15,
    fontWeight: 600,
    textIndent: 0,
  },
  rootIcon: {
    '&$cancel': {
      color: '#ff3636',
    },
    '&:hover': {
      opacity: 0.7,
    },
    fontSize: 12,
    width: 12,
    color: '#fff',
  },
  btn: {
    minWidth: 20,
    padding: '1px 0 0 0',
  },
  status: {
    '&$small': {
      maxWidth: 125,
    },
    lineHeight: '20px',
    paddingLeft: 12,
    fontSize: 12,
  },
  actions: {
    height: 20,
    lineHeight: '20px',
    marginTop: -3,
  },
  row: {
    '& td': {
      '&:first-child': {
        textIndent: 10,
      },
      '&$alignContRight': {
        paddingRight: '30px',
        textAlign: 'right',
      },
      backgroundColor: 'inherit',
      lineHeight: '13px',
      padding: 0,
      color: '#fff',
      fontSize: 9,
      height: 9,
      borderBottom: '1px solid #000',
    },
  },
  live: {},
  small: {},
  cancel: {},
  alignContRight: {},
})

const OrderListItem = (props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const confirmMsg = 'Are you sure you want to cancel this order?'
  const dispatch = useDispatch()
  const ccPairsMap = useSelector(getCcPairMap)
  const dailyAuctionsMap = useSelector(getAllDailyAuctionMap)
  const orderFilter = useSelector(getOrderFilter)
  const classes = useStyles()
  const { order } = props
  const { orderStatus } = order
  const ccPair = ccPairsMap.get(order.ccPairId)
  const auctionItem = dailyAuctionsMap.get(order.auctionId)
  let orderStatusColor = { color: null, text: null }
  const isLive =
    orderStatus === OrderStatus.LIVE ||
    orderStatus === OrderStatus.PARTIALLY_FILLED
  const outstandingAmount = order.quantity - order.executedAmount
  const orderDisplayPredicate =
    orderFilter === FilterStates.ALL ||
    orderStatus === orderFilter ||
    (isLive && orderFilter === FilterStates.LIVE) ||
    (orderFilter === FilterStates.CANCELLED &&
      (orderStatus === OrderStatus.REJECTED ||
        orderStatus === OrderStatus.TERMINATED))

  switch (orderStatus) {
    case OrderStatus.LIVE:
    case OrderStatus.PARTIALLY_FILLED:
      orderStatusColor = { color: '#0064b2', text: 'Live' }
      break
    case OrderStatus.CANCELED:
    case OrderStatus.PARTAILLY_CANCELED:
      orderStatusColor = { color: '#ff3636', text: 'Cancelled' }
      break
    case OrderStatus.TERMINATED:
      orderStatusColor = { color: '#ff3636', text: 'Terminated' }
      break
    case OrderStatus.PARTIALLY_REJECTED:
    case OrderStatus.REJECTED:
      orderStatusColor = { color: '#ff3636', text: 'Rejected' }
      break
    case OrderStatus.DONE:
      orderStatusColor = { color: '#199bbe', text: 'Done' }
      break
    default:
      orderStatusColor = { color: '#0064b2', text: 'Unkown' }
      break
  }

  const handleClick = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const handleClose = () => {
    setShowConfirm(false)
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    dispatch(cancelAuctionOrder({ orderId: order.orderId }))
  }

  const handleCancelOrder = () => {
    setShowConfirm(true)
  }

  if (!auctionItem) {
    return (
      orderDisplayPredicate && (
        <TableRow className={classes.row}>
          <TableCell style={{ color: orderStatusColor.color }}>
            {orderStatusColor.text}
          </TableCell>
          <TableCell>
            {ccPair.instrument1.name} / {ccPair.instrument2.name}
          </TableCell>
          <TableCell>{order.benchmarkName}</TableCell>
          <TableCell>{order.direction}</TableCell>
          <TableCell className={classes.alignContRight}>
            {order.quantity.toLocaleString()}
          </TableCell>
          <TableCell className={classes.alignContRight}>
            {order.executedAmount.toLocaleString()}
          </TableCell>
          <TableCell className={classes.alignContRight}>
            {outstandingAmount.toLocaleString()}
          </TableCell>
          <TableCell />
        </TableRow>
      )
    )
  }
  const { auction } = auctionItem
  return (
    orderDisplayPredicate && (
      <TableRow className={classes.row}>
        {isLive ? (
          <TableCell>
            <div
              className={`${classes.statusLabel} ${classes.live} ${classes.small}`}
            >
              <div
                className={`${classes.rect} ${classes.live} ${classes.small}`}
              />
              <Counter
                auctionId={auction.id}
                className={classes.roundCounterContainer}
                parent="orderDashboard"
              />
              <div className={classes.hammer}>
                <img src={auctionHammer} alt="Auction Hammer" />
              </div>
            </div>
          </TableCell>
        ) : (
          <TableCell style={{ color: orderStatusColor.color }}>
            {orderStatusColor.text}
          </TableCell>
        )}
        <TableCell>
          {ccPair.instrument1.name} / {ccPair.instrument2.name}
        </TableCell>
        <TableCell>{order.benchmarkName}</TableCell>
        <TableCell>{order.direction}</TableCell>
        <TableCell className={classes.alignContRight}>
          {order.quantity.toLocaleString()}
        </TableCell>
        <TableCell className={classes.alignContRight}>
          {order.executedAmount.toLocaleString()}
        </TableCell>
        <TableCell className={classes.alignContRight}>
          {orderStatus === OrderStatus.CANCELED ||
          orderStatus === OrderStatus.PARTAILLY_CANCELED ||
          orderStatus === OrderStatus.REJECTED
            ? 0
            : outstandingAmount.toLocaleString()}
        </TableCell>
        {isLive ? (
          <TableCell className={classes.actions}>
            <Button className={classes.btn} onClick={handleCancelOrder}>
              <CancelIcon
                color="primary"
                classes={{
                  root: classes.rootIcon,
                  colorPrimary: classes.cancel,
                }}
              />
            </Button>
            <Button className={classes.btn} onClick={handleClick}>
              <EditIcon classes={{ root: classes.rootIcon }} />
            </Button>
          </TableCell>
        ) : (
          <TableCell />
        )}
        {showPopup && (
          <OrderDialog
            className="orderModal"
            onClose={closePopup}
            fixingEvent={auction}
            benchmarkId={auction.benchmarkId}
            order={order}
          />
        )}
        {showConfirm && (
          <ConfirmationDialog
            onClose={handleClose}
            onConfirm={() => handleConfirm(order)}
            msg={confirmMsg}
          />
        )}
      </TableRow>
    )
  )
}

OrderListItem.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    benchmarkName: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    rate: PropTypes.number,
    executedAmount: PropTypes.number.isRequired,
    orderStatus: PropTypes.string.isRequired,
    ccPairId: PropTypes.number.isRequired,
    auctionId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
}

export default memo(OrderListItem)
