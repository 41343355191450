import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgGrid from '../../common-components/agGrid/agGrid'
import columns from './tradeBlotterColumns'
import DirectionRenderer from '../../common-components/directionCellRenderer/DirectionCellRenderer'
import {
  getTrades,
  setTrades,
  updateTrade,
} from '../../ducks/tradeBlotter/actions'
import * as tradeSelectors from '../../ducks/tradeBlotter/selectors'
import {
  getBenchmarksMap,
  getCcPairMap,
  getEndOfDay,
} from '../../ducks/paramHolder/selectors'
import {
  getSelectedAccount,
  getSelectedDates,
  getSelectedType,
} from '../../ducks/blotter/selectors'
import useResizeScreen from '../../common-components/hooks/useResizeScreen'
import ApiService from '../../api/ApiService'
import Utils from '../../utils/Utils'

const consts = {
  LIVE_TRADES: 'LIVE_TRADES',
}

const TradeBlotter = () => {
  const height = useResizeScreen()
  const dispatch = useDispatch()
  const tradesData = useSelector(tradeSelectors.getTrades)
  const benchmarksMap = useSelector(getBenchmarksMap)
  const ccPairsMap = useSelector(getCcPairMap)
  const chosenAccountId = useSelector(getSelectedAccount)
  const selectedDates = useSelector(getSelectedDates)
  const selectedType = useSelector(getSelectedType)
  const endOfDay = useSelector(getEndOfDay)

  useEffect(() => {
    try {
      if (chosenAccountId) {
        const afterEOD = Utils.checkIfAfterEOD(endOfDay)
        dispatch(
          getTrades(selectedType, chosenAccountId, selectedDates, afterEOD)
        )
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error while getting redux props ', e)
    }
  }, [
    chosenAccountId,
    dispatch,
    endOfDay,
    selectedDates,
    selectedType,
    tradesData.length,
  ])

  useEffect(() => {
    const handleTradePush = (data, headers) => {
      switch (headers.systemAction) {
        case consts.LIVE_TRADES:
          dispatch(updateTrade(data))
          break
        default:
      }
    }
    ApiService.stompExternalSubscribe(
      `/topic/system.blotterLiveTrades.${chosenAccountId}`,
      handleTradePush
    )
  }, [chosenAccountId, dispatch])

  const saveSettings = () => {}

  const onGridReady = () => {
    try {
      dispatch(setTrades(tradesData))
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error while preparing the grid data', e)
    }
  }

  const colState = null
  const sortState = null
  const filterState = null
  const isUseDelta = tradesData && tradesData.length > 0
  const factor = height > 800 ? 32 : 29

  const parsedTradesData = []
  tradesData.forEach((trade) => {
    const modifiedTrade = JSON.parse(JSON.stringify(trade))
    const ccPairObj = ccPairsMap.get(trade.ccPairId)
    const benchmarkObj = benchmarksMap.get(trade.benchmarkId)
    modifiedTrade.currencyPair = `${ccPairObj.instrument1.name} / ${ccPairObj.instrument2.name}`
    modifiedTrade.benchmark = benchmarkObj.name
    modifiedTrade.postTradeCode =
      modifiedTrade.rate > 0 ? modifiedTrade.counterParty : ''
    parsedTradesData.push(modifiedTrade)
  })

  return (
    <div style={{ height: `${(height * factor) / 100}px` }}>
      <AgGrid
        onSave={saveSettings}
        rowData={parsedTradesData}
        colState={colState}
        sortState={sortState}
        filterState={filterState}
        columnDefs={columns}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
          hide: false,
        }}
        frameworkComponents={{
          directionRenderer: DirectionRenderer,
        }}
        handleGridReady={onGridReady}
        deltaRowDataMode={isUseDelta}
        getRowNodeId={(data) => {
          return data.externalId
        }}
        loadingSpinner
        enableBrowserTooltips
      />
    </div>
  )
}

export default memo(TradeBlotter)
