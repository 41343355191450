import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { SnackbarProvider } from 'notistack'
import { Button } from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import App from './App'
import Trading from './Trading'
import ApiService from './api/ApiService'
import store from './ducks/store'

ApiService.initSocket()
const notistackRef = React.createRef()
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key)
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={{}}>
      <SnackbarProvider
        maxSnack={10}
        hideIconVariant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        ref={notistackRef}
        className="fixer-notistack"
        action={(key) => (
          <Button onClick={onClickDismiss(key)}>
            <CloseOutlinedIcon />
          </Button>
        )}
      >
        <Router>
          <Switch>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Route exact path="/" component={(props) => <App {...props} />} />
            <Route
              exact
              path="/trading"
              // eslint-disable-next-line react/jsx-props-no-spreading
              component={(props) => <Trading {...props} />}
            />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app')
)
