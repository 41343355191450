import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { stopLoading, startLoading } from '../../loaders/actions'
import { setFilteredOrders } from '../actions'

export default function* filterOrdersByStatus({
  payload: { accountId, orderStatus },
}) {
  yield put(startLoading({ loaderType: LoaderTypes.FILTER_ORDERS_BY_STATUS }))
  try {
    const filteredOrders = yield call(
      ApiService.filterOrdersByStatus,
      accountId,
      orderStatus
    )
    yield put(setFilteredOrders(filteredOrders))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.FILTER_ORDERS_BY_STATUS,
        error: null,
      })
    )
  } catch (error) {
    yield put(
      stopLoading({
        loaderType: LoaderTypes.FILTER_ORDERS_BY_STATUS,
        error: `Failed to get filtered orders ${error}`,
      })
    )
  }
}
