import authTypes from './types'

export const authenticate = (username, password) => ({
  type: authTypes.AUTHENTICATE,
  payload: { username, password },
})

export const logout = () => ({ type: authTypes.LOGOUT })

export const setAuthData = (payload) => ({
  type: authTypes.SET_AUTH_DATA,
  payload,
})

export const setErrorMsg = (payload) => ({
  type: authTypes.SET_ERR_MSG,
  payload,
})

export const clearErrMsg = () => ({ type: authTypes.CLEAR_ERR_MSG })

export const clearAuthData = (payload) => ({
  type: authTypes.CLEAR_AUTH_DATA,
  payload,
})

export const resetPassword = (username, oldPassword, newPassword) => ({
  type: authTypes.RESET_PASSWORD,
  payload: { username, oldPassword, newPassword },
})
