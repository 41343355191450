import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getAvailableCurrencies } from '../../ducks/trading/selectors'

const useStyles = makeStyles({
  dropDown: {
    '&:before': {
      borderColor: 'var(--theme-grey-color)',
    },
    '&:after': {
      borderColor: 'var(--theme-grey-color)',
    },
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 13,
    fontFamily: 'Open Sans',
    width: '100%',
    textAlign: 'left',
    textIndent: 5,
  },
  icon: {
    fill: 'var(--theme-blue-color)',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  menu: {
    fontSize: 12,
    fontWeight: 'normal',
    color: 'var(--theme-grey-color)',
  },
})

const CurrencyDropDown = (props) => {
  const classes = useStyles()
  const { benchmarkId, value, onChange, disabled } = props
  const currencies = useSelector(getAvailableCurrencies(benchmarkId))

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <Select
      className={classes.dropDown}
      inputProps={{
        classes: {
          icon: classes.icon,
          select: classes.select,
        },
        readOnly: disabled,
      }}
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      defaultValue="Select your CCY Pair"
      onChange={handleChange}
      placeholder="Select your CCY Pair"
      value={value}
    >
      <MenuItem className={classes.menu} value={-1}>
        Select your CCY Pair
      </MenuItem>
      {currencies.map((currency) => (
        <MenuItem
          key={currency.id}
          value={currency.id}
          className={classes.menu}
        >
          {currency.instrument1.name} / {currency.instrument2.name}
        </MenuItem>
      ))}
    </Select>
  )
}

CurrencyDropDown.propTypes = {
  benchmarkId: PropTypes.number.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CurrencyDropDown.defaultProps = {
  value: -1,
  disabled: false,
}

export default memo(CurrencyDropDown)
