const NAMESPACE = 'BLOTTER'

const blotterTypes = {
  SET_FILTER_TYPE: `${NAMESPACE}/SET_FILTER_TYPE`,
  SET_FILTER_ACCOUNT: `${NAMESPACE}/SET_FILTER_ACCOUNT`,
  SET_FILTER_DATE: `${NAMESPACE}/SET_FILTER_DATE`,
  DOWNLOAD_CSV_REPORT: `${NAMESPACE}/DOWNLOAD_CSV_REPORT`,
}

export default blotterTypes
