import orderBlotterTypes from './types'

export const getOrders = (
  selectedType,
  chosenAccountId,
  selectedDates,
  afterEOD
) => ({
  type: orderBlotterTypes.GET_ORDERS_DATA,
  payload: { selectedType, chosenAccountId, selectedDates, afterEOD },
})

export const updateOrder = (order) => ({
  type: orderBlotterTypes.UPDATE_ORDER,
  payload: { order },
})

export const setOrders = (orders) => ({
  type: orderBlotterTypes.SET_ORDERS_DATA,
  payload: { orders },
})

export const clearAllOrders = () => ({
  type: orderBlotterTypes.CLEAR_ALL_ORDERS,
})
