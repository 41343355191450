/* eslint-disable no-console */
import SockJS from 'sockjs-client'
import { Stomp } from '@stomp/stompjs'
import uuid from 'react-uuid'
import Utils from '../utils/Utils'

const TIMEOUT = 10000
let sessionId = null
let stompConnected = false

const callbackSubscription = [
  '/topic/anonymous.callbacks',
  '/topic/user.callbacks',
]
let socket = null
let stomp = null

const callbacks = {}

let connectPromise = null

// Check when we didn't get response

setInterval(() => {
  const callbackIds = Object.keys(callbacks)
  callbackIds.forEach((callbackId) => {
    const { timeSent, reject, topic } = callbacks[callbackId]
    if (Date.now() - timeSent >= TIMEOUT) {
      delete callbacks[callbackId]
      console.error(`Rejected because of timeout ${topic}`)
      reject(`Timeout.. No response to ${topic}`)
    }
  })
}, TIMEOUT)

export default class ApiService {
  static initSocket() {
    socket = new SockJS(`https://${process.env.REACT_APP_HOST}:8443/wsng`)
    if (stomp) {
      stomp.disconnect()
    }
    stomp = Stomp.over(() => socket)
    stomp.debug = () => {
      return null
    }
    const headers = {}
    if (sessionId) {
      headers.sessionId = sessionId
    }
    connectPromise = {}
    const promise = new Promise((resolve, reject) => {
      connectPromise.resolve = resolve
      connectPromise.reject = reject
    })
    stomp.connect(headers, ApiService.socketOpen, null, ApiService.socketClose)
    return promise
  }

  static incomingMessage(frame) {
    const callbackBody = frame.body ? JSON.parse(frame.body) : ''
    const { callbackId } = frame.headers
    const { message } = frame.headers
    if (callbacks[callbackId]) {
      if (message === 'ok') {
        callbacks[callbackId].resolve(callbackBody)
      } else {
        callbacks[callbackId].reject(message)
      }
      delete callbacks[callbackId]
    } else {
      console.log(
        `Received response for unknown callback HEADERS:${JSON.stringify(
          frame.headers
        )} BODY: ${JSON.stringify(callbackBody)}`
      )
    }
  }

  static socketOpen() {
    stompConnected = true
    ApiService.stompSubscribe(
      callbackSubscription[0],
      ApiService.incomingMessage
    )
  }

  static socketClose(data) {
    console.log('Socket close', data)
    stompConnected = false
    if (sessionId) {
      ApiService.logout()
    }
  }

  static setSessionId(session) {
    sessionId = session
  }

  static isConnected() {
    return stompConnected
  }

  static disconnectSocket() {
    sessionId = null
    if (stomp) {
      stomp.disconnect()
    }
  }

  static stompSubscribe(topic, callback) {
    const headers = {}
    if (sessionId) {
      headers.sessionId = sessionId
    }
    return stomp.subscribe(topic, callback, headers)
  }

  static stompExternalSubscribe(topic, callback) {
    const headers = {}
    if (sessionId) {
      headers.sessionId = sessionId
    }
    return stomp.subscribe(
      topic,
      (frame) => {
        callback(
          frame.body.length > 0 ? JSON.parse(frame.body) : null,
          frame.headers
        )
      },
      headers
    )
  }

  static stompSend(topic, params) {
    const headers = {}
    if (sessionId) {
      headers.sessionId = sessionId
    }
    headers.callbackId = uuid()
    stomp.send(topic, headers, params)
    const promise = new Promise((resolve, reject) => {
      callbacks[headers.callbackId] = {}
      callbacks[headers.callbackId].resolve = resolve
      callbacks[headers.callbackId].reject = reject
      callbacks[headers.callbackId].timeSent = Date.now()
      callbacks[headers.callbackId].topic = topic
    })
    return promise
  }

  static authenticate(username, password) {
    return ApiService.stompSend(
      '/authenticate',
      JSON.stringify({ username, password })
    )
  }

  static resetPassword(username, oldPassword, newPassword) {
    return ApiService.stompSend(
      '/expiredPassword',
      JSON.stringify({ username, oldPassword, newPassword })
    )
  }

  static postLogin() {
    ApiService.stompSubscribe(
      callbackSubscription[1],
      ApiService.incomingMessage
    )
    return ApiService.stompSend('/postLogin', JSON.stringify({}))
  }

  static getParametersByUserId(userId) {
    return ApiService.stompSend(
      '/getParametersByUserId',
      JSON.stringify({ userId })
    )
  }

  static getDailyAuctions() {
    return ApiService.stompSend('/getDailyAuctions', JSON.stringify({}))
  }

  static addAuctionOrder(
    direction,
    baseQuantity,
    userId,
    accountId,
    parentOrderId
  ) {
    return ApiService.stompSend(
      '/addOrder',
      JSON.stringify({
        direction,
        baseQuantity,
        userId,
        accountId,
        parentOrderId,
      })
    )
  }

  static cancelAuctionOrder(orderId) {
    return ApiService.stompSend(`/cancelOrder/${orderId}`, JSON.stringify({}))
  }

  static amendOrder(orderId, baseQuantity) {
    return ApiService.stompSend(
      '/amendOrderDown',
      JSON.stringify({ orderId, baseQuantity })
    )
  }

  static getTradesData(selectedType, chosenAccountId, selectedDates, afterEOD) {
    const tradesReq = Utils.createBlotterRequestObject(
      selectedType,
      chosenAccountId,
      selectedDates,
      afterEOD
    )
    return ApiService.stompSend(
      '/getAllTradesByAccountIds',
      JSON.stringify(tradesReq)
    )
  }

  static getOrdersData(selectedType, chosenAccountId, selectedDates, afterEOD) {
    const ordersReq = Utils.createBlotterRequestObject(
      selectedType,
      chosenAccountId,
      selectedDates,
      afterEOD
    )
    return ApiService.stompSend(
      '/getAllOrdersByAccountIds',
      JSON.stringify(ordersReq)
    )
  }

  static getOrderSummaryCounts(accountId) {
    return ApiService.stompSend(
      `/getSummaryPaneCounts/${accountId}`,
      JSON.stringify({})
    )
  }

  static fetchOutstandingBalance(accountId) {
    return ApiService.stompSend(
      `/getBalanceOutstanding/${accountId}`,
      JSON.stringify({})
    )
  }

  static cancelAllLiveOrders(accountId) {
    return ApiService.stompSend(
      `/cancelAllOrdersByAccount/${accountId}`,
      JSON.stringify({})
    )
  }

  static cancelCCPairLiveOrders(accountId, ccPairId) {
    const params = { accountId, ccPairId }
    return ApiService.stompSend(
      '/cancelAllOrdersOfCurrencyPair',
      JSON.stringify(params)
    )
  }

  static resetCCPairLiveOrders(accountId, ccPairId) {
    const params = { accountId, ccPairId }
    return ApiService.stompSend(
      '/resetBalanceOfCurrencyPair',
      JSON.stringify(params)
    )
  }

  static amendBalanceLiveOrder(
    direction,
    baseQuantityBefore,
    baseQuantityAfter,
    accountId,
    parentOrderId,
    currencyPairId
  ) {
    const params = {
      direction,
      baseQuantityBefore,
      baseQuantityAfter,
      accountId,
      parentOrderId,
      currencyPairId,
    }
    return ApiService.stompSend(
      '/updateBalanceOutstanding',
      JSON.stringify(params)
    )
  }

  static fetchGlobalSettings(userId) {
    return ApiService.stompSend(
      `/getUserGlobalSettings/${userId}`,
      JSON.stringify({})
    )
  }

  static updateGlobalSettings(userId, globalSettingType, settings) {
    const params = { userId, globalSettingType, settings }
    return ApiService.stompSend(
      `/updateUserGlobalSettings`,
      JSON.stringify(params)
    )
  }

  static filterOrdersByStatus(accountId, orderStatus) {
    const params = { accountId, orderStatus }
    return ApiService.stompSend('/getSummaryPaneList', JSON.stringify(params))
  }

  static downloadCSVReport(
    selectedType,
    chosenAccountId,
    selectedDates,
    dataType,
    afterEOD
  ) {
    const requestParams = Utils.createBlotterRequestObject(
      selectedType,
      chosenAccountId,
      selectedDates,
      afterEOD
    )
    const suffix =
      dataType === 'order' ? '/downloadOrdersCSV' : '/downloadTradesCSV'
    const reqUrl = `https://${process.env.REACT_APP_HOST}:8443${suffix}`
    Utils.sendXHRForCSV(reqUrl, requestParams, dataType, sessionId)
  }

  static logout() {
    // stomp.disconnect()
    window.location.reload()
  }

  static postLogout() {
    if (!stompConnected) {
      ApiService.stompSubscribe(
        callbackSubscription[0],
        ApiService.incomingMessage
      )
    }
  }
}
