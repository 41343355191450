import types from './types'

export const fetchSettings = (payload) => ({
  type: types.FETCH_GLOBAL_SETTINGS,
  payload,
})

export const setInStore = (payload) => ({
  type: types.SET_IN_STORE,
  payload,
})

export const updateGlobalSettings = (payload) => ({
  type: types.UPDATE_GLOBAL_SETTINGS,
  payload,
})
