export const getAuthState = (state) => state.authentication

export const getSessionId = (state) => {
  const { sessionData } = getAuthState(state)
  return sessionData !== null ? sessionData.sessionId : null
}

export const getUserId = (state) => {
  const { sessionData } = getAuthState(state)
  return sessionData !== null ? sessionData.user.id : null
}

export const getLoggedinUser = (state) => {
  const { sessionData } = getAuthState(state)
  return sessionData !== null ? sessionData.user : null
}

export const isAuthenticatedUser = (state) => {
  return getAuthState(state).isAuthenticated
}

export const getErrMsg = (state) => {
  const authState = getAuthState(state)
  return authState.errMsg
}
