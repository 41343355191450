import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { setInStore } from '../actions'

export default function* fetchGlobalSettings({ payload }) {
  try {
    const res = yield call(ApiService.fetchGlobalSettings, payload)
    const globalSettings = res[0]
    yield put(setInStore(globalSettings))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.FETCH_GLOBAL_SETTINGS,
        error: null,
      })
    )
  } catch (error) {
    yield put(
      stopLoading({
        loaderType: LoaderTypes.FETCH_GLOBAL_SETTINGS,
        error,
      })
    )
  }
}
