const NAMESPACE = 'PARAM_HOLDER'

const paramTypes = {
  SET_IN_STORE: `${NAMESPACE}/SET_IN_STORE`,
  GET_PARAM_BY_USER_ID: `${NAMESPACE}/GET_PARAM_BY_USER_ID`,
  ADD_BENCHMARK: `${NAMESPACE}/ADD_BENCHMARK`,
  REMOVE_ACCOUNT: `${NAMESPACE}/REMOVE_ACCOUNT`,
  ADD_ACCOUNT: `${NAMESPACE}/ADD_ACCOUNT`,
}

export default paramTypes
