import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { setAuthData, setErrorMsg, clearErrMsg } from '../actions'

export default function* authenticate({ payload: { username, password } }) {
  try {
    if (username.length > 0) {
      yield put(clearErrMsg())
      const res = yield call(ApiService.authenticate, username, password)
      const { sessionId } = res
      yield call(ApiService.setSessionId, sessionId)
      yield call(ApiService.postLogin)
      yield put(setAuthData(res))
    }
    yield put(
      stopLoading({
        loaderType: LoaderTypes.INIT_APP_AUTH_SESSION,
        error: null,
      })
    )
  } catch (error) {
    // eslint-disable-next-line
    yield put(setErrorMsg(error))
    yield put(
      stopLoading({ loaderType: LoaderTypes.INIT_APP_AUTH_SESSION, error })
    )
  }
}
