import React from 'react'
import PropTypes from 'prop-types'

const HorizontalLabel = (props) => {
  const { text, fontSize, color, high } = props
  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
      }}
    >
      <div
        style={{
          transform: 'rotate(-90deg)',
          fontWeight: 'bold',
          left: -8,
          top: high ? 7 : 12,
          position: 'relative',
          fontSize,
          color,
        }}
      >
        {text}
      </div>
    </div>
  )
}

HorizontalLabel.propTypes = {
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  high: PropTypes.number,
}

HorizontalLabel.defaultProps = {
  fontSize: undefined,
  color: '#000',
  high: 0,
}
export default HorizontalLabel
