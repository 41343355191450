import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { startLoading, stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { setErrMsg } from '../actions'

export default function* amendBalance({
  payload: {
    direction,
    baseQuantityBefore,
    baseQuantityAfter,
    accountId,
    parentOrderId,
    currencyPairId,
  },
}) {
  yield put(startLoading({ loaderType: LoaderTypes.AMEND_BALANCE_LIVE_ORDER }))
  try {
    yield call(
      ApiService.amendBalanceLiveOrder,
      direction,
      baseQuantityBefore,
      baseQuantityAfter,
      accountId,
      parentOrderId,
      currencyPairId
    )
    yield put(
      stopLoading({
        loaderType: LoaderTypes.AMEND_BALANCE_LIVE_ORDER,
        error: null,
      })
    )
  } catch (error) {
    yield put(setErrMsg(error))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.AMEND_BALANCE_LIVE_ORDER,
        error,
      })
    )
  }
}
