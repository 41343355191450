import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { stopLoading, startLoading } from '../../loaders/actions'
import { setDailyAuction } from '../actions'

export default function* getDailyAuctions() {
  yield put(startLoading({ loaderType: LoaderTypes.GET_DAILY_AUCTIONS }))
  try {
    const dailyAuctions = yield call(ApiService.getDailyAuctions)
    yield put(setDailyAuction(dailyAuctions))
    yield put(
      stopLoading({ loaderType: LoaderTypes.GET_DAILY_AUCTIONS, error: null })
    )
  } catch (error) {
    yield put(
      stopLoading({
        loaderType: LoaderTypes.GET_DAILY_AUCTIONS,
        error: `Failed to get daily auctions ${error}`,
      })
    )
  }
}
