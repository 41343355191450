/* eslint-disable no-param-reassign */
import produce from 'immer'
import moment from 'moment'
import types from './types'
import initialState from './initial'

export default function tradeBlotterReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_TRADES_DATA: {
      const nextState = produce(state, (draftState) => {
        const payloadLength = payload.trades.rowCount
          ? payload.trades.rowCount
          : payload.trades.length
        if (payloadLength > 0) {
          const newTradeState = payload.trades.dataItems
            ? payload.trades.dataItems
            : payload.trades
          newTradeState.forEach((trade) => {
            if (Array.isArray(trade.createDate)) {
              trade.createDate.pop() // remove the milliseconds from the array
              trade.createDate[1] -= 1 // decrement the month since java doesn't count months like programmers (starts at 1)
              trade.createDate = moment(trade.createDate).format(
                'DD MMM YYYY HH:mm:ss'
              )
            }
            trade.counterParty = trade.rate ? trade.postTradeCode : '' // counter party gets populated when rate > 0
          })
          draftState.tradesData = newTradeState
        } else {
          draftState.tradesData = []
        }
        return draftState
      })
      return nextState
    }
    case types.UPDATE_TRADE: {
      const nextState = produce(state, (draftState) => {
        if (
          typeof draftState.tradesData.find(
            (trade) => trade.externalId === payload.trade.externalId
          ) === 'undefined'
        ) {
          draftState.tradesData.push(payload.trade)
        }
        return draftState
      })
      return nextState
    }
    case types.CLEAR_ALL_TRADES: {
      const nextState = produce(state, (draftState) => {
        draftState.tradesData = []
        return draftState
      })
      return nextState
    }
    default:
      return state
  }
}
