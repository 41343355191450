/* eslint-disable no-param-reassign */
import produce from 'immer'
import types from './types'
import initialState from './initial'

const globalSettingsReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case types.SET_IN_STORE: {
      const { settings } = payload
      const nextState = produce(state, (draftState) => {
        draftState.confirmBalanceAmend = settings.confirmAmend
        return draftState
      })
      return nextState
    }
    default:
      return state
  }
}

export default globalSettingsReducer
