import { takeEvery } from 'redux-saga/effects'
import outstandingBalanceTypes from './types'
import fetchOutstandingBalance from './saga/fetchOutstandingBalance'
import cancelAllLiveOrders from './saga/cancelAllLiveOrders'
import cancelCCPairLiveOrders from './saga/cancelCCPairLiveOrders'
import resetCCPairLiveOrders from './saga/resetCCPairLiveOrders'
import amendBalance from './saga/amendBalanceLiveOrder'

function* outstandingBalanceWatcher() {
  yield takeEvery(
    outstandingBalanceTypes.FETCH_OUTSTANDING_CCPAIRS,
    fetchOutstandingBalance
  )
  yield takeEvery(
    outstandingBalanceTypes.RESET_CCPAIR_ORDERS,
    resetCCPairLiveOrders
  )
  yield takeEvery(
    outstandingBalanceTypes.CANCEL_CCPAIR_ORDERS,
    cancelCCPairLiveOrders
  )
  yield takeEvery(
    outstandingBalanceTypes.CANCEL_ALL_ORDERS,
    cancelAllLiveOrders
  )
  yield takeEvery(outstandingBalanceTypes.SET_AMENDED_BALANCE, amendBalance)
}
export default outstandingBalanceWatcher
