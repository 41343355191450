export const LoaderTypes = {
  INIT_APP_AUTH_SESSION: 'INIT_APP_AUTH_SESSION',
  GET_PARAMETERS_BY_USER_ID: 'GET_PARAMETERS_BY_USER_ID',
  GET_DAILY_AUCTIONS: 'GET_DAILY_AUCTIONS',
  GET_ORDER_SUMMARY_COUNTS: 'GET_ORDER_SUMMARY_COUNTS',
  ADD_AUCTION_ORDER: 'ADD_AUCTION_ORDER',
  CANCEL_AUCTION_ORDER: 'CANCEL_AUCTION_ORDER',
  FETCH_OUTSTANDING_CCPAIRS: 'FETCH_OUTSTANDING_CCPAIRS',
  CANCEL_CCPAIR_LIVE_ORDERS: 'CANCEL_CCPAIR_LIVE_ORDERS',
  CANCEL_ALL_LIVE_ORDERS: 'CANCEL_ALL_LIVE_ORDERS',
  RESET_CCPAIR_LIVE_ORDERS: 'RESET_CCPAIR_LIVE_ORDERS',
  FILTER_ORDERS_BY_STATUS: 'FILTER_ORDERS_BY_STATUS',
  AMEND_BALANCE_LIVE_ORDER: 'AMEND_BALANCE_LIVE_ORDER',
  FETCH_GLOBAL_SETTINGS: 'FETCH_GLOBAL_SETTINGS',
  UPDATE_GLOBAL_SETTINGS: 'UPDATE_GLOBAL_SETTINGS',
}

export const LoaderStatus = {
  LOADING: 'LOADING',
}
