import { takeEvery } from 'redux-saga/effects'
import tradingTypes from './types'
import getDailyAuctions from './saga/getDailyAuction'
import addAuctionOrder from './saga/addAuctionOrder'
import amendOrder from './saga/amendOrder'
import cancelAuctionOrder from './saga/cancelAuctionOrder'
import fetchOrderSummaryCounts from './saga/getOrderSummaryCounts'
import filterOrdersByStatus from './saga/filterOrdersByStatus'

function* tradingWatcher() {
  yield takeEvery(tradingTypes.GET_DAILY_AUCTIONS, getDailyAuctions)
  yield takeEvery(
    tradingTypes.GET_ORDER_SUMMARY_COUNTS,
    fetchOrderSummaryCounts
  )
  yield takeEvery(tradingTypes.ADD_AUCTION_ORDER, addAuctionOrder)
  yield takeEvery(tradingTypes.AMEND_ORDER, amendOrder)
  yield takeEvery(tradingTypes.CANCEL_AUCTION_ORDER, cancelAuctionOrder)
  yield takeEvery(tradingTypes.FILTER_ORDERS_BY_STATUS, filterOrdersByStatus)
}
export default tradingWatcher
