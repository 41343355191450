import types from './types'

export const fetchBalance = (payload) => ({
  type: types.FETCH_OUTSTANDING_CCPAIRS,
  payload,
})

export const setInStore = (payload) => ({
  type: types.SET_OUTSTANDING_CCPAIRS,
  payload,
})

export const cancelAllLiveOrders = (payload) => ({
  type: types.CANCEL_ALL_ORDERS,
  payload,
})

export const cancelCCPairLiveOrders = (accountId, ccPairId) => ({
  type: types.CANCEL_CCPAIR_ORDERS,
  payload: { accountId, ccPairId },
})

export const resetCCPairLiveOrders = (accountId, ccPairId) => ({
  type: types.RESET_CCPAIR_ORDERS,
  payload: { accountId, ccPairId },
})

export const resetCCpairBalance = (payload) => ({
  type: types.RESET_CCPAIR_BALANCE,
  payload,
})

export const clearOutstandingBalance = () => ({
  type: types.CLEAR_OUTSTANDING_BALANCE,
})

export const toggleEditModeLiveOrder = (parentOrderId, editMode) => ({
  type: types.TOGGLE_EDIT_MODE_BALANCE,
  payload: { parentOrderId, editMode },
})

export const setEditModeLiveOrderId = (payload) => ({
  type: types.SET_EDIT_MODE_ID,
  payload,
})

export const setAmendedBalance = (payload) => ({
  type: types.SET_AMENDED_BALANCE,
  payload,
})

export const setErrMsg = (payload) => ({
  type: types.SET_ERR_MSG,
  payload,
})

export const setOrderParentId = (
  parentOrderId,
  benchmarkId,
  currencyPairId
) => ({
  type: types.SET_ORDER_PARENT_ID,
  payload: { parentOrderId, benchmarkId, currencyPairId },
})
