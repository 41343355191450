/* eslint-disable no-param-reassign */
import produce from 'immer'
import types from './types'
import initialState from './initial'
import { LoaderStatus } from './loaderTypesEnum'

export default function loadersReducer(state = initialState, action) {
  const { payload } = action
  const { loaderType, error } = payload || {}
  switch (action.type) {
    case types.START_LOADING: {
      const nextState = produce(state, (draftState) => {
        draftState[loaderType] = { status: LoaderStatus.LOADING, error: null }
      })
      return nextState
    }
    case types.STOP_LOADING: {
      const nextState = produce(state, (draftState) => {
        draftState[loaderType] = { status: null, error }
      })
      return nextState
    }
    case types.CLEAN_ERROR: {
      const nextState = produce(state, (draftState) => {
        draftState[loaderType] = { status: null, error: null }
      })
      return nextState
    }
    default:
      return state
  }
}
