import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

// styles
import './taSnackbar.css'

// assets
import { CheckCircle, Info, Warning, Error } from '@material-ui/icons'

const TaSnackbar = (props) => {
  const { classes, variant, position, message, show, showIcon } = props
  const [closeAnimation, setCloseAnimation] = useState('')

  // close Snackbar
  const closeSnackbar = () => {
    // time for the closing animation to happen
    setTimeout(() => {
      setCloseAnimation('close')
    }, 100)
  }

  // icon message type
  const iconType = () => {
    let icon
    switch (variant) {
      case 'success':
        icon = (
          <i className="icon">
            <CheckCircle className="icon-svg" />
          </i>
        )
        break
      case 'info':
        icon = (
          <i className="icon">
            <Info className="icon-svg" />
          </i>
        )
        break
      case 'error':
        icon = (
          <i className="icon">
            <Error className="icon-svg" />
          </i>
        )
        break
      case 'warning':
        icon = (
          <i className="icon">
            <Warning className="icon-svg" />
          </i>
        )
        break

      default:
        icon = <i className="icon" />
        break
    }
    return icon
  }

  if (show) {
    return (
      <div
        role="alert"
        className={`ta-snackbar ${closeAnimation} ${variant} ${position} ${classes}`}
        aria-describedby="snackbar-msg"
      >
        {/* changes by variant=type */}
        {showIcon ? (
          <span className="icon-warpper mr-r-lr">{iconType()}</span>
        ) : null}
        <div className="content">
          <p id="snackbar-msg" title={message} className="message">
            {message}
          </p>
        </div>
        <button type="button" className="btn" onClick={closeSnackbar}>
          <i className="icon close">
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
            </svg>
          </i>
        </button>
      </div>
    )
  }
  return null
}

TaSnackbar.propTypes = {
  classes: PropTypes.string,
  variant: PropTypes.string,
  position: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  showIcon: PropTypes.bool,
}

TaSnackbar.defaultProps = {
  classes: '',
  variant: 'default',
  position: 'bottom-right',
  message: '',
  show: false,
  showIcon: false,
}

export default memo(TaSnackbar)
