import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { stopLoading, startLoading } from '../../loaders/actions'
import { setOrderSummaryCounts } from '../actions'

export default function* fetchOrderSummaryCounts({ payload: accountId }) {
  yield put(startLoading({ loaderType: LoaderTypes.GET_DAILY_AUCTIONS }))
  try {
    const summaryCounts = yield call(
      ApiService.getOrderSummaryCounts,
      accountId
    )
    yield put(setOrderSummaryCounts(summaryCounts))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.GET_ORDER_SUMMARY_COUNTS,
        error: null,
      })
    )
  } catch (error) {
    yield put(
      stopLoading({
        loaderType: LoaderTypes.GET_ORDER_SUMMARY_COUNTS,
        error: `Failed to get daily auctions ${error}`,
      })
    )
  }
}
