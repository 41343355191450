import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { setInStore } from '../actions'

export default function* getParametersByUserId({ payload }) {
  try {
    const parameters = yield call(ApiService.getParametersByUserId, payload)
    yield put(setInStore(parameters))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.GET_PARAMETERS_BY_USER_ID,
        error: null,
      })
    )
  } catch (error) {
    yield put(
      stopLoading({
        loaderType: LoaderTypes.GET_PARAMETERS_BY_USER_ID,
        error: `Falied to get parameterHolder ${error}`,
      })
    )
  }
}
