import { put, call } from 'redux-saga/effects'
import { stopLoading, startLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import ApiService from '../../../api/ApiService'

export default function* amendOrder({ payload: { orderId, baseQuantity } }) {
  yield put(startLoading({ loaderType: LoaderTypes.ADD_AUCTION_ORDER }))
  try {
    yield call(ApiService.amendOrder, orderId, baseQuantity)
    yield put(
      stopLoading({ loaderType: LoaderTypes.ADD_AUCTION_ORDER, error: null })
    )
  } catch (error) {
    yield put(stopLoading({ loaderType: LoaderTypes.ADD_AUCTION_ORDER, error }))
  }
}
