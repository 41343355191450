import React, { memo, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, List, ListItem, makeStyles } from '@material-ui/core'
import { getBenchmarks } from '../../ducks/paramHolder/selectors'
import ApiService from '../../api/ApiService'
import { AuctionStatus } from '../../consts/TaConsts'
import { getIsLoadingByType } from '../../ducks/loaders/selectors'
import { LoaderTypes } from '../../ducks/loaders/loaderTypesEnum'
import {
  updateAuctionStatus,
  addAuction,
  cancelAuction,
  auctionInitiated,
  getDailyAuction,
  setBenchmarksCurrentStatus,
  decrementFixingEventsRunTime,
  getOrdersByStatus,
} from '../../ducks/trading/actions'
import {
  getSelectedAccountId,
  getSortedDailyAuctions,
  getBenchmarksCurrentStatus,
} from '../../ducks/trading/selectors'
import { addBenchmark } from '../../ducks/paramHolder/actions'
import BenchmarkItem from './BenchMarkItem'
import useResizeScreen from '../../common-components/hooks/useResizeScreen'

const consts = {
  AUCTION_TERMINATED: 'AUCTION_TERMINATED',
  AUCTION_ADDED: 'AUCTION_ADDED',
  AUCTION_INITIATED: 'AUCTION_INITIATED',
  AUCTION_CANCELED: 'AUCTION_CANCELED',
  RATE_UPDATE: 'RATE_UPDATE',
  BENCHMARK_ADD: 'BENCHMARK_ADD',
  START_OF_DAY: 'START_OF_DAY',
}

const useStyles = makeStyles({
  root: {
    '&$tall': {
      height: '54.7vh',
    },
    height: '49vh',
    paddingRight: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  item: {
    '&:first-child': {
      margin: 0,
    },
    fontSize: 14,
    padding: 0,
    borderBottom: 0,
    marginTop: 10,
  },
  selected: {
    backgroundColor: '#3a3a3a',
  },
  list: {
    color: '#fefefe',
    width: '95%',
    padding: '0 11px 10px 20px',
    maxHeight: 400,
  },
  buttonsContainer: {
    '&$wide': {
      right: 40,
    },
    position: 'absolute',
    right: 0,
    top: 21,
  },
  wide: {},
  tall: {},
})

const BenchmarkList = () => {
  const dispatch = useDispatch()
  const benchmarks = useSelector(getBenchmarks)
  const dailyAuctions = useSelector(getSortedDailyAuctions)
  const isAuctionLoading = useSelector((state) =>
    getIsLoadingByType(state, LoaderTypes.GET_DAILY_AUCTIONS)
  )
  const accountId = useSelector(getSelectedAccountId)
  const benchmarkCurrentStatus = useSelector(getBenchmarksCurrentStatus)
  const classes = useStyles()
  const height = useResizeScreen()
  const timerId = useRef(null)
  const isWideScreen = height > 900

  const disableTimer = () => {
    clearInterval(timerId.current)
    timerId.current = null
  }

  useEffect(() => {
    const initiateTimer = () => {
      const decrementTime = () => {
        dispatch(decrementFixingEventsRunTime())
      }
      if (timerId.current === null) {
        timerId.current = setInterval(decrementTime, 1000)
      }
    }
    if (
      dailyAuctions.some((item) => item.auction.status === AuctionStatus.LIVE)
    ) {
      initiateTimer()
    } else {
      disableTimer()
    }
  }, [dailyAuctions, dispatch])

  const handleBenchmarkNotification = useCallback(
    (data, headers) => {
      switch (headers.systemAction) {
        case consts.BENCHMARK_ADD: {
          dispatch(addBenchmark(data))
          break
        }
        default:
          // eslint-disable-next-line no-console
          console.warn(
            `received notificaiton for type ${headers.systemAction} data: ${data}`
          )
      }
    },
    [dispatch]
  )

  const handleAuctionNotification = useCallback(
    (data, headers) => {
      dispatch(setBenchmarksCurrentStatus())
      switch (headers.systemAction) {
        case consts.AUCTION_TERMINATED:
          dispatch(
            updateAuctionStatus({ auctionId: data, status: AuctionStatus.DONE })
          )
          dispatch(getOrdersByStatus(accountId, 'ALL'))
          break
        case consts.AUCTION_ADDED: {
          dispatch(addAuction({ auction: data }))
          break
        }
        case consts.AUCTION_CANCELED: {
          dispatch(cancelAuction({ auctionId: data }))
          break
        }
        case consts.AUCTION_INITIATED: {
          dispatch(auctionInitiated({ auction: data }))
          break
        }

        default:
          // eslint-disable-next-line no-console
          console.warn(
            `received notificaiton for type ${headers.systemAction} data: ${data}`
          )
      }
    },
    [dispatch, accountId]
  )

  useEffect(() => {
    ApiService.stompExternalSubscribe(
      '/topic/system.auction',
      handleAuctionNotification
    )

    ApiService.stompExternalSubscribe(
      '/topic/system.benchmark',
      handleBenchmarkNotification
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getDailyAuction())
  }, [dispatch])

  useEffect(() => {
    dispatch(setBenchmarksCurrentStatus())
  }, [dispatch, dailyAuctions])

  const sortedBenchMarks = benchmarks.slice().sort((a, b) => {
    const indexA = dailyAuctions.findIndex(
      (item) => item.auction.benchmarkId === a.id
    )
    const indexB = dailyAuctions.findIndex(
      (item) => item.auction.benchmarkId === b.id
    )

    return indexA - indexB
  })

  const filteredBenchmarks = sortedBenchMarks.filter((benchmark) => {
    return dailyAuctions.some(
      (item) => item.auction.benchmarkId === benchmark.id
    )
  })

  return (
    <div className={`${classes.root} ${isWideScreen ? classes.tall : ''}`}>
      <Grid container>
        <Grid item md={12}>
          <List className={classes.list} loading={isAuctionLoading.toString()}>
            {filteredBenchmarks.map(
              (benchmark) =>
                benchmark != null && (
                  <ListItem key={benchmark.id} className={classes.item}>
                    <BenchmarkItem
                      benchmarkId={benchmark.id}
                      benchmark={benchmark}
                      benchmarkCurrentStatus={benchmarkCurrentStatus}
                    />
                  </ListItem>
                )
            )}
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

export default memo(BenchmarkList)
