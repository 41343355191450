import { call, put } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { setOrders } from '../actions'

export default function* getOrdersData({
  payload: { selectedType, chosenAccountId, selectedDates, afterEOD },
}) {
  try {
    const res = yield call(
      ApiService.getOrdersData,
      selectedType,
      chosenAccountId,
      selectedDates,
      afterEOD
    )
    yield put(setOrders(res))
  } catch (error) {
    // eslint-disable-next-line
    console.error('error', error)
  }
}
