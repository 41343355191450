import { call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'

function* logout() {
  try {
    yield call(ApiService.logout)
    yield call(ApiService.initSocket)
  } catch (error) {
    // eslint-disable-next-line
    console.log('err')
  }
}

export default logout
