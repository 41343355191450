import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Button,
} from '@material-ui/core'
import Settings from '@material-ui/icons/Settings'
import {
  getLoggedinUser,
  getUserId,
} from '../../ducks/authentication/selectors'
import { getSelectedAccountId } from '../../ducks/trading/selectors'
import { selectAccount } from '../../ducks/trading/actions'
import { getAccounts } from '../../ducks/paramHolder/selectors'
import { setFilterAccount } from '../../ducks/blotter/actions'
import { logout } from '../../ducks/authentication/actions'
import { fetchSettings } from '../../ducks/globalSettings/actions'

import GlobalSettingsDialog from '../../common-components/dialog/globalSettingsDialog/GlobalSettingsDialog'

const useStyles = makeStyles({
  container: {
    height: 34,
    width: '100%',
    padding: '8px 0 0 0',
    backgroundColor: 'var(--theme-widget-bg-color)',
  },
  logo: {
    height: 20,
    width: 117,
    display: 'inline-block',
  },
  headerCont: {
    textIndent: '13%',
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  ordersHeader: {
    maxWidth: '7%',
    textAlign: 'right',
  },
  dropDown: {
    '&:before': {
      borderColor: 'var(--theme-grey-color)',
    },
    '&:after': {
      borderColor: 'var(--theme-grey-color)',
    },
    color: 'var(--theme-grey-color)',
    fontWeight: 'bold',
    fontSize: 13,
    fontFamily: 'Open Sans',
    margin: '-1px 0 0 20px',
  },
  icon: {
    fill: 'var(--theme-grey-color)',
  },
  user: {
    fontSize: 13,
    fontWeight: 'normal',
    textAlign: 'right',
    marginLeft: 4,
    lineHeight: '26px',
  },
  logout: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: 'var(--theme-grey-color)',
    cursor: 'pointer',
    textTransform: 'none',
    padding: 0,
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: 'var(--theme-grey-color)',
  },
  menu: {
    fontFamily: 'var(--theme-font-family)',
    fontWeight: 'bold',
    fontSize: 13,
  },
  icap: {
    marginLeft: 6,
    backgroundImage: `url("/images/ICAP_logo.svg")`,
    width: 66,
  },
  spot: {
    verticalAlign: 'middle',
    marginLeft: 6,
    backgroundImage: `url("/images/SpotMatch_logo.svg")`,
    width: 104,
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  settingsBtn: {
    margin: '0 10px 0 5px',
    cursor: 'pointer',
    transition: 'fill 0.3s',
    '&:hover': {
      fill: 'var(--theme-white-color)',
    },
  },
})

const Header = () => {
  const dispatch = useDispatch()
  const accounts = useSelector(getAccounts)
  const selectedAccountId = useSelector(getSelectedAccountId)
  const userId = useSelector(getUserId)
  const user = useSelector(getLoggedinUser)
  const classes = useStyles()
  const [showGlobalSettings, setShowGlobalSettings] = useState(false)

  useEffect(() => {
    if (userId !== '') {
      dispatch(fetchSettings(userId))
    }
  }, [userId, dispatch])

  const handleChange = (e) => {
    const accountId = e.target.value
    dispatch(selectAccount({ accountId }))
    dispatch(setFilterAccount(accountId))
  }

  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
  }

  const openGlobalSettings = () => {
    setShowGlobalSettings(true)
  }

  const closeGlobalSettings = () => {
    setShowGlobalSettings(false)
  }

  useEffect(() => {
    if (accounts.length > 0 && selectedAccountId === '') {
      const accountId = accounts[0].id
      dispatch(selectAccount({ accountId }))
      dispatch(setFilterAccount(accountId))
    }
  }, [accounts, dispatch, selectedAccountId])

  return (
    <>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container justify="flex-start">
          <Grid item md={3}>
            <div
              alt="ICAP Logo"
              className={`${classes.logo} ${classes.icap}`}
            />
            <div
              alt="Spot Match Logo"
              className={`${classes.logo} ${classes.spot}`}
            />
          </Grid>
          <Grid item md={4} className={classes.headerCont} />
          <Grid item md={5} className={classes.rightContainer}>
            <FormControl>
              <Select
                className={classes.dropDown}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    select: classes.select,
                  },
                }}
                disableUnderline
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                onChange={handleChange}
                value={selectedAccountId}
                defaultValue={accounts[0].id}
              >
                {accounts.map((account) => (
                  <MenuItem
                    key={account.id}
                    value={account.id}
                    className={classes.menu}
                  >
                    {account.accountId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <span className={classes.user}>
              {user.userName} @ {user.org.name}&nbsp;&nbsp;
            </span>
            <Settings
              className={classes.settingsBtn}
              onClick={openGlobalSettings}
            />
            <Button className={classes.logout} onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
        {showGlobalSettings && (
          <div>
            <GlobalSettingsDialog onClose={closeGlobalSettings}>
              Global settings dialog
            </GlobalSettingsDialog>
          </div>
        )}
      </Container>
    </>
  )
}

export default memo(Header)
