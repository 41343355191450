import { LoaderTypes, LoaderStatus } from './loaderTypesEnum'

const InitialState = {}
Object.keys(LoaderTypes).forEach((key) => {
  let status = null
  if (
    key === LoaderTypes.INIT_APP_AUTH_SESSION ||
    key === LoaderTypes.GET_PARAMETERS_BY_USER_ID
  ) {
    status = LoaderStatus.LOADING
  }
  InitialState[key] = { status, error: '' }
})

export default InitialState
