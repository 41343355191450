import { put, call } from 'redux-saga/effects'
import ApiService from '../../../api/ApiService'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import { setInStore } from '../actions'

export default function* fetchOutstandingBalance({ payload: accountId }) {
  try {
    const ccPairs = yield call(ApiService.fetchOutstandingBalance, accountId)
    yield put(setInStore(ccPairs))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.FETCH_OUTSTANDING_CCPAIRS,
        error: null,
      })
    )
  } catch (error) {
    // eslint-disable-next-line
    console.log('err', error)
    yield put(
      stopLoading({
        loaderType: LoaderTypes.FETCH_OUTSTANDING_CCPAIRS,
        error: `Falied to get parameterHolder ${error}`,
      })
    )
  }
}
