import { put, call } from 'redux-saga/effects'

import ApiService from '../../../api/ApiService'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'

export default function* resetPassword({
  payload: { username, oldPassword, newPassword },
}) {
  try {
    yield call(ApiService.resetPassword, username, oldPassword, newPassword)
  } catch (error) {
    yield put(
      stopLoading({ loaderType: LoaderTypes.INIT_APP_AUTH_SESSION, error })
    )
  }
}
