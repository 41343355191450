/* eslint-disable class-methods-use-this */
import React, { memo, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'
import {
  getSessionId,
  isAuthenticatedUser,
  getErrMsg,
} from './ducks/authentication/selectors'
import { LoaderTypes } from './ducks/loaders/loaderTypesEnum'
import { getIsLoadingByType, getLoaderError } from './ducks/loaders/selectors'
import { authenticate, resetPassword } from './ducks/authentication/actions'
import FirstLoginDialog from './common-components/dialog/firstLoginDialog/FirstLoginDialog'
import { ErrStrings } from './consts/TaConsts'
import './styles/login.css'
import icapLogo from './images/ICAP_logo.svg'
import spotMatchLogo from './images/SpotMatch_logo-login.svg'
import ApiService from './api/ApiService'
import BlankLoadingScreen from './common-components/blankLoadingScreen/BlankLoadingScreen'
import { stopLoading } from './ducks/loaders/actions'

const App = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [connected, setConnected] = useState(false)
  const retryCounter = useRef(0)
  const [displayDialog, setDisplayDialog] = useState(false)
  const [loginClicked, setLoginClicked] = useState(false)
  const appLoading = useSelector((state) =>
    getIsLoadingByType(state, LoaderTypes.INIT_APP_AUTH_SESSION)
  )
  const appLoadingError = useSelector((state) =>
    getLoaderError(state, LoaderTypes.INIT_APP_AUTH_SESSION)
  )
  const sessionId = useSelector(getSessionId)
  const isAuthenticated = useSelector(isAuthenticatedUser)
  const loginErrMsg = useSelector(getErrMsg)
  const dispatch = useDispatch()

  useEffect(() => {
    const checkAuthAndSetTimeout = () => {
      retryCounter.current += 1
      if (!ApiService.isConnected()) {
        // console.warn("Socket not ready.. Will try again in 1 seconds")
        if (retryCounter.current < 5) {
          setTimeout(checkAuthAndSetTimeout, 1000)
        } else {
          dispatch(
            stopLoading({
              loaderType: LoaderTypes.INIT_APP_AUTH_SESSION,
              error: null,
            })
          )
        }
      } else if (appLoadingError !== 'Missing username') {
        setConnected(true)
        dispatch(authenticate('', ''))
      }
    }

    const updateStateAccordingToServerError = () => {
      const cond =
        appLoadingError != null &&
        (appLoadingError.toLowerCase() === 'first login' ||
          appLoadingError.toLowerCase() === 'password has expired' ||
          appLoadingError === 'Error - Please do not use previous passwords')
      setDisplayDialog(cond)
      setLoginClicked(false)
    }

    checkAuthAndSetTimeout()
    if (loginClicked && appLoadingError != null) {
      updateStateAccordingToServerError()
    }
  }, [loginClicked, dispatch, appLoadingError, retryCounter])

  const handleInputChange = (e) => {
    const { name } = e.target
    if (name === 'username') setUsername(e.target.value)
    else setPassword(e.target.value)
  }

  const checkUserAuth = (e) => {
    e.preventDefault()
    dispatch(authenticate(username, password))
    setLoginClicked(true)
  }

  const setNewPasswordAndLogin = (uname, oldPassword, newPassword) => {
    dispatch(resetPassword(uname, oldPassword, newPassword))

    setTimeout(() => {
      dispatch(authenticate(uname, newPassword))
    }, 2000)
  }

  const closeFirstLoginDialog = () => {
    setDisplayDialog(false)
  }

  const firstLoginDialog = (
    <FirstLoginDialog
      isOpen={displayDialog}
      attemptResetPassword={setNewPasswordAndLogin}
      handleClose={closeFirstLoginDialog}
      username={username}
      oldPassword={password}
      errorFromServer={ErrStrings[loginErrMsg]}
    />
  )
  if (appLoading) {
    return <BlankLoadingScreen />
  }
  if (!ApiService.isConnected() && connected === false) {
    return (
      <div className="serverMsg">
        Server is unavailable, please contact support
      </div>
    )
  }
  return (
    <>
      {isAuthenticated && sessionId && <Redirect to="/trading" />}
      <div className="loginPage">
        <div className="logo">
          <img src={icapLogo} alt="ICAP Logo" className="icap" />
          <img
            src={spotMatchLogo}
            alt="Spot Match Logo"
            className="spotmatch"
          />
        </div>
        <form onSubmit={checkUserAuth} className="loginForm">
          <div className="loginFormInner">
            <label htmlFor="username" className="inputSection">
              Username
              <input
                className={classNames('usernameField', 'loginField')}
                name="username"
                id="username"
                onChange={handleInputChange}
                value={username}
              />
            </label>
            <label htmlFor="password" className="inputSection">
              Password
              <input
                id="password"
                className={classNames('passwordField', 'loginField')}
                type="password"
                name="password"
                autoComplete="off"
                onChange={handleInputChange}
                value={password}
              />
            </label>
            <input className="loginButton" type="submit" value="LOGIN" />
          </div>
          {loginErrMsg != null && loginErrMsg.length > 0 && (
            <div className="error">{ErrStrings[loginErrMsg.toLowerCase()]}</div>
          )}
        </form>
        {firstLoginDialog}
      </div>
    </>
  )
}

export default memo(App)
