import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Grid, makeStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import TradeBlotter from '../../components/tradeBlotter/tradeBlotter'
import OrderBlotter from '../../components/orderBlotter/orderBlotter'
import FilterPanel from '../../components/filterPanel/FilterPanel'

const StyledTabs = withStyles({
  root: {
    minHeight: 25,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'var(--theme-blue-color)',
    marginBottom: 1,
  },
})((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tabs {...props} />
))

const TabPanel = (props) => {
  const { children, value, index, selectedTab } = props

  return (
    <div hidden={value !== selectedTab} id={`simple-tabpanel-${index}`}>
      {value === selectedTab && <div>{children}</div>}
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    minHeight: 25,
    fontSize: '0.7rem',
  },
  container: {
    borderTop: '1px solid rgba(110, 110, 110, 0.5)',
  },
})

const Blotter = () => {
  const [selectedTab, setSelectedTab] = useState('order')
  const classes = useStyles()

  const handleTabChange = (event, tabValue) => {
    setSelectedTab(tabValue)
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item md={12}>
          <StyledTabs onChange={handleTabChange} value={selectedTab}>
            <Tab label="Orders" value="order" className={classes.root} />
            <Tab label="Trades" value="trade" className={classes.root} />
          </StyledTabs>
          <FilterPanel type={selectedTab} />
          <TabPanel value="order" index={0} selectedTab={selectedTab}>
            <OrderBlotter />
          </TabPanel>
          <TabPanel value="trade" index={1} selectedTab={selectedTab}>
            <TradeBlotter />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  )
}

TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedTab: PropTypes.string.isRequired,
}

TabPanel.defaultProps = {
  children: null,
}

export default memo(Blotter)
