import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgGrid from '../../common-components/agGrid/agGrid'
import DirectionRenderer from '../../common-components/directionCellRenderer/DirectionCellRenderer'
import columns from './orderBlotterColumns'
import {
  getOrders,
  setOrders,
  updateOrder,
} from '../../ducks/orderBlotter/actions'
import * as orderSelectors from '../../ducks/orderBlotter/selectors'
import {
  getBenchmarksMap,
  getCcPairMap,
  getEndOfDay,
} from '../../ducks/paramHolder/selectors'
import {
  getSelectedAccount,
  getSelectedDates,
  getSelectedType,
} from '../../ducks/blotter/selectors'
import ApiService from '../../api/ApiService'
import useResizeScreen from '../../common-components/hooks/useResizeScreen'
import Utils from '../../utils/Utils'

const consts = {
  LIVE_ORDERS: 'LIVE_ORDERS',
}

const OrderBlotter = () => {
  const height = useResizeScreen()
  const dispatch = useDispatch()
  const ordersData = useSelector(orderSelectors.getOrders)
  const benchmarksMap = useSelector(getBenchmarksMap)
  const ccPairsMap = useSelector(getCcPairMap)
  const chosenAccountId = useSelector(getSelectedAccount)
  const selectedDates = useSelector(getSelectedDates)
  const selectedType = useSelector(getSelectedType)
  const endOfDay = useSelector(getEndOfDay)

  useEffect(() => {
    try {
      if (chosenAccountId) {
        const afterEOD = Utils.checkIfAfterEOD(endOfDay)
        dispatch(
          getOrders(selectedType, chosenAccountId, selectedDates, afterEOD)
        )
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error while getting redux props ', e)
    }
  }, [dispatch, chosenAccountId, selectedType, selectedDates, endOfDay])

  useEffect(() => {
    const handleOrderPush = (data, headers) => {
      switch (headers.systemAction) {
        case consts.LIVE_ORDERS:
          dispatch(updateOrder(data))
          break
        default:
      }
    }
    ApiService.stompExternalSubscribe(
      `/topic/system.blotterLiveOrders.${chosenAccountId}`,
      handleOrderPush
    )
  }, [chosenAccountId, dispatch])

  const saveSettings = () => {}

  const onGridReady = () => {
    try {
      dispatch(setOrders(ordersData))
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error while preparing the grid data', e)
    }
  }

  const getParsedData = () => {
    const parsedOrdersData = []

    ordersData.forEach((order) => {
      const modifiedOrder = JSON.parse(JSON.stringify(order))
      const ccPairObj = ccPairsMap.get(order.ccPairId)
      const benchmarkObj = benchmarksMap.get(order.benchmarkId)
      modifiedOrder.currencyPair = `${ccPairObj.instrument1.name} / ${ccPairObj.instrument2.name}`
      modifiedOrder.benchmark = benchmarkObj.name
      parsedOrdersData.push(modifiedOrder)
    })

    return parsedOrdersData
  }

  const isUseDelta = ordersData && ordersData.length > 0
  const parsedOrdersData = getParsedData()
  const factor = height > 800 ? 32 : 29

  return (
    <div style={{ height: `${(height * factor) / 100}px` }}>
      <AgGrid
        onSave={saveSettings}
        rowData={parsedOrdersData}
        colState={null}
        sortState={null}
        filterState={null}
        columnDefs={columns}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
          hide: false,
        }}
        frameworkComponents={{
          directionRenderer: DirectionRenderer,
        }}
        tooltipShowDelay={0}
        handleGridReady={onGridReady}
        deltaRowDataMode={isUseDelta}
        getRowNodeId={(data) => {
          return data.orderId
        }}
        loadingSpinner
        enableBrowserTooltips
      />
    </div>
  )
}

export default memo(OrderBlotter)
