/* eslint-disable no-param-reassign */
import produce from 'immer'
import types from './types'
import initialState from './initial'

const outstandingBalanceReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case types.SET_OUTSTANDING_CCPAIRS: {
      const nextState = produce(state, (draftState) => {
        draftState.outstandingBalanceList = payload
        const { inEditModeOrderParentId } = draftState
        draftState.outstandingBalanceList.forEach((item) => {
          if (item.parentOrderId === inEditModeOrderParentId) {
            item.editMode = true
          } else {
            item.editMode = false
          }
        })
        return draftState
      })
      return nextState
    }
    case types.CLEAR_OUTSTANDING_BALANCE: {
      const nextState = produce(state, (draftState) => {
        draftState.outstandingBalanceList = []
        return draftState
      })
      return nextState
    }
    case types.RESET_CCPAIR_BALANCE: {
      const ccPairId = payload
      const nextState = produce(state, (draftState) => {
        const ccPairBalanceRow = draftState.outstandingBalanceList.find(
          (item) => item.ccPairId === ccPairId
        )
        ccPairBalanceRow.balance = 0
        return draftState
      })
      return nextState
    }
    case types.TOGGLE_EDIT_MODE_BALANCE: {
      const { parentOrderId, editMode } = payload
      const nextState = produce(state, (draftState) => {
        const parentOrderBalanceRow = draftState.outstandingBalanceList.find(
          (item) => item.parentOrderId === parentOrderId
        )
        parentOrderBalanceRow.editMode = editMode
        if (parentOrderId === draftState.inEditModeOrderParentId) {
          draftState.inEditModeOrderParentId = undefined
        }
        return draftState
      })
      return nextState
    }
    case types.SET_EDIT_MODE_ID: {
      const nextState = produce(state, (draftState) => {
        draftState.inEditModeOrderParentId = payload
        return draftState
      })
      return nextState
    }
    case types.SET_ERR_MSG: {
      const nextState = produce(state, (draftState) => {
        draftState.globalErrMsg = payload
        return draftState
      })
      return nextState
    }
    case types.SET_ORDER_PARENT_ID: {
      const { parentOrderId, benchmarkId, currencyPairId } = payload
      const nextState = produce(state, (draftState) => {
        const initiatedFixingOrderRow = draftState.outstandingBalanceList.find(
          (item) =>
            item.benchmarkId === benchmarkId && item.ccPairId === currencyPairId
        )
        if (initiatedFixingOrderRow)
          initiatedFixingOrderRow.parentOrderId = parentOrderId
        return draftState
      })
      return nextState
    }
    default:
      return state
  }
}

export default outstandingBalanceReducer
