/* eslint-disable no-param-reassign */
import produce from 'immer'
import types from './types'
import initialState from './initial'

export default function paramHolderReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_IN_STORE: {
      const nextState = produce(state, (draftState) => {
        draftState = payload
        return draftState
      })
      return nextState
    }
    case types.ADD_BENCHMARK: {
      const nextState = produce(state, (draftState) => {
        const { org } = payload
        draftState.benchmarks.push(org)
        return draftState
      })
      return nextState
    }
    case types.ADD_ACCOUNT: {
      const nextState = produce(state, (draftState) => {
        draftState.accounts.push(payload)
        return draftState
      })
      return nextState
    }
    case types.REMOVE_ACCOUNT: {
      const nextState = produce(state, (draftState) => {
        draftState.accounts = draftState.accounts.filter(
          (account) => account.id !== payload.id
        )
        return draftState
      })
      return nextState
    }
    default:
      return state
  }
}
