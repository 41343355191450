import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    '& h2': {
      color: '#199BBE',
      fontFamily: 'var(--theme-font-family)',
      fontWeight: 'bold',
      fontSize: 16,
      textAlign: 'center',
    },
    padding: '10px 0 0 0',
    height: 30,
    width: '100%',
  },
  ccPair: {
    fontSize: 14,
    whiteSpace: 'pre-line',
  },
  white: {
    color: '#fff',
    fontWeight: 600,
    flex: '1 1 auto',
    padding: '8px 24px',
    overflowY: 'auto',
  },
  btn: {
    '&:hover': {
      opacity: 0.8,
    },
    '&$submit': {
      '&:hover': {
        backgroundColor: '#199bbe',
        opacity: 1,
        border: '1px solid #1dcaf9',
      },
      color: '#fff',
      backgroundColor: '#0180a0',
    },
    '&$cancel': {
      border: '1px solid #6e6e6e',
      borderRadius: 0,
      color: '#6e6e6e',
    },
    '&$second': {
      marginLeft: 8,
    },
    textAlign: 'center',
    height: 30,
    fontWeight: 600,
    fontSize: 13,
    backgroundColor: 'transparent',
    borderRadius: 0,
    minWidth: 103,
  },
  paper: {
    boxShadow: 'none',
    backgroundColor: 'var(--theme-widget-bg-color)',
  },
  actions: {
    flex: '0 0 auto',
    display: 'flex',
    padding: 8,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  submit: {},
  cancel: {},
  second: {},
})

const ConfirmContent = ({ msg, onClose, onConfirm, type, show, title }) => {
  const classes = useStyles()

  const dialogTitle = () => {
    if (title) {
      return title
    }
    let titleByType = ''
    switch (type) {
      case 'alert':
        titleByType = 'Error'
        break
      case 'confirmation':
        titleByType = 'Confirmation'
        break
      default:
        titleByType = 'Warning'
    }

    return titleByType
  }

  return (
    show && (
      <>
        <DialogTitle className={classes.title}>{dialogTitle()}</DialogTitle>
        <DialogContent className={`${classes.ccPair} ${classes.white}`}>
          {msg}
        </DialogContent>
        {(() => {
          switch (type) {
            case 'alert':
              return (
                <DialogActions className={classes.actions}>
                  <Button
                    disableRipple
                    onClick={onClose}
                    className={`${classes.btn} ${classes.submit}`}
                  >
                    OK
                  </Button>
                </DialogActions>
              )
            case 'confirmation':
              return (
                <DialogActions className={classes.actions}>
                  <Button
                    onClick={onClose}
                    className={`${classes.btn} ${classes.cancel}`}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onConfirm}
                    className={`${classes.btn} ${classes.submit} ${classes.second}`}
                  >
                    Ok
                  </Button>
                </DialogActions>
              )
            default:
              return (
                <DialogActions className={classes.actions}>
                  <Button
                    autoFocus
                    onClick={onClose}
                    className={`${classes.btn} ${classes.cancel}`}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onConfirm}
                    className={`${classes.btn} ${classes.submit} ${classes.second}`}
                  >
                    Yes
                  </Button>
                </DialogActions>
              )
          }
        })()}
      </>
    )
  )
}

ConfirmContent.propTypes = {
  msg: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  type: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
}

ConfirmContent.defaultProps = {
  type: '',
  onConfirm: null,
  show: false,
  title: null,
}

export default memo(ConfirmContent)
