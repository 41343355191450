import React from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import GlobalSettingsForm from '../../../components/globalSettingsForm/GlobalSettingsForm'

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    overflow: 'hidden',
  },
})

const GlobalSettingsDialog = (props) => {
  const { onClose } = props
  const classes = useStyles()

  return (
    <Dialog
      open
      onClose={onClose}
      classes={{ paper: classes.paper }}
      disableBackdropClick
    >
      <GlobalSettingsForm onClose={onClose} />
    </Dialog>
  )
}

GlobalSettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

GlobalSettingsDialog.defaultProps = {}

export default GlobalSettingsDialog
