import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getSortedDailyAuctions } from '../../ducks/trading/selectors'
import Utils from '../../utils/Utils'

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  text: {
    '&$small': {
      fontSize: 14,
      top: 6,
      fontWeight: 'normal',
      left: '37%',
    },
    '&$orderEntry': {
      left: '87%',
      top: -15,
    },
    position: 'absolute',
    left: '47%',
    top: -3,
    padding: 0,
    margin: 0,
    transform: 'translate(-50%, -50%)',
    fontWeight: 300,
    fontSize: 26,
  },
  blink: {
    animation: '$blinker 1s linear infinite',
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0,
    },
  },
  small: {},
  orderEntry: {},
})

const Counter = (props) => {
  const blink = useRef(false)
  const { auctionId, color, parent } = props
  const classes = useStyles()
  const isOrderEntry = parent === 'orderEntry'
  const isSmallScreen = parent === 'orderDashboard' || isOrderEntry
  const dailyFixingEvents = useSelector(getSortedDailyAuctions)
  const isFixingEventStillLive = dailyFixingEvents.some(
    (item) => typeof item !== 'undefined' && item.auction.id === auctionId
  )
  let runTime = 0
  if (isFixingEventStillLive === true) {
    const { auction } = dailyFixingEvents.find(
      (item) => item.auction.id === auctionId
    )
    runTime = auction.runTime
  }

  useEffect(() => {}, [auctionId])

  const hours = Math.floor(runTime / 60 / 60)
  const hoursCalc = hours * 60 * 60
  const leftOver = runTime - hoursCalc
  const minutes = Math.floor(leftOver / 60)
  const seconds = leftOver % 60
  let result = hours === 0 ? '' : `${hours}:`
  result += minutes === 0 ? '00:' : `${Utils.addPaddingZeroToTime(minutes)}:`
  result += seconds === 0 ? '00' : Utils.addPaddingZeroToTime(seconds)
  if (blink.current !== true && runTime <= 30) {
    blink.current = true
  }
  if (blink.current === true && runTime > 30) {
    blink.current = false
  }

  return (
    <div className={classes.container}>
      <div
        className={`${classes.text} ${isSmallScreen ? classes.small : ''} ${
          blink.current ? classes.blink : ''
        } ${isOrderEntry ? classes.orderEntry : ''}`}
        style={{ color }}
      >
        {result}
      </div>
    </div>
  )
}

Counter.propTypes = {
  auctionId: PropTypes.number.isRequired,
  color: PropTypes.string,
  parent: PropTypes.string,
}

Counter.defaultProps = {
  color: 'var(--theme-widget-bg-color)',
  parent: 'fixingItems',
}

export default memo(Counter)
