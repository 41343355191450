import { takeEvery } from 'redux-saga/effects'
import authTypes from './types'
import authenticate from './saga/authenticate'
import logout from './saga/logout'
import resetPassword from './saga/resetPassword'

function* authWatcher() {
  yield takeEvery(authTypes.AUTHENTICATE, authenticate)
  yield takeEvery(authTypes.RESET_PASSWORD, resetPassword)
  yield takeEvery(authTypes.LOGOUT, logout)
}
export default authWatcher
