import ApiService from '../../api/ApiService'
import { getSelectedAccountId, getOrderFilter } from './selectors'
import {
  addOrUpdateOrder,
  fetchOrderSummaryCounts,
  getOrdersByStatus,
} from './actions'

let cachedAccountId = ''
let subscription = null

const handleSystemActivity = ({ dispatch, getState }, { accountId }) => (
  order,
  headers
) => {
  if (headers.systemAction === 'ORDER_UPDATE') {
    const orderFilter = getOrderFilter(getState())

    dispatch(addOrUpdateOrder({ order }))
    dispatch(fetchOrderSummaryCounts(accountId))
    dispatch(getOrdersByStatus(accountId, orderFilter))
  }
}

export default ({ dispatch, getState }) => (next) => (action) => {
  const accountId = getSelectedAccountId(getState())

  if (accountId && accountId !== cachedAccountId) {
    if (subscription) {
      subscription.unsubscribe()
    }

    subscription = ApiService.stompExternalSubscribe(
      `/topic/system.activity.${accountId}`,
      handleSystemActivity({ dispatch, getState }, { accountId })
    )
  }

  cachedAccountId = accountId
  return next(action)
}
