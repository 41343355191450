import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { AgGridReact } from 'ag-grid-react'
import { withStyles } from '@material-ui/core/styles'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { CircularProgress } from '@material-ui/core'

// language=SCSS
export const Container = styled('div')`
  && {
    &.hidden-ag-grid {
      visibility: hidden;
    }

    .ag-header-viewport {
      background-color: var(--theme-widget-bg-color);
    }

    .ag-row {
      background-color: var(--theme-widget-bg-color);
      border: none;

      .ag-cell-value {
        color: var(--theme-primary-text-color);
        font-weight: bold;
        font-size: 12px;

        &[col-id='baseQuantity'],
        &[col-id='executedQuantity'],
        &[col-id='amount'] {
          text-align: right;
        }
      }
    }

    .ag-header-cell.ag-header-cell-sortable {
      padding-right: 4px;
    }

    .ag-root {
      border: none;
    }

    .ag-header {
      border-bottom: 1px solid rgba(110, 110, 110, 0.5);

      .ag-header-row {
        background-color: var(--theme-widget-bg-color);
        color: var(--theme-grey-color);
        font-weight: bold;
        font-size: 12px;
      }
    }

    .ag-row {
      color: var(--theme-widget-title-text-color);
    }

    .ag-overlay-no-rows-center {
      font-weight: var(--semibold);
      color: var(--theme-widget-title-text-color);
    }

    .ag-root {
      background-color: var(--theme-widget-bg-color);
    }

    .ag-root ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    .ag-root ::-webkit-scrollbar-corner {
      display: none;
    }

    /* Track */
    .ag-root ::-webkit-scrollbar-track {
      background: none;
    }

    /* Handle */
    .ag-root ::-webkit-scrollbar-thumb {
      background: var(--theme-blue-color);
      border-radius: 0.6rem;
    }
  }
`

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    fontWeight: 800,
  },

  cssRoot: {
    color: 'white',
    borderColor: 'white',
  },
})

class AgGrid extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const fit = !(window.innerWidth > 1500)
    this.autoSizeAll(fit)
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    const { handleGridReady } = this.props

    handleGridReady(params)

    setTimeout(() => {
      this.restoreState()
      if (window.innerWidth > 1500) {
        this.autoSizeAll()
      }
    }, 500)
  }

  autoSizeAll = (fit = false) => {
    const columnsIds = this.gridColumnApi
      .getAllColumns()
      .map((column) => column.colId)
    if (fit) {
      this.gridApi.sizeColumnsToFit()
    } else {
      this.gridColumnApi.autoSizeColumns(columnsIds)
    }
  }

  restoreState = () => {
    const { colState, filterState, sortState } = this.props
    if (colState && (colState.length > 0 || Object.keys(colState).length > 0)) {
      // we check colState because otherwise the setColumnState will set the columns to be hidden as default
      this.gridColumnApi.setColumnState(colState)
      this.gridApi.setSortModel(sortState)
      this.gridApi.setFilterModel(filterState)
    }
  }

  saveSettings = (agGridEvent) => {
    const { onSave, id } = this.props
    if (onSave) {
      onSave(agGridEvent, {
        id,
        colState: this.gridColumnApi.getColumnState(),
        groupState: this.gridColumnApi.getColumnGroupState(),
        sortState: this.gridApi.getSortModel(),
        filterState: this.gridApi.getFilterModel(),
      })
    }
  }

  loadingSpinner = () => {
    return <CircularProgress className="loading-spinner" size={40} />
  }

  render() {
    const {
      columnDefs,
      defaultColDef,
      components,
      frameworkComponents,
      disableSidebar,
      rowData,
      getDataPath,
      autoGroupColumnDef,
      deltaRowDataMode,
      getRowNodeId,
      loadingSpinner,
    } = this.props
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <Container
          id="myGrid"
          className={classNames('ag-theme-balham')}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            components={components}
            frameworkComponents={frameworkComponents}
            sideBar={
              disableSidebar
                ? null
                : {
                    toolPanels: [
                      {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                      },
                    ],
                  }
            }
            rowData={rowData}
            onGridReady={this.onGridReady}
            onFilterChanged={this.saveSettings}
            onColumnMoved={this.saveSettings}
            onColumnVisible={this.saveSettings}
            onSortChanged={this.saveSettings}
            getDataPath={getDataPath}
            autoGroupColumnDef={autoGroupColumnDef}
            loadingOverlayComponentFramework={this.loadingSpinner}
            animateRows
            deltaRowDataMode={deltaRowDataMode}
            getRowNodeId={getRowNodeId}
            loadingSpinner={loadingSpinner}
          />
        </Container>
      </div>
    )
  }
}

export default withStyles(styles)(AgGrid)

AgGrid.propTypes = {
  colState: PropTypes.shape([]),
  columnDefs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  components: PropTypes.shape({}),
  defaultColDef: PropTypes.shape({}).isRequired,
  filterState: PropTypes.shape({}),
  handleGridReady: PropTypes.func,
  id: PropTypes.number,
  onSave: PropTypes.func,
  rowData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  sortState: PropTypes.shape([]),
  disableSidebar: PropTypes.bool,
  deltaRowDataMode: PropTypes.bool,
  autoGroupColumnDef: PropTypes.shape({}),
  getDataPath: PropTypes.func,
  loadingSpinner: PropTypes.bool,
  getRowNodeId: PropTypes.func,
  frameworkComponents: PropTypes.shape({}),
}

AgGrid.defaultProps = {
  colState: null,
  columnDefs: [],
  components: {},
  frameworkComponents: {},
  filterState: null,
  rowData: [],
  sortState: null,
  disableSidebar: false,
  deltaRowDataMode: false,
  autoGroupColumnDef: null,
  getDataPath: null,
  loadingSpinner: false,
  handleGridReady: null,
  getRowNodeId: null,
  id: 0,
  onSave: () => {},
}
