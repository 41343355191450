import moment from 'moment'

const numberFormatter = (params) => {
  return params.value.toLocaleString()
}

const dateFormatter = (params) => {
  const { createDate } = params.data
  const utcTime = moment.utc(createDate)
  utcTime.local()
  return utcTime.format('DD MMM YYYY HH:mm:ss')
}

const counterPartyFormatter = (params) => {
  const { rate } = params.data
  const { postTradeCode } = params.data
  return rate > 0 ? postTradeCode : ''
}

const columns = [
  {
    field: 'externalId',
    width: 80,
    sortable: true,
    headerName: 'Deal ID',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'externalId',
    headerTooltip: 'Deal ID',
  },
  {
    field: 'tradeId',
    width: 80,
    sortable: true,
    headerName: 'Trade ID',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'tradeId',
    headerTooltip: 'Trade ID',
  },
  {
    field: 'createDate',
    width: 145,
    sortable: true,
    headerName: 'Trade Date',
    menuTabs: ['filterMenuTab'],
    valueFormatter: dateFormatter,
    tooltipField: 'createDate',
    headerTooltip: 'Trade Date',
  },
  {
    field: 'orderId',
    width: 112,
    sortable: true,
    headerName: 'Order Id',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'orderId',
    headerTooltip: 'Order Id',
  },
  {
    field: 'postTradeCode',
    width: 112,
    sortable: true,
    headerName: 'Counterparty',
    menuTabs: ['filterMenuTab'],
    valueFormatter: counterPartyFormatter,
    tooltipField: 'counterParty',
    headerTooltip: 'Counterparty',
  },
  {
    field: 'direction',
    width: 64,
    sortable: true,
    cellRenderer: 'directionRenderer',
    headerName: 'Direction',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'direction',
    headerTooltip: 'Direction',
  },
  {
    field: 'currencyPair',
    width: 94,
    sortable: true,
    headerName: 'CCY Pair',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'currencyPair',
    headerTooltip: 'CCY Pair',
  },
  {
    field: 'amount',
    width: 94,
    sortable: true,
    headerName: 'Amount',
    menuTabs: ['filterMenuTab'],
    valueFormatter: numberFormatter,
    tooltipField: 'amount',
    headerTooltip: 'Amount',
  },
  {
    field: 'benchmark',
    width: 100,
    sortable: true,
    headerName: 'Benchmark',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'benchmark',
    headerTooltip: 'Benchmark',
  },
  {
    field: 'maturityDate',
    width: 90,
    sortable: true,
    headerName: 'Maturity Date',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'maturityDate',
    headerTooltip: 'Maturity Date',
  },
  {
    field: 'account',
    width: 94,
    sortable: true,
    headerName: 'Account',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'account',
    headerTooltip: 'Account',
  },
  {
    field: 'user',
    width: 90,
    sortable: true,
    headerName: 'Trader',
    menuTabs: ['filterMenuTab'],
    tooltipField: 'user',
    headerTooltip: 'Trader',
  },
]

export default columns
