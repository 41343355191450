import blotterTypes from './types'

export const setFilterType = (payload) => ({
  type: blotterTypes.SET_FILTER_TYPE,
  payload,
})

export const setFilterAccount = (payload) => ({
  type: blotterTypes.SET_FILTER_ACCOUNT,
  payload,
})

export const setFilterDate = (dateType, date) => ({
  type: blotterTypes.SET_FILTER_DATE,
  payload: { dateType, date },
})

export const downloadCSVReport = (
  selectedType,
  chosenAccountId,
  selectedDates,
  dataType,
  afterEOD
) => ({
  type: blotterTypes.DOWNLOAD_CSV_REPORT,
  payload: { selectedType, chosenAccountId, selectedDates, dataType, afterEOD },
})
