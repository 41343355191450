export const getBlotterState = (state) => state.blotterReducer

export const getSelectedType = (state) => {
  const { selectedType } = getBlotterState(state)
  return selectedType
}

export const getSelectedAccount = (state) => {
  const { selectedAccount } = getBlotterState(state)
  return selectedAccount
}

export const getSelectedDates = (state) => {
  const { selectedDates } = getBlotterState(state)
  return selectedDates
}
