import { useEffect, useState } from 'react'

const useResizeScreen = () => {
  const [height, setHeight] = useState(window.innerHeight)
  useEffect(() => {
    const updateDimensions = () => {
      setHeight(window.innerHeight)
    }
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  return height
}

export default useResizeScreen
