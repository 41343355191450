import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import uuid from 'react-uuid'
import {
  getMySortedFilteredOrders,
  getMySortedOrders,
  getSelectedAccountId,
} from '../../ducks/trading/selectors'
import OrderListItem from './OrderListItem'
import { fetchBalance } from '../../ducks/outstandingBalance/actions'

const useStyles = makeStyles({
  container: {
    display: 'block',
    fontSize: 10,
  },
  row: {
    '& th, td': {
      backgroundColor: 'var(--theme-widget-bg-color)',
      height: 12,
      padding: 0,
      color: '#fff',
    },
    '& th': {
      fontSize: 10,
      color: 'var(--theme-grey-color)',
      lineHeight: '18px',
      borderBottom: '1px solid var(--theme-grey-color)',
      '&$alignContRight': {
        paddingRight: '30px',
        textAlign: 'right',
      },
    },
  },
  alignContRight: {},
})

const OrdersList = () => {
  const dispatch = useDispatch()
  const accountId = useSelector(getSelectedAccountId)
  const myOrders = useSelector(getMySortedOrders)
  const myFilteredOrders = useSelector(getMySortedFilteredOrders)
  const classes = useStyles()

  useEffect(() => {
    if (accountId !== '' && myOrders.length > 0) {
      dispatch(fetchBalance(accountId))
    }
  }, [accountId, dispatch, myOrders])

  return (
    <TableContainer
      id="table-container"
      style={{
        height: '100%',
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell style={{ width: 160 }} />
            <TableCell>Currency</TableCell>
            <TableCell>Benchmark</TableCell>
            <TableCell>Buy/Sell</TableCell>
            <TableCell className={classes.alignContRight}>Order</TableCell>
            <TableCell className={classes.alignContRight}>Traded</TableCell>
            <TableCell className={classes.alignContRight}>Balance</TableCell>
            <TableCell style={{ width: 60 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {myFilteredOrders.map((order) => {
            return <OrderListItem key={uuid()} order={order} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default memo(OrdersList)
