import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, DialogTitle, makeStyles } from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import TaButton from '../../common-components/taButton/TaButton'
import TaSwitch from '../../common-components/taSwitch/TaSwitch'
import { getConfirmBalanceAmend } from '../../ducks/globalSettings/selectors'
import { getUserId } from '../../ducks/authentication/selectors'
import { updateGlobalSettings } from '../../ducks/globalSettings/actions'

const Consts = {
  CONFIRM_AMEND: 'CONFIRM_AMEND',
}

const useStyles = makeStyles({
  container: {
    width: 600,
    border: '2px solid #0180a0',
    backgroundColor: 'var(--theme-widget-bg-color)',
    textAlign: 'center',
  },
  content: {
    minHeight: 340,
    '& $settingsList': {
      fontSize: 13,
      padding: '30px 10px 0 15px',
      color: 'var(--theme-white-color)',
      textAlign: 'left',
      borderRight: '1px solid var(--theme-black-color)',
      '& $settingsListItem': {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
      },
    },
    '& $settingsListActions': {
      fontSize: 13,
      padding: '30px 15px 0 10px',
      textAlign: 'right',
      '& $settingsListItem': {
        height: 30,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginBottom: 5,
      },
    },
  },
  title: {
    '& h2': {
      color: '#000',
      fontFamily: 'var(--theme-font-family)',
      fontSize: 13,
      fontWeight: 600,
      textAlign: 'left',
      textIndent: 5,
      letterSpacing: 0,
      height: 20,
    },
    backgroundColor: 'var(--theme-blue-color)',
    width: '100%',
    padding: 0,
    borderRadius: '2px 2px 0 0',
  },
  footer: {
    justifyContent: 'end',
    padding: '12px 10px 10px',
    borderTop: '1px solid var(--theme-black-color)',
  },
  btn: {
    minHeight: 30,
    lineHeight: 1,
    color: 'var(--theme-white-color)',
    backgroundColor: 'var(--theme-blue-color)',
    '&:hover': {
      backgroundColor: 'var(--theme-blue-hover-color)',
    },
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    top: 5,
    height: '0.6em',
    fontWeight: 'bold',
  },
  orderEntry: {
    fontSize: 14,
    border: 'none',
    margin: 0,
  },
  buy: {},
  disabled: {},
  sell: {},
  settingsList: {},
  settingsListItem: {},
  settingsListActions: {},
  settingsListActionsItem: {},
})

const GlobalSettingsForm = (props) => {
  const { onClose } = props
  const confirmBalanceAmend = useSelector(getConfirmBalanceAmend)
  const userId = useSelector(getUserId)
  const [switchConfirmBalanceAmend, setSwitchConfirmBalanceAmend] = useState(
    confirmBalanceAmend
  )
  const dispatch = useDispatch()
  const classes = useStyles()

  const toggleConfirmBalanceAmend = (checked) => {
    setSwitchConfirmBalanceAmend(checked)
  }

  const updateUserSettings = () => {
    const data = {
      userId,
      globalSettingType: Consts.CONFIRM_AMEND,
      settings: {
        confirmAmend: switchConfirmBalanceAmend,
      },
    }
    dispatch(updateGlobalSettings(data))
    onClose()
  }

  return (
    <Grid container className={classes.container}>
      <DialogTitle className={classes.title}>
        Global Settings
        <CloseOutlinedIcon className={classes.closeBtn} onClick={onClose} />
      </DialogTitle>
      <Grid container className={classes.content}>
        <Grid item md={4} className={classes.settingsList}>
          <div className={classes.settingsListItem}>
            <span>Confirm Balance Amend</span>
          </div>
        </Grid>
        <Grid item md={8} className={classes.settingsListActions}>
          <div className={classes.settingsListActionsItem}>
            <TaSwitch
              isChecked={switchConfirmBalanceAmend}
              change={(e) => {
                toggleConfirmBalanceAmend(e)
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.footer}>
        <TaButton
          className={classes.btn}
          onClick={() => {
            updateUserSettings()
          }}
        >
          Ok
        </TaButton>
      </Grid>
    </Grid>
  )
}

GlobalSettingsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
}

GlobalSettingsForm.defaultProps = {}

export default GlobalSettingsForm
