/* eslint-disable no-param-reassign */
import produce from 'immer'
import moment from 'moment'
import types from './types'
import initialState from './initial'

export default function orderBlotterReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_ORDERS_DATA: {
      const nextState = produce(state, (draftState) => {
        const payloadLength = payload.orders.rowCount
          ? payload.orders.rowCount
          : payload.orders.length
        if (payloadLength > 0) {
          const newOrderState = payload.orders.dataItems
            ? payload.orders.dataItems
            : payload.orders
          newOrderState.forEach((order) => {
            if (Array.isArray(order.createDate)) {
              order.createDate.pop() // remove the milliseconds from the array
              order.createDate[1] -= 1 // decrement the month since java doesn't count months like programmers (starts at 1)
              order.createDate = moment(order.createDate).format(
                'DD MMM YYYY HH:mm:ss'
              )
            }
          })
          draftState.ordersData = payload.orders.dataItems
            ? payload.orders.dataItems
            : payload.orders
        } else {
          draftState.ordersData = []
        }
        return draftState
      })
      return nextState
    }
    case types.UPDATE_ORDER: {
      const nextState = produce(state, (draftState) => {
        const existingOrderIndex = draftState.ordersData.findIndex(
          (order) => payload.order.orderId === order.orderId
        )
        if (existingOrderIndex > -1) {
          draftState.ordersData[existingOrderIndex] = payload.order
        } else {
          draftState.ordersData.unshift(payload.order)
        }
        return draftState
      })
      return nextState
    }
    case types.CLEAR_ALL_ORDERS: {
      const nextState = produce(state, (draftState) => {
        draftState.ordersData = []
        return draftState
      })
      return nextState
    }
    default:
      return state
  }
}
