const NAMESPACE = 'OUTSTANDING_BALANCE'

const outstandingBalanceTypes = {
  FETCH_OUTSTANDING_CCPAIRS: `${NAMESPACE}/FETCH_OUTSTANDING_CCPAIRS`,
  SET_OUTSTANDING_CCPAIRS: `${NAMESPACE}/SET_OUTSTANDING_CCPAIRS`,
  CANCEL_ALL_ORDERS: `${NAMESPACE}/CANCEL_ALL_ORDERS`,
  CANCEL_CCPAIR_ORDERS: `${NAMESPACE}/CANCEL_CCPAIR_ORDERS`,
  RESET_CCPAIR_ORDERS: `${NAMESPACE}/RESET_CCPAIR_ORDERS`,
  CLEAR_OUTSTANDING_BALANCE: `${NAMESPACE}/CLEAR_OUTSTANDING_BALANCE`,
  RESET_CCPAIR_BALANCE: `${NAMESPACE}/RESET_CCPAIR_BALANCE`,
  TOGGLE_EDIT_MODE_BALANCE: `${NAMESPACE}/TOGGLE_EDIT_MODE_BALANCE`,
  SET_EDIT_MODE_ID: `${NAMESPACE}/SET_EDIT_MODE_ID`,
  SET_AMENDED_BALANCE: `${NAMESPACE}/SET_AMENDED_BALANCE`,
  SET_ERR_MSG: `${NAMESPACE}/SET_ERR_MSG`,
  SET_ORDER_PARENT_ID: `${NAMESPACE}/SET_ORDER_PARENT_ID`,
}

export default outstandingBalanceTypes
