import { combineReducers } from 'redux'
import authenticationReducer from './authentication/reducer'
import loadersReducer from './loaders/reducer'
import paramHolderReducer from './paramHolder/reducer'
import tradingReducer from './trading/reducer'
import orderBlotterReducer from './orderBlotter/reducer'
import tradeBlotterReducer from './tradeBlotter/reducer'
import outstandingBalanceReducer from './outstandingBalance/reducer'
import blotterReducer from './blotter/reducer'
import globalSettingsReducer from './globalSettings/reducer'

export default combineReducers({
  authentication: authenticationReducer,
  loaders: loadersReducer,
  paramHolder: paramHolderReducer,
  trading: tradingReducer,
  orderBlotterReducer,
  tradeBlotterReducer,
  outstandingBalanceReducer,
  blotterReducer,
  globalSettingsReducer,
})
