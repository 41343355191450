import { put, call } from 'redux-saga/effects'
import { stopLoading, startLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'
import ApiService from '../../../api/ApiService'
import { setErrorMsg } from '../actions'

export default function* addAuctionOrder({
  payload: { direction, baseQuantity, userId, accountId, parentOrderId },
}) {
  yield put(startLoading({ loaderType: LoaderTypes.ADD_AUCTION_ORDER }))
  try {
    yield call(
      ApiService.addAuctionOrder,
      direction,
      baseQuantity,
      userId,
      accountId,
      parentOrderId
    )
    yield put(
      stopLoading({ loaderType: LoaderTypes.ADD_AUCTION_ORDER, error: null })
    )
  } catch (error) {
    yield put(setErrorMsg(error))
    yield put(stopLoading({ loaderType: LoaderTypes.ADD_AUCTION_ORDER, error }))
  }
}
