/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect'
import { AuctionStatus, OrderStatus } from '../../consts/TaConsts'
import { getCcPairMap, getCcPairs } from '../paramHolder/selectors'

export const getTradingState = (state) => state.trading

export const getDailyAuctionsState = (state) =>
  getTradingState(state).dailyAuctions

export const getSelectedAuctionId = (state) =>
  getTradingState(state).selectedAuctionId
export const getMyOrders = (state) => getTradingState(state).myOrders

export const getMyFilteredOrders = (state) =>
  getTradingState(state).filteredOrders

export const getMySortedFilteredOrders = createSelector(
  getMyFilteredOrders,
  (filteredOrders) => {
    return filteredOrders
      .slice()
      .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
  }
)

export const getMySortedOrders = createSelector(getMyOrders, (myOrders) => {
  return myOrders
    .slice()
    .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
})

export const getErrMsg = (state) => getTradingState(state).errorMsg

export const getSelectedAccountId = (state) =>
  getTradingState(state).selectedAccountId

export const getOrderSummaryCounts = createSelector(
  getTradingState,
  (tradingState) => tradingState.orderSummaryCounts
)

export const getSortedDailyAuctions = createSelector(
  getDailyAuctionsState,
  (dailyAuctions) => {
    let waiting = dailyAuctions.filter(
      (item) => item.auction.status === AuctionStatus.WAITING
    )
    let live = dailyAuctions.filter(
      (item) => item.auction.status === AuctionStatus.LIVE
    )
    let done = dailyAuctions.filter(
      (item) => item.auction.status === AuctionStatus.DONE
    )

    const sortFunc = (a, b) => {
      const aDate = new Date()
      const bDate = new Date()
      const [hourA, minA] = a.auction.dailyAuctionTime.split(':')
      const [hourB, minB] = b.auction.dailyAuctionTime.split(':')
      aDate.setHours(hourA, minA, 0)
      bDate.setHours(hourB, minB, 0)
      return aDate - bDate
    }

    waiting = waiting.sort(sortFunc)
    live = live.sort(sortFunc)
    done = done.sort(sortFunc)

    return [...live, ...waiting, ...done]
  }
)

export const getBenchmarksCurrentStatus = createSelector(
  getTradingState,
  (tradingState) => {
    const currentBenchmarkStatuses = {}
    const benchmarkIds = Object.keys(tradingState.benchMarksCurrentStatus)

    // eslint-disable-next-line no-restricted-syntax
    for (const benchmarkId of benchmarkIds) {
      const currentBenchmark = tradingState.benchMarksCurrentStatus[benchmarkId]
      const areAllFinished = currentBenchmark.every(
        (fixingEvent) => fixingEvent.status === AuctionStatus.DONE
      )
      if (areAllFinished === true) {
        // return status "DONE" only when all fixing events finish
        const [first] = currentBenchmark
        currentBenchmarkStatuses[benchmarkId] = first
      } else {
        /* 1. Find the nearest ending LIVE fixing event
       2. Otherwise any LIVE fixing event 
       3. Finally a WAITING event if none else
     */
        const [firstElem] = currentBenchmark
        let currentRelevant = firstElem
        currentBenchmark.forEach((fixingEvent) => {
          if (fixingEvent.status === AuctionStatus.LIVE) {
            if (
              (currentRelevant.status === AuctionStatus.LIVE &&
                currentRelevant.runTime > fixingEvent.runTime) ||
              currentRelevant.status !== AuctionStatus.LIVE
            ) {
              currentRelevant = fixingEvent
            }
          } else if (
            currentRelevant.status === AuctionStatus.DONE &&
            fixingEvent.status === AuctionStatus.WAITING
          ) {
            currentRelevant = fixingEvent
          }
        })
        currentBenchmarkStatuses[benchmarkId] = currentRelevant
      }
    }

    return currentBenchmarkStatuses
  }
)

export const getOrderFilter = (state) => getTradingState(state).orderFilter

export const getLiveOrdersMap = createSelector(
  [getMyOrders, getCcPairMap],
  (myOrders, ccPairsMap) => {
    const liveOrdersMap = new Map()
    ccPairsMap.forEach((val, key) => {
      const isLiveOrder = myOrders.some(
        (order) =>
          order.ccPairId === key &&
          (order.orderStatus === OrderStatus.LIVE ||
            order.orderStatus === OrderStatus.PARTIALLY_FILLED)
      )
      liveOrdersMap.set(key, isLiveOrder)
    })
    return liveOrdersMap
  }
)

export const getLiveFixingEventsMap = createSelector(
  [getDailyAuctionsState, getCcPairMap],
  (dailyFixingEvents, ccPairsMap) => {
    const liveFixingEventsMap = new Map()
    ccPairsMap.forEach((val, key) => {
      const isLiveFixingEvent = dailyFixingEvents.some(
        (fixingEvent) =>
          fixingEvent.auction.ccPairId === key &&
          fixingEvent.auction.status === AuctionStatus.LIVE
      )
      liveFixingEventsMap.set(key, isLiveFixingEvent)
    })
    return liveFixingEventsMap
  }
)

export const getAvailableCurrencies = (benchmarkId) =>
  createSelector([getTradingState, getCcPairs], (tradingState, ccPairs) => {
    const currentBenchmark = tradingState.benchMarksCurrentStatus[benchmarkId]
    return ccPairs.filter((ccPair) =>
      currentBenchmark.some(
        (fixingEvent) =>
          fixingEvent.ccPairId === ccPair.id &&
          fixingEvent.status === AuctionStatus.LIVE
      )
    )
  })

export const getSelectedAuction = createSelector(
  [getDailyAuctionsState, getSelectedAuctionId],
  (dailyAuctions, selectedAuctionId) => {
    const result = dailyAuctions.filter(
      (item) => item.auction.id === selectedAuctionId
    )
    return result.length === 0 ? null : result[0]
  }
)

export const getAllDailyAuctionMap = createSelector(
  getDailyAuctionsState,
  (dailyAuctions) => {
    const resultMap = new Map()
    dailyAuctions.forEach((item) => {
      resultMap.set(item.auction.id, item)
    })
    return resultMap
  }
)

export const getIsMatchOccur = (state) => getTradingState(state).isMatchOccur
export const getMatchesArr = (state) => getTradingState(state).tradesMatches
