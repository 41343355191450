import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { clearAuthData } from './ducks/authentication/actions'
import { clearErrMsg } from './ducks/trading/actions'
import { getSessionId, getUserId } from './ducks/authentication/selectors'
import { getSelectedAccountId, getErrMsg } from './ducks/trading/selectors'
import { LoaderTypes } from './ducks/loaders/loaderTypesEnum'
import { getIsLoadingByType } from './ducks/loaders/selectors'
import { getParametersByUserId } from './ducks/paramHolder/actions'
import Header from './components/header/Header'
import BlankLoadingScreen from './common-components/blankLoadingScreen/BlankLoadingScreen'
import AuctionOrderListSection from './containers/auctionOrderListSection/AuctionOrderListSection'
import ApiService from './api/ApiService'
import Blotter from './containers/blotter/Blotter'
import ConfirmationDialog from './common-components/dialog/confirmationDialog/ConfirmationDialog'

const Consts = {
  TRADE_ALERT: 'TRADE_ALERT',
  SERVER_ALERT: 'SERVER_ALERT',
  ORDER_UPDATE: 'ORDER_UPDATE',
  KILL_USER: 'KILL_USER',
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    color: '#fff',
  },
  mainContent: {
    border: '1px solid rgba(110, 110, 110, 0.5)',
    bottom: 8,
    margin: 4,
  },
})

const Trading = () => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const dispatch = useDispatch()
  const userId = useSelector(getUserId)
  const globalErrMsg = useSelector(getErrMsg)
  const selectedAccountId = useSelector(getSelectedAccountId)
  const isParamHolderLoading = useSelector((state) =>
    getIsLoadingByType(state, LoaderTypes.GET_PARAMETERS_BY_USER_ID)
  )
  const sessionId = useSelector(getSessionId)
  const classes = useStyles()

  useEffect(() => {
    const handleUserSystemNotification = (data, headers) => {
      switch (headers.systemAction) {
        case Consts.KILL_USER:
          dispatch(clearAuthData())
          ApiService.disconnectSocket()
          ApiService.logout()
          break
        case Consts.SERVER_ALERT:
          setErrMsg(data.msg)
          setShowConfirm(true)
          break
        default:
      }
    }

    if (userId) {
      dispatch(getParametersByUserId(userId))
      ApiService.stompExternalSubscribe(
        '/topic/user.system',
        handleUserSystemNotification
      )
    }
  }, [dispatch, selectedAccountId, userId])

  if (!sessionId) {
    return <Redirect to="/" />
  }
  if (isParamHolderLoading) {
    return <BlankLoadingScreen />
  }

  const handleClose = () => {
    dispatch(clearErrMsg())
    setShowConfirm(false)
  }

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.mainContent}>
        <AuctionOrderListSection />
        <Blotter />
        {(showConfirm || globalErrMsg.length > 0) && (
          <ConfirmationDialog
            onClose={handleClose}
            msg={errMsg || globalErrMsg}
            type="alert"
          />
        )}
      </div>
    </div>
  )
}

export default memo(Trading)
