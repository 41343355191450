export const AuctionStatus = {
  WAITING: 'WAITING',
  DONE: 'DONE',
  LIVE: 'LIVE',
}

export const OrderStatus = {
  LIVE: 'LIVE',
  DONE: 'DONE',
  CANCELED: 'CANCELLED',
  TERMINATED: 'TERMINATED',
  PARTAILLY_CANCELED: 'PARTAILLY_CANCELED',
  PARTIALLY_REJECTED: 'PARTIALLY_REJECTED',
  PARTIALLY_FILLED: 'PARTIALLY_FILLED',
  REJECTED: 'REJECTED',
}

export const FilterStates = {
  LIVE: OrderStatus.LIVE,
  CANCELLED: OrderStatus.CANCELED,
  DONE: OrderStatus.DONE,
  ALL: 'ALL',
}

export const TaConsts = {
  DECIMAL_REGEX: new RegExp(/^$|^[0-9\b]+$/), // allow backspace and empty
}

export const ErrStrings = {
  'wrong password':
    'The user name or password that you have entered is incorrect',
  'user is locked': 'User account is locked, please contact support',
  'user is disabled': 'User account is disabled, please contact support',
  'already login': 'You are already logged in on a different computer.',
  'Password has expired':
    'Your password has expired. Please submit a new password.',
  'First login': 'First login. Please enter your password.',
}
