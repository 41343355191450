import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import DatePicker from 'react-datepicker'
import {
  getSelectedAccount,
  getSelectedDates,
  getSelectedType,
} from '../../ducks/blotter/selectors'
import {
  setFilterType,
  setFilterDate,
  downloadCSVReport,
} from '../../ducks/blotter/actions'
import { getEndOfDay } from '../../ducks/paramHolder/selectors'
import 'react-datepicker/dist/react-datepicker.css'
import excelIcon from '../../images/excel.gif'
import Utils from '../../utils/Utils'

const useStyles = makeStyles({
  dropDown: {
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    },
    color: 'var(--theme-primary-text-color)',
    fontWeight: 'bold',
    fontSize: 12,
    fontFamily: 'Open Sans',
    margin: '-1px 0 0 20px',
  },
  icon: {
    fill: '#fff',
  },
  user: {
    fontSize: 12,
    fontWeight: 'normal',
    textAlign: 'right',
    marginLeft: 4,
  },
  menu: {
    fontFamily: 'var(--theme-font-family)',
    fontWeight: 'bold',
    fontSize: 11,
    textAlign: 'center',
    lineHeight: '11px',
  },
  datePicker: {
    '& input': {
      width: 100,
      background: 'transparent',
      outline: 0,
      textAlign: 'center',
      color: 'var(--theme-primary-text-color)',
      border: '1px solid #fff',
      fontSize: 12,
      fontWeight: 'normal',
    },
    marginTop: -5,
  },
  lineHeight: {
    lineHeight: '27px',
    height: 20,
    fontSize: 12,
  },
  indent: {
    textIndent: 10,
  },
  datesContainer: {
    display: 'inline-block',
    width: 160,
  },
  csvDownload: {
    '& img': {
      height: 25,
    },
    verticalAlign: 'unset',
    padding: 0,
  },
})

const FilterPanel = (props) => {
  const { type } = props
  const dispatch = useDispatch()
  const selectedType = useSelector(getSelectedType)
  const chosenAccountId = useSelector(getSelectedAccount)
  const selectedDates = useSelector(getSelectedDates)
  const endOfDay = useSelector(getEndOfDay)
  const { dateFrom, dateTo } = selectedDates
  const classes = useStyles()

  const handleTypeChange = (e) => {
    dispatch(setFilterType(e.target.value))
  }

  const handleDateChange = (dateType, date) => {
    dispatch(setFilterDate(dateType, date))
  }

  const handleCSVClick = () => {
    const afterEOD = Utils.checkIfAfterEOD(endOfDay)
    dispatch(
      downloadCSVReport(
        selectedType,
        chosenAccountId,
        selectedDates,
        type,
        afterEOD
      )
    )
  }

  return (
    <Grid container className={classes.lineHeight}>
      <Grid item md={1} className={classes.indent}>
        Filter:
      </Grid>
      <Grid item md={1}>
        <FormControl>
          <Select
            className={classes.dropDown}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            disableUnderline
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            defaultValue="live"
            onChange={handleTypeChange}
            value={selectedType}
          >
            <MenuItem key={0} value="live" className={classes.menu}>
              Live
            </MenuItem>
            <MenuItem key={1} value="custom" className={classes.menu}>
              Custom
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={1}>
        <Button className={classes.csvDownload} onClick={handleCSVClick}>
          <img src={excelIcon} alt="Create CSV report" />
        </Button>
      </Grid>
      {selectedType === 'custom' && (
        <Grid item md={6} className={classes.datePicker}>
          <span className={classes.datesContainer}>
            From :{' '}
            <DatePicker
              selected={dateFrom}
              onChange={(date) => handleDateChange('dateFrom', date)}
              dateFormat="dd/MMM/yyyy"
            />
          </span>
          <span className={classes.datesContainer}>
            To :{' '}
            <DatePicker
              selected={dateTo}
              onChange={(date) => handleDateChange('dateTo', date)}
              dateFormat="dd/MMM/yyyy"
            />
          </span>
        </Grid>
      )}
    </Grid>
  )
}

FilterPanel.propTypes = {
  type: PropTypes.string.isRequired,
}

export default FilterPanel
