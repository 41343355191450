import React, { memo, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import uuid from 'react-uuid'
import BenchmarkList from '../../components/auctions/BenchmarkList'
import SummaryPane from '../../components/summaryPane/SummaryPane'
import {
  fetchOrderSummaryCounts,
  clearAllData,
  setDailyAuction,
} from '../../ducks/trading/actions'
import {
  clearOutstandingBalance,
  resetCCpairBalance,
} from '../../ducks/outstandingBalance/actions'
import {
  getBenchmarksMap,
  getCcPairMap,
} from '../../ducks/paramHolder/selectors'
import { getSelectedAccountId } from '../../ducks/trading/selectors'
import { clearAllTrades } from '../../ducks/tradeBlotter/actions'
import { setFilterType } from '../../ducks/blotter/actions'
import { addAccount, removeAccount } from '../../ducks/paramHolder/actions'
import ApiService from '../../api/ApiService'
import OutstandingBalance from '../../components/outstandingBalance/OutstandingBalance'
import OrdersList from '../../components/ordersList/OrdersList'
import { clearAllOrders } from '../../ducks/orderBlotter/actions'
import useTradeAlertSnackbars from '../../hooks/useTradeAlertSnackbars'

const useStyles = makeStyles({
  parent: {
    '&$tall': {
      height: '58vh',
    },
    backgroundColor: 'var(--theme-widget-bg-color)',
  },
  container: {
    width: '100%',
    minHeight: 300,
  },
  left: {
    borderRight: '1px solid rgba(110, 110, 110, 0.5)',
    height: '100%',
  },
  topRight: {
    '&$tall': {
      height: '58vh',
    },
    height: '57vh',
  },
  benchmarkHeader: {
    textAlign: 'center',
    margin: '5px 0',
  },
  tall: {},
  fullHeight: {
    height: '100%',
  },
  flexVerticalParent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})

const AuctionOrderListSection = () => {
  const tradeId = useRef(null)
  const dispatch = useDispatch()
  const benchmarkMap = useSelector(getBenchmarksMap)
  const ccPairsMap = useSelector(getCcPairMap)
  const accountId = useSelector(getSelectedAccountId)
  const classes = useStyles()
  const isWideScreen = useMediaQuery('(min-width:1281px)')
  const { fireSnackbars } = useTradeAlertSnackbars()

  useEffect(() => {
    const handleUserSystemNotification = (data, headers) => {
      const ccPair = ccPairsMap.get(data.ccPairId)
      const benchmark = benchmarkMap.get(data.benchmark)
      if (ccPair != null && typeof benchmark !== 'undefined') {
        const quantity = parseInt(data.quantity, 10).toLocaleString('en-US', {
          style: 'currency',
          currency: ccPair.instrument1.name,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        const alertMessage = `${data.direction} order for ${ccPair.instrument1.name} / ${ccPair.instrument2.name} - ${benchmark.name} has been matched for ${quantity}`
        switch (headers.systemAction) {
          case 'TRADE_ALERT':
            if (!tradeId.current || tradeId.current !== data.tradeId) {
              fireSnackbars(
                alertMessage,
                { variant: 'success', persist: 'true' },
                { msg: alertMessage, id: uuid() }
              )
            }
            tradeId.current = data.tradeId

            break
          default:
        }

        if (accountId !== '') {
          dispatch(fetchOrderSummaryCounts(accountId))
        }
      }
    }
    ApiService.stompExternalSubscribe(
      '/topic/user.system',
      handleUserSystemNotification
    )
  }, [accountId, benchmarkMap, ccPairsMap, fireSnackbars, dispatch])

  useEffect(() => {
    const handleSystemNotification = (data, headers) => {
      switch (headers.systemAction) {
        case 'ACCOUNT_ADD':
          dispatch(addAccount(data))
          break
        case 'ACCOUNT_REMOVE':
          dispatch(removeAccount(data))
          break
        case 'RESET_BALANCE_OUTSTANDING':
          dispatch(resetCCpairBalance(data.ccPairId))
          break
        default:
      }
    }

    ApiService.stompExternalSubscribe(
      '/topic/user.system',
      handleSystemNotification
    )
  }, [dispatch])

  useEffect(() => {
    const handleGeneralSystemNotification = (data, headers) => {
      if (accountId !== '') {
        switch (headers.systemAction) {
          case 'END_OF_DAY':
            dispatch(setFilterType('live'))
            dispatch(clearOutstandingBalance())
            dispatch(clearAllData())
            dispatch(clearAllTrades())
            dispatch(clearAllOrders())
            break
          case 'START_OF_DAY':
            dispatch(setDailyAuction(data))
            break
          default:
        }
      }
    }
    ApiService.stompExternalSubscribe(
      '/topic/system.system',
      handleGeneralSystemNotification
    )
  }, [accountId, dispatch])

  return (
    <div className={`${classes.parent} ${isWideScreen ? classes.tall : ''}`}>
      <Grid container className={classes.container}>
        <Grid item md={5} className={classes.left}>
          <h4 className={classes.benchmarkHeader}>Benchmarks</h4>
          <BenchmarkList />
        </Grid>
        <Grid
          item
          md={7}
          className={`${classes.topRight} ${isWideScreen ? classes.tall : ''}`}
          id="top-right"
        >
          <Grid container className={`${classes.fullHeight}`}>
            <Grid item md={12} className={`${classes.flexVerticalParent}`}>
              <OutstandingBalance />
              <SummaryPane />
              <OrdersList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default memo(AuctionOrderListSection)
