import { all, call } from 'redux-saga/effects'
import authWatcher from './authentication/watchers'
import paramHolderWatcher from './paramHolder/watcher'
import tradingWatcher from './trading/watcher'
import ordersWatcher from './orderBlotter/watcher'
import tradesWatcher from './tradeBlotter/watcher'
import outstandingBalanceWatcher from './outstandingBalance/watcher'
import blotterWatcher from './blotter/watcher'
import globalSettingsWatcher from './globalSettings/watcher'

function* rootSaga() {
  yield all([
    call(authWatcher),
    call(paramHolderWatcher),
    call(tradingWatcher),
    call(ordersWatcher),
    call(tradesWatcher),
    call(outstandingBalanceWatcher),
    call(blotterWatcher),
    call(globalSettingsWatcher),
  ])
}

export default rootSaga
