import React, { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import {
  fetchBalance,
  cancelAllLiveOrders,
  cancelCCPairLiveOrders,
  resetCCPairLiveOrders,
  toggleEditModeLiveOrder,
  setEditModeLiveOrderId,
  setAmendedBalance,
  setErrMsg,
  setOrderParentId,
} from '../../ducks/outstandingBalance/actions'
import ApiService from '../../api/ApiService'
import { getLoggedinUser } from '../../ducks/authentication/selectors'
import {
  getLiveOrdersMap,
  getLiveFixingEventsMap,
  getOrderSummaryCounts,
  getSelectedAccountId,
  getIsMatchOccur,
  getMatchesArr,
} from '../../ducks/trading/selectors'
import {
  getOutstandingBalanceMap,
  getGlobalErrMsg,
  getInEditModeOrderParentId,
} from '../../ducks/outstandingBalance/selectors'
import { getConfirmBalanceAmend } from '../../ducks/globalSettings/selectors'
import { getBenchmarks } from '../../ducks/paramHolder/selectors'
import ConfirmationDialog from '../../common-components/dialog/confirmationDialog/ConfirmationDialog'
import WindowPortal from '../../common-components/windowPortal/WindowPortal'
import ConfirmContent from '../../common-components/confirmContent/ConfirmContent'
import TaSnackbar from '../../common-components/taSnackbar/TaSnackbar'

const useStyles = makeStyles({
  container: {
    '&$borderBlue': {
      border: '2px solid #0180a0',
      height: '98.5vh',
    },
  },
  root: {
    '&$big': {
      height: '21.8vh',
    },
    '&$fullSize': {
      height: '100%',
    },
    height: '26.8vh',
    overflowY: 'auto',
    marginTop: -2,
  },
  btn: {
    '&$big': {
      height: 15,
      fontSize: 12,
    },
    '&$cancel': {
      '&:hover': {
        backgroundColor: '#ff5252',
      },
      backgroundColor: 'var(--theme-cancel-color)',
      '&$disabled': {
        backgroundColor: 'var(--theme-grey-color)',
        color: '#000',
      },
    },
    '&$small': {
      minWidth: 60,
    },
    '&$reset': {
      '&:hover': {
        backgroundColor: '#1eb5db',
      },
      backgroundColor: 'var(--theme-blue-color)',
      marginLeft: 5,
      '&$disabled': {
        backgroundColor: 'var(--theme-grey-color)',
        color: '#000',
      },
    },
    '&:hover': {
      outline: '1px solid #fff',
    },
    fontSize: 11,
    fontFamily: 'var(--theme-font-family)',
    color: 'var(--theme-primary-text-color)',
    borderRadius: 2,
    textTransform: 'none',
    padding: 0,
    minWidth: 125,
    height: 12,
    textAlign: 'center',
  },
  header: {
    fontSize: 13,
    fontWeight: 600,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 0,
    marginTop: 2,
  },
  table: {
    '&$big': {
      '& td': {
        fontSize: 11,
      },
    },
    '& th, & td': {
      '&:first-child': {
        textIndent: 15,
      },
      '&$last': {
        textAlign: 'right',
        paddingRight: 15,
        paddingBottom: 1,
      },
      color: '#fff',
      fontWeight: 600,
      fontFamily: 'Open Sans',
      padding: 0,
      border: 'none',
    },
    '& th': {
      fontSize: 10,
      color: 'var(--theme-grey-color)',
      lineHeight: '18px',
    },
    '& td': {
      color: '#fff',
      fontWeight: 'bold',
      '& div': {
        '&$loaderWrapper': {
          '&$buy': {
            textAlign: 'center',
          },
          '&$sell': {
            textAlign: 'center',
          },
        },
        '&$amount': {
          textAlign: 'right',
        },
        '&$buy': {
          backgroundColor: 'var(--theme-buy-color)',
          textAlign: 'right',
        },
        '&$sell': {
          backgroundColor: 'var(--theme-sell-color)',
          textAlign: 'right',
        },
        margin: '0 auto',
        padding: '0 10px',
        maxWidth: 37,
      },
      fontSize: 9,
    },
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tr: {
    '&$head': {
      borderBottom: '1px solid var(--theme-grey-color)',
    },
    borderBottom: '1px solid #000',
  },
  popout: {
    height: 13,
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginLeft: 14,
  },
  logo: {
    display: 'inline-block',
    position: 'static',
    backgroundRepeat: 'no-repeat',
    height: 21,
  },
  logoContainer: {
    display: 'block',
    width: '100%',
    paddingTop: 2,
  },
  head: {},
  last: {},
  amount: {},
  cancel: {},
  small: {},
  reset: {},
  disabled: {},
  fullSize: {},
  borderBlue: {},
  icap: {
    marginLeft: 6,
    width: 66,
    backgroundImage: `url(${window.location.origin}/images/ICAP_logo.svg)`,
  },
  spotmatch: {
    width: 104,
    verticalAlign: 'middle',
    marginLeft: 6,
    backgroundImage: `url(${window.location.origin}/images/SpotMatch_logo.svg)`,
  },
  dialogContainer: {
    backgroundColor: 'var(--theme-widget-bg-color)',
    maxWidth: 400,
  },
  opaque: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  balanceEdit: {
    display: 'flex',
    justifyContent: 'center',
    '& $loaderWrapper': {
      minWidth: 37,
      '&$buy': {
        backgroundColor: 'var(--theme-buy-color)',
      },
      '&$sell': {
        backgroundColor: 'var(--theme-sell-color)',
      },
      '& $loader': {
        color: 'var(--theme-white-color)',
      },
    },
  },
  balanceInput: {
    maxWidth: '37px',
    margin: '0 auto',
    padding: '0 10px',
    border: 'none',
    fontSize: '11px',
    textAlign: 'right',
    color: '#fff',
    fontWeight: 'bold',
    cursor: 'text',
    boxSizing: 'initial',
    '&$buy': {
      backgroundColor: 'var(--theme-buy-color)',
    },
    '&$sell': {
      backgroundColor: 'var(--theme-sell-color)',
    },
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid var(--theme-white-color)',
    },
  },
  sell: {},
  big: {},
  buy: {},
  loaderWrapper: {},
  loader: {},
})

const OutstandingBalance = (props) => {
  const { title } = props
  const [showConfirm, setShowConfirm] = useState(false)
  const [popupMsg, setPopupMsg] = useState('')
  const [btnType, setBtnType] = useState('')
  const [popupTitle, setPopupTitle] = useState(null)
  const [showTaSnackbar] = useState(true)
  const [ccPairId, setCCPairId] = useState(null)
  const [isWindow, setIsWindow] = useState(false)
  const [editedBalance, setEditedBalance] = useState('') // FE -> Fixing Event
  const [initialBalance, setInitialBalance] = useState(null)
  const [realTimeRowBalance, setRealTimeRowBalance] = useState(null)
  const [currentRow, setCurrentRow] = useState({})
  const [balanceUpdateInProgress, setBalanceUpdateInProgress] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const accountId = useSelector(getSelectedAccountId)
  const matchOccur = useSelector(getIsMatchOccur)
  const matches = useSelector(getMatchesArr)
  const outstandingBalanceMap = useSelector(getOutstandingBalanceMap)
  const orderSummaryCounts = useSelector(getOrderSummaryCounts)
  const liveOrdersMap = useSelector(getLiveOrdersMap)
  const liveFixingEventsMap = useSelector(getLiveFixingEventsMap)
  const confirmBalanceAmend = useSelector(getConfirmBalanceAmend)
  const benchmarks = useSelector(getBenchmarks)
  const noLiveOrders = orderSummaryCounts.liveCount === 0
  const user = useSelector(getLoggedinUser)
  const globalErrMsg = useSelector(getGlobalErrMsg)
  const inEditModeOrderParentId = useSelector(getInEditModeOrderParentId)
  const isWideScreen = useMediaQuery('(min-width:1281px)')

  useEffect(() => {
    if (accountId !== '') {
      dispatch(fetchBalance(accountId))
    }
  }, [accountId, dispatch])

  useEffect(() => {
    // add listener in case of refresh to close popped out window
    const unloadHandler = () => setIsWindow(false)
    window.addEventListener('beforeunload', unloadHandler)
    return () => window.removeEventListener('beforeunload', unloadHandler)
  }, [setIsWindow])

  useEffect(() => {
    // update balance change in live when the pop up confirmation is open
    const updatedRowFromServer = outstandingBalanceMap.find((row) => {
      if (row.parentOrderId === currentRow.parentOrderId) {
        return row
      }
      return null
    })
    if (updatedRowFromServer) {
      setRealTimeRowBalance(
        Math.abs(
          Math.round(updatedRowFromServer.balance / 1000000)
        ).toLocaleString()
      )
    }
  }, [outstandingBalanceMap, currentRow.parentOrderId])

  useEffect(() => {
    const handleAuctionNotification = (data, headers) => {
      switch (headers.systemAction) {
        case 'AUCTION_TERMINATED':
          if (data.parentOrderId === inEditModeOrderParentId)
            dispatch(setEditModeLiveOrderId(undefined))
          break
        case 'AUCTION_INITIATED':
          dispatch(
            setOrderParentId(
              data.auction.parentOrderId,
              data.auction.benchmarkId,
              data.auction.ccPairId
            )
          )
          break
        default:
      }
    }
    ApiService.stompExternalSubscribe(
      '/topic/system.auction',
      handleAuctionNotification
    )
  }, [accountId, inEditModeOrderParentId, dispatch])

  const amendBalancePromise = (data) => {
    return new Promise((res) => {
      res(dispatch(setAmendedBalance(data)))
    })
  }

  const amendBalance = (row) => {
    const balanceBeforeAmend = initialBalance * 1000000
    const balanceAfterAmend = editedBalance * 1000000
    const data = {
      direction: row.direction,
      baseQuantityBefore: balanceBeforeAmend.toFixed(2),
      baseQuantityAfter: balanceAfterAmend.toFixed(2),
      accountId,
      parentOrderId: row.parentOrderId,
      currencyPairId: row.ccPairId,
    }
    amendBalancePromise(data).then(() => {
      // settings a 0.75s delay to prevent from showing
      // the previous balance before changing to the amended balance
      // and show the loader meanwhile
      setTimeout(() => {
        setBalanceUpdateInProgress(false)
        dispatch(toggleEditModeLiveOrder(row.parentOrderId, false))
        dispatch(setEditModeLiveOrderId(undefined))
      }, 750)
    })
  }

  // error msg from the server
  const handleCloseGlobalErrMsg = () => {
    dispatch(setErrMsg(null))
  }

  const handleClose = () => {
    setShowConfirm(false)
    if (btnType === 'confirmation') {
      setBalanceUpdateInProgress(false)
      setPopupTitle(undefined)
      dispatch(toggleEditModeLiveOrder(currentRow.parentOrderId, false))
    }
  }

  const handleConfirm = () => {
    setShowConfirm(false)
    if (btnType === 'all') {
      dispatch(cancelAllLiveOrders(accountId))
    } else if (btnType === 'cancel') {
      dispatch(cancelCCPairLiveOrders(accountId, ccPairId))
    } else if (btnType === 'confirmation') {
      if (initialBalance === realTimeRowBalance) {
        setBalanceUpdateInProgress(true)
        amendBalance(currentRow)
      } else {
        // balance was changed while editting and the amending process canno't continue
        setShowConfirm(true)
        setBtnType('alert')
        setPopupMsg(
          `This action cannot be completed 
          as the Current Balance Amount is already changed`
        )
        dispatch(toggleEditModeLiveOrder(currentRow.parentOrderId, false))
        setBalanceUpdateInProgress(false)
      }
    } else {
      dispatch(resetCCPairLiveOrders(accountId, ccPairId))
    }
  }

  const handleClick = (type, ccPair, selectedCCPairId) => {
    setShowConfirm(true)
    setBtnType(type)
    setCCPairId(selectedCCPairId)
    if (type === 'all') {
      setPopupMsg('All existing open orders will be cancelled. Are you sure?')
    } else if (type === 'cancel') {
      setPopupMsg(
        `All existing open orders of CCY Pair ${ccPair} will be cancelled. Are you sure?`
      )
    } else {
      setPopupMsg(`Balance of CCY Pair ${ccPair} will be reset. Are you sure?`)
    }
  }

  const editBalancePromise = (parentOrderId) => {
    return new Promise((res) => {
      res(dispatch(toggleEditModeLiveOrder(parentOrderId, true)))
    })
  }

  const editBalance = (row, isLiveFixingEventsForCCPair) => {
    const rowBalance = Math.abs(
      Math.round(row.balance / 1000000)
    ).toLocaleString()
    if (isLiveFixingEventsForCCPair && parseInt(rowBalance, 10) !== 0) {
      editBalancePromise(row.parentOrderId)
        .then(() => {
          const input = document.querySelector(
            `input[parent-id='${row.parentOrderId}']`
          )
          input.focus()
        })
        .then(() => {
          setEditedBalance(rowBalance) // set the value in the input field
          setInitialBalance(rowBalance) // row balance before focus input
          dispatch(setEditModeLiveOrderId(row.parentOrderId))
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err.message)
        })
    }
  }

  const changeBalance = (e) => {
    const regex = /^([1-9]\d?\d?)(\.\d{1,2})?$|^$/ // 1-999 range and empty string
    const newBalance = e.target.value
    if (regex.test(newBalance)) {
      setEditedBalance(newBalance)
    }
  }

  const balanceInputkeyPress = (e, row) => {
    const newBalance = e.target.value
    const rowBenchmarkId = row.benchmarkId
    const rowBenchmark = benchmarks.find(
      (benchmark) => benchmark.id === rowBenchmarkId
    )
    setRealTimeRowBalance(
      Math.abs(Math.round(row.balance / 1000000)).toLocaleString()
    )
    setCurrentRow(row)
    switch (e.key) {
      case 'Escape':
        dispatch(toggleEditModeLiveOrder(row.parentOrderId, false))
        break
      case 'Enter':
        if (newBalance === '' || initialBalance === editedBalance) {
          dispatch(toggleEditModeLiveOrder(row.parentOrderId, false))
          dispatch(setEditModeLiveOrderId(undefined))
          return
        }
        if (initialBalance === realTimeRowBalance) {
          if (confirmBalanceAmend) {
            setBalanceUpdateInProgress(true)
            setShowConfirm(true)
            setBtnType('confirmation')
            setPopupTitle('Amend Confirmation')
            setPopupMsg(
              `Benchmark: ${rowBenchmark.name}
              CCY Pair: ${row.currencyPair}

              ${
                editedBalance > Math.abs(currentRow.balance / 1000000)
                  ? `A new ${
                      editedBalance - Math.abs(currentRow.balance / 1000000)
                    }M ${currentRow.direction.toLowerCase()} order will be submitted`
                  : `The Current Balance Outstanding will be reduced to ${editedBalance}M`
              }`
            )
          } else {
            setBalanceUpdateInProgress(true)
            amendBalance(row)
          }
        } else {
          // balance was changed while editting
          // and the amending process canno't continue
          setShowConfirm(true)
          setBtnType('alert')
          setPopupMsg(
            `This action cannot be completed 
            as the Current Balance Amount is already changed`
          )
          dispatch(toggleEditModeLiveOrder(row.parentOrderId, false))
          setBalanceUpdateInProgress(false)
        }
        break
      default:
        break
    }
  }

  const openWindow = useCallback(() => setIsWindow(true), [setIsWindow])
  const closeWindow = useCallback(() => setIsWindow(false), [setIsWindow])

  return (
    <div
      className={`${classes.container} ${
        title === false ? classes.borderBlue : ''
      }`}
    >
      {title === false && (
        <div className={`${showConfirm ? classes.opaque : ''}`}>
          <div className={classes.dialogContainer}>
            <ConfirmContent
              onClose={handleClose}
              onConfirm={handleConfirm}
              msg={popupMsg}
              show={showConfirm}
            />
          </div>
        </div>
      )}
      {title === false && (
        <div className={classes.logoContainer}>
          <div alt="ICAP Logo" className={`${classes.logo} ${classes.icap}`} />
          <div
            alt="Spot Match Logo"
            className={`${classes.logo} ${classes.spotmatch}`}
          />
        </div>
      )}
      <h1 className={classes.header}>
        Current Balance Outstanding
        {title === true ? (
          <OpenInNewOutlinedIcon
            className={classes.popout}
            onClick={openWindow}
          />
        ) : null}
      </h1>
      <TableContainer
        className={`${classes.root} ${
          title === false ? classes.fullSize : ''
        } ${isWideScreen ? classes.big : ''}`}
      >
        <Table
          className={`${classes.table} ${isWideScreen ? classes.big : ''}`}
        >
          <TableHead>
            <TableRow className={`${classes.tr} ${classes.head}`}>
              <TableCell width="20%" align="left" size="small">
                CCY Pair
              </TableCell>
              <TableCell width="10%" align="center" size="small">
                Bought
              </TableCell>
              <TableCell width="10%" align="center" size="small">
                Sold
              </TableCell>
              <TableCell width="15%" align="center" size="small">
                Balance (M)
              </TableCell>
              <TableCell width="30%" className={classes.last}>
                <Button
                  disabled={noLiveOrders}
                  className={`${classes.btn} ${classes.cancel} ${
                    noLiveOrders ? classes.disabled : ''
                  } ${isWideScreen ? classes.big : ''}`}
                  onClick={() => handleClick('all')}
                >
                  Cancel All
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outstandingBalanceMap.map((row) => {
              const isLiveFixingEventsForCCPair =
                liveFixingEventsMap.get(row.ccPairId) === true
              const noLiveOrdersForCCPair =
                liveOrdersMap.get(row.ccPairId) === false
              return (
                (row.order !== 0 || row.bought > 0 || row.sold < 0) && (
                  <TableRow key={row.ccPairId} className={classes.tr}>
                    <TableCell width="20%" align="left" size="small">
                      {row.currencyPair}
                    </TableCell>
                    <TableCell
                      width="10%"
                      align="center"
                      size="small"
                      className={classes.amount}
                    >
                      {row.bought > 0
                        ? Math.round(row.bought / 1000000).toLocaleString()
                        : null}
                    </TableCell>
                    <TableCell
                      width="10%"
                      align="center"
                      size="small"
                      className={classes.amount}
                    >
                      {row.sold > 0
                        ? Math.round(row.sold / 1000000).toLocaleString()
                        : null}
                    </TableCell>
                    <TableCell width="15%" align="center" size="small">
                      {row.editMode === false ? (
                        <div
                          className={`${classes.amount} ${
                            row.balance > 0 ? classes.buy : ''
                          } ${row.balance < 0 ? classes.sell : ''}`}
                          role="button"
                          tabIndex={0}
                          onKeyUp={() =>
                            editBalance(row, isLiveFixingEventsForCCPair)
                          }
                          onClick={() =>
                            editBalance(row, isLiveFixingEventsForCCPair)
                          }
                        >
                          {Math.abs(
                            Math.round(row.balance / 1000000)
                          ).toLocaleString()}
                        </div>
                      ) : (
                        <div className={classes.balanceEdit}>
                          {balanceUpdateInProgress ? (
                            <div
                              className={`${classes.loaderWrapper} ${
                                row.balance > 0 ? classes.buy : ''
                              } ${row.balance < 0 ? classes.sell : ''}`}
                            >
                              <CircularProgress
                                className={classes.loader}
                                size={10}
                              />
                            </div>
                          ) : (
                            <input
                              className={`${classes.balanceInput} ${
                                row.balance > 0 ? classes.buy : ''
                              } ${row.balance < 0 ? classes.sell : ''}`}
                              type="text"
                              maxLength="3"
                              parent-id={row.parentOrderId}
                              value={editedBalance}
                              onBlur={() => {
                                dispatch(
                                  toggleEditModeLiveOrder(
                                    row.parentOrderId,
                                    false
                                  )
                                )
                              }}
                              onChange={(e) => {
                                changeBalance(e)
                              }}
                              onKeyUp={(e) => balanceInputkeyPress(e, row)}
                            />
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell width="30%" className={classes.last}>
                      <Button
                        onClick={() =>
                          handleClick('cancel', row.currencyPair, row.ccPairId)
                        }
                        className={`${classes.btn} ${classes.cancel} ${
                          classes.small
                        } ${
                          noLiveOrdersForCCPair || row.balance === 0
                            ? classes.disabled
                            : ''
                        } ${isWideScreen ? classes.big : ''}`}
                        disabled={noLiveOrdersForCCPair || row.balance === 0}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          handleClick('reset', row.currencyPair, row.ccPairId)
                        }
                        className={`${classes.btn} ${classes.reset} ${
                          classes.small
                        } ${
                          isLiveFixingEventsForCCPair || row.balance === 0
                            ? classes.disabled
                            : ''
                        } ${isWideScreen ? classes.big : ''}`}
                        disabled={
                          isLiveFixingEventsForCCPair || row.balance === 0
                        }
                      >
                        Reset
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {showConfirm && title === true && (
        <ConfirmationDialog
          onClose={handleClose}
          onConfirm={handleConfirm}
          msg={popupMsg}
          type={btnType}
          title={popupTitle}
        />
      )}

      {globalErrMsg && showConfirm === false && (
        <ConfirmationDialog
          type="alert"
          msg={globalErrMsg}
          onClose={handleCloseGlobalErrMsg}
        />
      )}

      {isWindow && (
        <WindowPortal
          width={600}
          height={200}
          show={isWindow}
          onClose={closeWindow}
          matchOccur={matchOccur}
        >
          <OutstandingBalance title={false} user={user} />

          {matches.map((match) => (
            <TaSnackbar
              show={showTaSnackbar}
              variant="success"
              message={match.msg}
              key={`tasnackbar-${match.id}`}
              id={`tasnackbar-${match.id}`}
            />
          ))}
        </WindowPortal>
      )}
    </div>
  )
}

OutstandingBalance.propTypes = {
  title: PropTypes.bool,
  currentUser: PropTypes.shape({ id: PropTypes.number.isRequired }),
}

OutstandingBalance.defaultProps = {
  title: true,
  currentUser: null,
}

export default memo(OutstandingBalance)
