/* eslint-disable react/prop-types */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

const TaButton = (props) => {
  const { children, disabled, onClick, className } = props
  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={(e) => onClick(e)}
    >
      {children}
    </Button>
  )
}

TaButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

TaButton.defaultProps = {
  disabled: false,
  onClick: () => {},
}

export default memo(TaButton)
