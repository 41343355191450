import React, { memo } from 'react'
import PropTypes from 'prop-types'

const DirectionRenderer = (props) => {
  const { value } = props
  const color =
    value.toLowerCase() === 'buy'
      ? 'var(--theme-buy-color)'
      : 'var(--theme-sell-color)'
  return <span style={{ color }}>{value}</span>
}

DirectionRenderer.propTypes = {
  value: PropTypes.string.isRequired,
}

export default memo(DirectionRenderer)
