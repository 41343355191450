import { takeEvery } from 'redux-saga/effects'
import globalSettingsTypes from './types'
import fetchGlobalSettings from './saga/fetchGlobalSettings'
import updateGlobalSettings from './saga/updateGlobalSettings'

function* globalSettingsWatcher() {
  yield takeEvery(
    globalSettingsTypes.FETCH_GLOBAL_SETTINGS,
    fetchGlobalSettings
  )
  yield takeEvery(
    globalSettingsTypes.UPDATE_GLOBAL_SETTINGS,
    updateGlobalSettings
  )
}
export default globalSettingsWatcher
