import types from './types'

export const setInStore = (payload) => ({ type: types.SET_IN_STORE, payload })

export const getParametersByUserId = (payload) => ({
  type: types.GET_PARAM_BY_USER_ID,
  payload,
})

export const addBenchmark = (payload) => ({
  type: types.ADD_BENCHMARK,
  payload,
})

export const addAccount = (payload) => ({ type: types.ADD_ACCOUNT, payload })

export const removeAccount = (payload) => ({
  type: types.REMOVE_ACCOUNT,
  payload,
})
