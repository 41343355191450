import types from './types'

export const getDailyAuction = () => ({ type: types.GET_DAILY_AUCTIONS })

export const fetchOrderSummaryCounts = (payload) => ({
  type: types.GET_ORDER_SUMMARY_COUNTS,
  payload,
})

export const setOrderSummaryCounts = (payload) => ({
  type: types.SET_ORDER_SUMMARY_COUNTS,
  payload,
})

export const getOrdersByStatus = (accountId, orderStatus) => ({
  type: types.FILTER_ORDERS_BY_STATUS,
  payload: { accountId, orderStatus },
})

export const setErrorMsg = (payload) => ({
  type: types.SET_ORDER_ERR_MSG,
  payload,
})

export const clearErrMsg = () => ({ type: types.CLEAR_ERR_MSG })

export const clearAllData = () => ({ type: types.CLEAR_ALL_DATA })

export const setBenchmarksCurrentStatus = () => ({
  type: types.SET_BENCHMARKS_CURRENT_STATUS,
})

export const setFilteredOrders = (payload) => ({
  type: types.SET_FILTERED_ORDERS,
  payload,
})

export const setDailyAuction = (payload) => ({
  type: types.SET_DAILY_AUCTIONS,
  payload,
})

export const decrementFixingEventsRunTime = () => ({
  type: types.DECREMENT_EVENTS_RUN_TIME,
})

export const setSelectedAuctionId = (payload) => ({
  type: types.SET_SELECTED_AUCTION_ID,
  payload,
})

export const addAuctionOrder = (payload) => ({
  type: types.ADD_AUCTION_ORDER,
  payload,
})

export const amendOrder = (payload) => ({ type: types.AMEND_ORDER, payload })

export const cancelAuctionOrder = (payload) => ({
  type: types.CANCEL_AUCTION_ORDER,
  payload,
})
export const updateOrderStatus = (payload) => ({
  type: types.UPDATE_ORDER_STATUS,
  payload,
})

export const clearActivity = () => ({ type: types.CLEAR_ACTIVITY })

export const addOrUpdateOrder = (payload) => ({
  type: types.ADD_OR_UPDATE_ORDER,
  payload,
})

export const updateAuctionStatus = (payload) => ({
  type: types.UPDATE_AUCTION_STATUS,
  payload,
})
export const cancelAuction = (payload) => ({
  type: types.CANCEL_AUCTION,
  payload,
})
export const addAuction = (payload) => ({ type: types.ADD_AUCTION, payload })

export const auctionInitiated = (payload) => ({
  type: types.AUCTION_INITIATED,
  payload,
})

export const publishAuctionRate = (payload) => ({
  type: types.PUBLISH_RATE,
  payload,
})

export const selectAccount = (payload) => ({
  type: types.SELECT_ACCOUNT,
  payload,
})

export const setFilterState = (payload) => ({
  type: types.SET_ORDER_FILTER,
  payload,
})

export const setIsMatch = (payload) => ({
  type: types.SET_IS_MATCH,
  payload,
})

export const addTradeMatch = (payload) => {
  return {
    type: types.ADD_MATCH,
    payload,
  }
}

export const clearMatches = () => ({ type: types.CLEAR_MATCHES })
