import moment from 'moment'

export default class Utils {
  static addPaddingZeroToTime(number) {
    if (number === 0) {
      return '00'
    }
    if (number > 0 && number < 10) {
      return `0${number}`
    }
    return `${number}`
  }

  static getFormatter() {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  static formatLocalCurrency(formatter, number) {
    return formatter.format(number)
  }

  static convertHourLocalTime(java8Time) {
    const utcTime = moment.utc(java8Time, 'HH:mm')
    utcTime.local()
    return utcTime.format('HH:mm')
  }

  static calculateTimeDiff(originalTime, type = 'seconds') {
    return moment(new Date()).diff(moment(originalTime), type)
  }

  static sendXHRForCSV(reqUrl, requestParams, dataType, sessionId) {
    const xhttp = new XMLHttpRequest()

    xhttp.responseType = 'blob'
    xhttp.onload = () => {
      const temp = document.createElement('a')
      temp.id = Math.random()
      temp.href = window.URL.createObjectURL(xhttp.response)
      temp.download = `${dataType}s.csv`
      temp.style.display = 'none'
      document.body.appendChild(temp)
      temp.click()
      document.getElementById(temp.id).parentNode.removeChild(temp)
    }
    xhttp.open('POST', reqUrl, true)
    xhttp.setRequestHeader('sessionId', sessionId)
    xhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhttp.send(JSON.stringify(requestParams))
  }

  static checkIfAfterEOD(time) {
    const now = moment.utc()
    const endOfDay = moment.utc(time, 'HH:mm')
    return now.isAfter(endOfDay)
  }

  static createBlotterRequestObject(
    type,
    accountId,
    dates = { dateFrom: null, dateTo: null },
    afterEOD
  ) {
    const { dateFrom, dateTo } = dates
    const today = afterEOD ? moment.utc().add(1, 'days') : moment.utc()
    let dateCriteriaFrom = today
    let dateCriteriaTo = today
    if (type === 'custom' && dateFrom != null && dateTo != null) {
      dateCriteriaFrom = moment(dateFrom)
      dateCriteriaTo = moment(dateTo)
    }
    const blotterReqObj = {
      pagingCriteria: {
        pageNumber: 1,
        pageSize: 100,
      },
      sortCriterias: [],
      whereCriterias: [
        {
          fieldName: 'createDate',
          fieldValue: `${dateCriteriaFrom.format('YYYY-MM-DD')} 00:00:00`,
          operatorAndOr: 'AND',
          operatorFieldValue: '>=',
        },
        {
          fieldName: 'createDate',
          fieldValue: `${dateCriteriaTo.format('YYYY-MM-DD')} 23:59:59`,
          operatorAndOr: 'AND',
          operatorFieldValue: '<=',
        },
      ],
      accountIds: [accountId],
    }
    return blotterReqObj
  }
}
