import { createSelector } from 'reselect'

export const getParamHolderState = (state) => state.paramHolder

export const getBenchmarks = (state) => getParamHolderState(state).benchmarks
export const getCcPairs = (state) => getParamHolderState(state).ccPairs
export const getEndOfDay = (state) => {
  const {
    globalParameters: { endOfDay },
  } = getParamHolderState(state)

  return endOfDay
}

export const getAccounts = (state) => {
  const { accounts } = getParamHolderState(state)
  return accounts
}

export const getBenchmarksMap = createSelector(getBenchmarks, (benchmarks) => {
  const map = new Map()
  benchmarks.forEach((benchmark) => {
    map.set(benchmark.id, benchmark)
  })
  return map
})

export const getCcPairMap = createSelector(getCcPairs, (ccPairs) => {
  const map = new Map()
  ccPairs.forEach((ccPair) => {
    map.set(ccPair.id, ccPair)
  })
  return map
})
