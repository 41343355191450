import { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import useSound from 'use-sound'

// assets
import msgAlert from '../../sounds/chching.mp3'

import { setIsMatch, clearMatches } from '../../ducks/trading/actions'

const WindowPortal = (props) => {
  const { children, width, height, show, onClose, matchOccur } = props
  const containerEl = useRef(document.createElement('div'))
  const externalWindow = useRef(null)
  const dispatch = useDispatch()
  const [playMatchSound] = useSound(msgAlert)

  const copyStyles = (source, target) => {
    Array.from(source.styleSheets).forEach((styleSheet) => {
      let rules
      try {
        rules = styleSheet.cssRules
      } catch (err) {
        // eslint-disable-next-line
        console.error(err)
      }
      if (rules) {
        const newStyleEl = source.createElement('style')

        // Write the text of each rule into the body of the style element
        Array.from(styleSheet.cssRules).forEach((cssRule) => {
          const { cssText, type } = cssRule
          let returnText = cssText
          // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5) to handle local imports on a about:blank page
          // '/custom.css' turns to 'http://my-site.com/custom.css'
          if ([3, 5].includes(type)) {
            returnText = cssText
              .split('url(')
              .map((line) => {
                if (line[1] === '/') {
                  return `${line.slice(0, 1)}${
                    window.location.origin
                  }${line.slice(1)}`
                }
                return line
              })
              .join('url(')
          }
          newStyleEl.appendChild(source.createTextNode(returnText))
        })

        target.head.appendChild(newStyleEl)
      } else if (styleSheet.href) {
        // for <link> elements loading CSS from a URL
        const newLinkEl = source.createElement('link')

        newLinkEl.rel = 'stylesheet'
        newLinkEl.href = styleSheet.href
        target.head.appendChild(newLinkEl)
      }
    })
  }

  useEffect(() => {
    // open a new browser window and store a reference to it
    if (show === true && externalWindow.current === null) {
      externalWindow.current = window.open(
        '',
        '',
        `width=${width},height=${height},left=200,top=200`
      )
      copyStyles(document, externalWindow.current.document)
      externalWindow.current.document.body.appendChild(containerEl.current)
      externalWindow.current.document.title = 'Current Balance Outstanding'
      externalWindow.current.addEventListener('beforeunload', onClose)
      // initial match flag
      dispatch(setIsMatch(false))
      // clear matches arr
      dispatch(clearMatches())
    }

    return () => {
      externalWindow.current.removeEventListener('beforeunload', onClose)
      externalWindow.current.close()
      dispatch(clearMatches())
    }
  }, [height, show, width, onClose, dispatch])

  // if traders' match occur
  useEffect(() => {
    const onTASnackbarInsert = () => {
      const browserTabInitialTitle = externalWindow.current.document.title
      const msgTitleAtBlink = 'New Trade'
      // browser tab blink at match
      const blink = setInterval(() => {
        externalWindow.current.document.title =
          externalWindow.current.document.title === browserTabInitialTitle
            ? msgTitleAtBlink
            : browserTabInitialTitle
      }, 1000)

      externalWindow.current.document.addEventListener(
        'mousemove',
        function clearBlink() {
          clearInterval(blink)
          externalWindow.current.document.title = browserTabInitialTitle
        }
      )

      externalWindow.current.document.removeEventListener(
        'mousemove',
        function clearBlink() {
          clearInterval(blink)
        }
      )
    }

    if (matchOccur) {
      onTASnackbarInsert()
      playMatchSound()
      dispatch(setIsMatch(false))
    }
  }, [matchOccur, playMatchSound, dispatch])

  return ReactDOM.createPortal(children, containerEl.current)
}

export default WindowPortal
