/* eslint-disable no-param-reassign */
import produce from 'immer'
import { OrderStatus, AuctionStatus } from '../../consts/TaConsts'
import types from './types'
import initialState from './initial'

export default function tradingReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case types.SET_DAILY_AUCTIONS: {
      const nextState = produce(state, (draftState) => {
        draftState.dailyAuctions = payload
        return draftState
      })
      return nextState
    }
    case types.SET_ORDER_SUMMARY_COUNTS: {
      const nextState = produce(state, (draftState) => {
        draftState.orderSummaryCounts = payload
        return draftState
      })
      return nextState
    }
    case types.SET_SELECTED_AUCTION_ID: {
      const { auctionId } = payload
      const nextState = produce(state, (draftState) => {
        draftState.selectedAuctionId = auctionId
        return draftState
      })
      return nextState
    }
    case types.SET_BENCHMARKS_CURRENT_STATUS: {
      const nextState = produce(state, (draftState) => {
        const sortFunc = (a, b) => {
          const aDate = new Date()
          const bDate = new Date()
          const [hourA, minA] = a.dailyAuctionTime.split(':')
          const [hourB, minB] = b.dailyAuctionTime.split(':')
          aDate.setHours(hourA, minA, 0)
          bDate.setHours(hourB, minB, 0)
          return aDate - bDate
        }

        const benchmarkIds = draftState.dailyAuctions.map(
          (fixingEvent) => fixingEvent.auction.benchmarkId
        )
        const uniqueBenchMarkIds = [...new Set(benchmarkIds)]
        draftState.benchMarksCurrentStatus = uniqueBenchMarkIds.reduce(
          (benchmarksMap, benchmarkId) => ({
            ...benchmarksMap,
            [benchmarkId]: draftState.dailyAuctions
              .filter(
                (fixingEvent) => fixingEvent.auction.benchmarkId === benchmarkId
              )
              .map((item) => item.auction)
              .sort(sortFunc),
          }),
          {}
        )
        return draftState
      })
      return nextState
    }
    case types.SET_FILTERED_ORDERS: {
      const nextState = produce(state, (draftState) => {
        draftState.filteredOrders = payload
      })
      return nextState
    }
    case types.DECREMENT_EVENTS_RUN_TIME: {
      const nextState = produce(state, (draftState) => {
        const liveEvents = draftState.dailyAuctions.filter(
          (item) => item.auction.status === 'LIVE'
        )
        liveEvents.forEach((item) => {
          const fixingEvent = item.auction
          if (fixingEvent.runTime > 0) {
            const [hour, min] = fixingEvent.dailyAuctionTime.split(':')
            const endDate = new Date()
            endDate.setUTCHours(hour, min, 0)
            endDate.setUTCMinutes(endDate.getMinutes() + fixingEvent.duration)
            fixingEvent.runTime = Math.floor(
              (endDate.getTime() - Date.now()) / 1000
            )
          } else {
            fixingEvent.status = AuctionStatus.DONE
          }
        })
        return draftState
      })
      return nextState
    }
    case types.ADD_OR_UPDATE_ORDER: {
      const { order } = payload
      const nextState = produce(state, (draftState) => {
        const { orderId, orderStatus, executedAmount, quantity } = order
        const existingIndex = draftState.myOrders.findIndex(
          (existing) => existing.orderId === orderId
        )
        if (existingIndex !== -1) {
          draftState.myOrders[existingIndex].orderStatus = orderStatus
          draftState.myOrders[existingIndex].quantity = quantity
          draftState.myOrders[existingIndex].executedAmount = executedAmount
        } else {
          draftState.myOrders.unshift(order)
        }
        return draftState
      })
      return nextState
    }
    case types.UPDATE_AUCTION_STATUS: {
      const { auctionId, status } = payload
      const nextState = produce(state, (draftState) => {
        const auction = draftState.dailyAuctions.find(
          (item) => item.auction.id === auctionId
        )
        if (auction) {
          auction.auction.status = status
        }
      })
      return nextState
    }
    case types.SET_ORDER_ERR_MSG: {
      const errMsg = payload
      const nextState = produce(state, (draftState) => {
        draftState.errorMsg = errMsg
      })
      return nextState
    }
    case types.CLEAR_ERR_MSG: {
      const nextState = produce(state, (draftState) => {
        draftState.errorMsg = ''
      })
      return nextState
    }
    case types.CLEAR_ALL_DATA: {
      const nextState = produce(state, (draftState) => {
        draftState.dailyAuctions = []
        draftState.myOrders = []
        draftState.orderSummaryCounts = {
          liveCount: 0,
          doneCount: 0,
          canceledCount: 0,
          totalCount: 0,
        }
      })
      return nextState
    }
    case types.SET_ORDER_FILTER: {
      const { filter } = payload
      const nextState = produce(state, (draftState) => {
        draftState.orderFilter = filter
      })
      return nextState
    }
    case types.ADD_AUCTION: {
      const { auction } = payload
      const nextState = produce(state, (draftState) => {
        draftState.dailyAuctions.push(auction)
      })
      return nextState
    }
    case types.CANCEL_AUCTION: {
      const { auctionId } = payload
      const nextState = produce(state, (draftState) => {
        const newDailyList = draftState.dailyAuctions.filter(
          (item) => item.auction.id !== auctionId
        )
        draftState.dailyAuctions = newDailyList
        return draftState
      })
      return nextState
    }
    case types.AUCTION_INITIATED: {
      const { auction } = payload
      const nextState = produce(state, (draftState) => {
        const newDailyList = draftState.dailyAuctions.filter(
          (item) => item.auction.id !== auction.auction.id
        )
        newDailyList.push(auction)
        draftState.dailyAuctions = newDailyList
        return draftState
      })
      return nextState
    }
    case types.UPDATE_ORDER_STATUS: {
      const { orderId, status } = payload
      const nextState = produce(state, (draftState) => {
        const order = draftState.myOrders.find(
          (myOrder) => myOrder.orderId === orderId
        )
        order.orderStatus = status
      })
      return nextState
    }
    case types.CLEAR_ACTIVITY: {
      const nextState = produce(state, (draftState) => {
        const myOrders = draftState.myOrders.filter(
          (myOrder) =>
            myOrder.orderStatus === OrderStatus.LIVE ||
            myOrder.orderStatus === OrderStatus.PARTIALLY_FILLED
        )
        draftState.myOrders = myOrders
      })
      return nextState
    }
    case types.SELECT_ACCOUNT: {
      const { accountId } = payload
      const nextState = produce(state, (draftState) => {
        draftState.selectedAccountId = accountId
      })
      return nextState
    }

    // match happened
    case types.SET_IS_MATCH: {
      const isMatchOccur = payload
      const nextState = produce(state, (draftState) => {
        draftState.isMatchOccur = isMatchOccur
      })
      return nextState
    }

    // add match snackbar obj to arr
    case types.ADD_MATCH: {
      const tradeMatch = payload
      const nextState = produce(state, (draftState) => {
        draftState.tradesMatches.push(tradeMatch)
      })
      return nextState
    }

    // reset matches arr
    case types.CLEAR_MATCHES: {
      const nextState = produce(state, (draftState) => {
        draftState.tradesMatches = []
      })
      return nextState
    }

    default:
      return state
  }
}
