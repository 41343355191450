import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, LinearProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getSortedDailyAuctions } from '../../ducks/trading/selectors'

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: '#131416',
    marginTop: 33,
    height: 4,
    width: '100%',
  },
  barColorPrimary: {
    height: 4,
    backgroundColor: 'var(--theme-font-orange)',
  },
  orange: {
    height: 4,
    backgroundColor: 'var(--theme-font-orange)',
  },
})

const ProgressBar = (props) => {
  const completed = useRef(0)
  const { totalSeconds, barColor, style, fixingEventId } = props
  const classes = useStyles()
  const dailyFixingEvents = useSelector(getSortedDailyAuctions)
  const {
    auction: { runTime },
  } = dailyFixingEvents.find((item) => item.auction.id === fixingEventId)

  if (completed.current === 100) {
    completed.current = 0
  } else {
    const percentageDone = ((totalSeconds - runTime) / totalSeconds) * 100
    completed.current = Math.min(percentageDone, 100)
  }

  return (
    <div>
      <LinearProgress
        variant="determinate"
        value={completed.current}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: barColor
            ? classes[barColor]
            : classes.barColorPrimary,
        }}
        style={style}
      />
    </div>
  )
}

ProgressBar.propTypes = {
  totalSeconds: PropTypes.number.isRequired,
  barColor: PropTypes.string,
  style: PropTypes.shape({ marginTop: PropTypes.number }),
  fixingEventId: PropTypes.number.isRequired,
}

ProgressBar.defaultProps = {
  barColor: null,
  style: null,
}

export default memo(ProgressBar)
