import { put, call } from 'redux-saga/effects'
import { getOrdersByStatus } from '../../trading/actions'
import ApiService from '../../../api/ApiService'
import { stopLoading } from '../../loaders/actions'
import { LoaderTypes } from '../../loaders/loaderTypesEnum'

export default function* cancelCCPairLiveOrders({
  payload: { accountId, ccPairId },
}) {
  try {
    yield call(ApiService.cancelCCPairLiveOrders, accountId, ccPairId)
    yield put(getOrdersByStatus(accountId, 'ALL'))
    yield put(
      stopLoading({
        loaderType: LoaderTypes.CANCEL_CCPAIR_LIVE_ORDERS,
        error: null,
      })
    )
  } catch (error) {
    // eslint-disable-next-line
    console.log('err', error)
    yield put(
      stopLoading({
        loaderType: LoaderTypes.CANCEL_CCPAIR_LIVE_ORDERS,
        error: `Falied to get parameterHolder ${error}`,
      })
    )
  }
}
