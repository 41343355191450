// Initial state is dynamic and based on LoaderTypes

const InitialState = {
  dailyAuctions: [],
  orderSummaryCounts: {
    liveCount: 0,
    doneCount: 0,
    canceledCount: 0,
    totalCount: 0,
  },
  selectedAuctionId: null,
  selectedAccountId: '',
  myOrders: [],
  filteredOrders: [],
  benchMarksCurrentStatus: {},
  orderFilter: 'ALL',
  errorMsg: '',
  isMatchOccur: false,
  tradesMatches: [],
}

export default InitialState
